import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_BRANDS](state, brands) {
    state.brands = brands.map((brand) => {
      const formatDateBanners = brand.banners.map((item) => ({
        ...item,
        dateStart: new Date(item.dateStart).getTime(),
        dateEnd: new Date(item.dateEnd).getTime()
      }))

      return { ...brand, banners: formatDateBanners }
    })
  },
  [mutationTypes.CREATE_BRAND](state, banner) {
    const idx = state.brands.findIndex((el) => el.id === +banner?.groupId)
    if (idx > -1) {
      state.brands[idx].banners = [...state.brands[idx].banners, banner]
    }
  }
}
