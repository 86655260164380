<script setup>
const OPEN_MODAL = "modal-open"
import { ref, watch, onMounted } from "vue"

const emit = defineEmits(["close"])

const props = defineProps({
  size: {
    type: String,
    default: ""
  },
  show: {
    type: Boolean,
    default: false
  },
  autoShow: {
    type: Boolean,
    default: false
  },
  hideCloseBtn: {
    type: Boolean,
    default: false
  }
})

const isOpen = ref(false)

const closeModal = () =>
  props.autoShow ? (isOpen.value = false) : emit("close")

watch(
  () => props.show,
  (val) => {
    if (val) {
      document.body.classList.add(OPEN_MODAL)
    } else {
      document.body.classList.remove(OPEN_MODAL)
    }
  }
)

onMounted(() => {
  if (props.autoShow) {
    isOpen.value = true
  }
})
</script>

<template>
  <transition name="fade">
    <div
      v-show="show || isOpen"
      tabindex="-1"
      class="modal"
      @keyup.esc="closeModal"
    >
      <div class="modal-mask" @click="closeModal" />
      <transition name="slide-up">
        <div
          v-show="show || isOpen"
          class="modal-wrapper"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <div class="modal-container" :class="[size]">
            <button
              v-show="!hideCloseBtn"
              class="modal-close-button"
              aria-label="Close modal"
              @click="closeModal"
            >
              <img
                src="@/assets/icons/close.svg"
                alt="Icon close modal"
                width="12"
                height="12"
                class="img-fluid"
              />
            </button>

            <div class="modal-body">
              <slot name="main" :handle-click="closeModal"></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<!--<script>
// const OPEN_MODAL = "modal-open"

// export default {
//   name: "v-modal",
//   props: {
//     size: {
//       type: String,
//       default: ""
//     },

//     show: {
//       type: Boolean,
//       default: false
//     },

//     autoShow: {
//       type: Boolean,
//       default: false
//     },

//     hideCloseBtn: {
//       type: Boolean,
//       default: false
//     }
//   },

//   data: () => ({
//     isOpen: false
//   }),

//   watch: {
//     show(value) {
//       if (value) {
//         document.body.classList.add(OPEN_MODAL)
//       } else {
//         document.body.classList.remove(OPEN_MODAL)
//       }
//     }
//   },

//   mounted() {
//     if (this.autoShow) {
//       this.isOpen = true
//     }
//   },

//   methods: {
//     closeModal() {
//       if (this.autoShow) {
//         this.isOpen = false
//       } else {
//         this.$emit("close")
//       }
//     }
//   }
// }
// </script>-->

<style lang="scss" scoped>
.modal,
.modal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal {
  position: fixed;
  animation-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &-mask {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-wrapper {
    position: relative;
    width: fit-content;
    max-width: 100%;
    z-index: 9999;
    display: flex;
    vertical-align: middle;
    border-radius: 5px;
    animation-duration: 0.3s;
  }

  &-body {
    height: 100%;
  }

  &-container {
    position: relative;
    margin: 0 auto;
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    transition: all 0.4s ease;
    overflow: hidden;
    min-height: 455px;
  }

  &-close-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 20px;
    border: 0;
    background: none;
  }
}
</style>
