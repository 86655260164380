<template>
  <div class="link">
    <div class="link-row">
      <span class="link-row--label">Link {{ repeatChart(idx) }}</span>
      <v-input
        class="link-row--input"
        :class="isClasses"
        :disabled="disabled || !!link.promotion?.link"
        :value="link.link"
        @input="inputLink"
      />
      <CopyLabel class="link-row--copy" :textToCopy="link.link || ''" />
    </div>
  </div>
</template>

<script>
import CopyLabel from "@/components/CopyLabel.vue"

export default {
  props: {
    link: {
      type: Object,
      default: () => ({
        id: 0,
        link: "",
        promotion: null
      })
    },

    idx: {
      type: Number,
      default: 1
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["inputLink"],
  components: {
    CopyLabel
  },

  data: () => ({}),
  computed: {
    isClasses() {
      return [
        {
          "bg-grey":
            (this.link.promotion && this.link.link) || this.link.promotion?.link
        }
      ]
    }
  },

  methods: {
    inputLink(event) {
      this.$emit("inputLink", event.target.value)
    },

    repeatChart(idx) {
      return "I".repeat(+idx + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  &-row {
    display: flex;
    align-items: center;
    &--label {
      font-size: 14px;
      line-height: 16px;
      width: 43px;
      margin-right: 48px;
      white-space: nowrap;
    }
    &--input {
      border: 1px solid $grey5;
      border-radius: 15px;
      padding: 8px 16px;
      max-width: 364px;
      width: 100%;
      margin-right: 16px;
      &.bg-grey {
        background-color: $grey6;
      }
    }
  }
}
</style>
