export const ROUTE = {
  MAIN: {
    name: "Main",
    path: "/"
  },
  LOGIN: {
    name: "Login",
    path: "/login"
  },
  BANNERS: {
    name: "Banners",
    path: "/banners"
  },
  CREATE_BANNER: {
    name: "CreateBanner",
    path: "/banners/:parentId/create-banner"
  },
  EDIT_BANNER: {
    name: "CurrentBanner",
    path: "/banners/:bannerId"
  },
  BRANDS: {
    name: "Brands",
    path: "/brands"
  },
  CREATE_BRAND: {
    name: "CreateBrand",
    path: "/brands/create/:groupId"
  },
  EDIT_BRAND: {
    name: "EditBrand",
    path: "/brands/:brandId"
  },
  PROMOTIONS: {
    name: "Promotions",
    path: "/promotions"
  },
  CREATE_PROMOTION: {
    name: "CreatePromotion",
    path: "/promotions/:promotionId"
  },
  SECTIONS_AND_FORMS: {
    name: "SectionsAndForms",
    path: "/sections-and-forms"
  },
  ACTIVE_COMPONENTS: {
    name: "ActiveComponents",
    path: "/active-components"
  },
  CATALOG_FILTER: {
    name: "CatalogFilter",
    path: "/catalog-filter"
  },
  CATALOG_COLORS: {
    name: "CatalogColors",
    path: "/catalog-colors"
  },
  CATALOG_EFFECTS: {
    name: "CatalogEffects",
    path: "/catalog-effects"
  },
  LEAVINGS: {
    name: "leavings",
    path: "/leavings"
  },
  DOCUMENTS: {
    name: "documents",
    path: "/documents"
  },
  ORDERS: {
    name: "orders",
    path: "/orders"
  },
  ORDERS_INFO: {
    name: "orders-info",
    path: "/orders/:orderId"
  },
  TRADING_POINTS: {
    name: "tradingPoints",
    path: "/tradingPoints"
  },
  REVIEWS: {
    name: "reviews",
    path: "/reviews"
  }
}
