import { actionTypes } from "./constants"

import { loginUser } from "@/api/lib/auth"
import { setCookie } from "@/helpers/cookies"
import * as cookieKeys from "@/constants/cookieKeys"

const actions = {
  [actionTypes.LOGIN_USER]: async (_, { email, password }) => {
    const res = await loginUser(email, password)

    setCookie(cookieKeys.TOKEN, res.data.token)
  }
}

export default actions
