<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99606 0.142821C9.18031 -0.0450028 9.48526 -0.0450028 9.66951 0.142821C9.85375 0.330645 9.85375 0.641526 9.66951 0.829349L0.813091 9.85785C0.628847 10.0457 0.323892 10.0457 0.139648 9.85785C-0.0445961 9.67002 -0.0445961 9.35914 0.139648 9.17132L8.99606 0.142821Z"
      :fill="color"
    />
    <path
      d="M9.86043 9.17083C10.0447 9.35865 10.0447 9.66954 9.86043 9.85736C9.67618 10.0452 9.37123 10.0452 9.18698 9.85736L0.330566 0.828861C0.146322 0.641037 0.146322 0.330157 0.330566 0.142333C0.51481 -0.0454911 0.819765 -0.0454911 1.00401 0.142333L9.86043 9.17083Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#fff"
    }
  }
}
</script>
