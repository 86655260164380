import { uuid } from "vue-uuid"

class TimelineRow {
  constructor(data) {
    this.id = data?.id || uuid.v4()
    this.rowLabel = data.rowLabel
    this.type = data.type
    this.subItems = data.subItems
    this.additionalElements = data?.additionalElements || []
    this.value = data?.value || null
  }
}

export default TimelineRow
