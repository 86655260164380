<template>
  <div class="app-checkbox">
    <div
      @click.stop="$emit('onPress')"
      class="app-checkbox--button"
      :style="{ backgroundColor: checked ? '#000' : '#fff' }"
    >
      <CheckIcon v-if="checked" />
    </div>
    <span
      @click.stop="$emit('onCheckLabel')"
      @mouseover="$emit('onLabelMouseOver', $event)"
      @mouseout="$emit('onLabelMouseOut', $event)"
      class="app-checkbox--label"
      :style="{ color: labelColor }"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import CheckIcon from "./svg/CheckIcon.vue"

export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ""
    },

    labelColor: {
      type: String,
      default: "#000"
    }
  },

  emits: ["onLabelMouseOver", "onLabelMouseOut", "onPress", "onCheckLabel"],

  components: {
    CheckIcon
  }
}
</script>

<style lang="scss" scoped>
.app-checkbox {
  display: flex;
  align-items: center;
  width: fit-content;

  &--button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid $grey2;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
  }

  &--label {
    font-size: 14px;
    line-height: 16px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
