<template>
  <div class="page-wrapper">
    <PageTitle class="page-title" title="Відгуки" />
    <v-search
      class="search"
      v-model="searchTerm"
      placeholder=""
      :name-store="prevSearchList"
      :list-searches="searchHistory"
      @input="onSearch($event)"
      @change="onSearch($event)"
    />
    <div class="table-wrapper">
      <AppTable
        :list="reviewsData?.data || []"
        :openRowOnIcon="true"
        @rowClicked="changeDetailsStatus($event)"
      >
        <template v-slot:header>
          <div class="table-grid">
            <div class="table-grid--head">дата cтворення</div>
            <div class="table-grid--head">дата оновлення</div>
            <div class="table-grid--head">номер</div>
            <div class="table-grid--head">клієнт</div>
            <div class="table-grid--head">товар</div>
            <div class="table-grid--head">відгук</div>
            <div class="table-grid--head ta-r">
              <v-drop-down-slots-fixed class="table-grid--sort">
                <template v-slot:toggler>
                  <span class="table-grid--sort_toggle">статус</span>
                </template>
                <template v-slot:content>
                  <ul class="table-grid--sort_content">
                    <li
                      v-for="(value, key) in sortOption"
                      :key="key"
                      :class="{ active: key === sort }"
                      @click="onSort(key)"
                    >
                      {{ value }}
                    </li>
                  </ul>
                </template>
              </v-drop-down-slots-fixed>
            </div>
          </div>
        </template>
        <template
          #body="{
            created_at,
            updated_at,
            number,
            customer,
            product,
            feedback,
            status,
            id
          }"
        >
          <ReviewItem
            @modal:open="openModal"
            :created_at="created_at"
            :updated_at="updated_at"
            :number="number"
            :customer="customer"
            :product="product"
            :feedback="feedback"
            :status="status"
            :id="id"
          />
        </template>
        <template #endElement>
          <span></span>
        </template>
      </AppTable>

      <v-modal v-show="showModal" :show="showModal" @close="closeModal">
        <template #main>
          <modal-reviews
            v-if="currentId"
            :key="currentId"
            :current-id="currentId"
            :current-review="currentReviewData"
            @closeModal="closeModal(true)"
          />
        </template>
      </v-modal>
    </div>
    <div class="leavings-footer" v-if="(reviewsData?.data || []).length">
      <CountElements
        :current="reviewsData.to || NaN"
        :total="reviewsData.total || NaN"
      />
      <PaginationLaravel
        :info="reviewsData"
        @changePage="onChangePage($event)"
      />
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import modalReviews from "./components/modalReviews.vue"
import ReviewItem from "./components/ReviewItem.vue"

import AppTable from "@/components/AppTable.vue"
import PageTitle from "@/components/PageTitle"
import CountElements from "@/components/pagination/countElements.vue"
import PaginationLaravel from "@/components/pagination/paginationLaravel.vue"
import VDropDownSlotsFixed from "@/components/ui/v-drop-down-slots-fixed"
import vModal from "@/components/v-modal.vue"
import { PREV_SEARCH_REVIEWS } from "@/constants/localStorageKeys.js"
import { getItemlocalStorage } from "@/helpers/storage.js"
import { debounce } from "@/helpers/strings"
import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/reviews"

const SORT_OPTIONS = {
  ALL: "всі",
  NEW: "новий",
  RENEWED: "оновлений",
  PROBLEMATIC: "опублікований",
  PUBLISHED: "проблемний"
}

export default {
  name: "Reviews",
  components: {
    PageTitle,
    AppTable,
    modalReviews,
    vModal,
    PaginationLaravel,
    CountElements,
    VDropDownSlotsFixed,
    ReviewItem
  },

  async mounted() {
    const { query } = this.$route

    if (query?.page) {
      this.page = query.page
    }

    this.onFetchReviews(this.page)
  },

  data() {
    return {
      searchTerm: "",
      showModal: false,
      currentId: null,
      sort: Object.keys(SORT_OPTIONS)[0],
      page: 1,
      searchHistory: []
    }
  },

  computed: {
    ...mapGetters(namespace, ["reviewsData", "currentReviewData"]),

    prevSearchList() {
      return PREV_SEARCH_REVIEWS
    },

    sortOption() {
      return SORT_OPTIONS
    }
  },

  methods: {
    ...mapActions(namespace, ["fetchReviews", "fetchReview"]),

    getSearchHistory() {
      return (getItemlocalStorage(PREV_SEARCH_REVIEWS) || []).slice(0, 5)
    },

    closeModal(updateData = false) {
      this.showModal = false

      if (updateData) {
        this.onFetchReviews(this.page)
      }
    },

    async onFetchReviews(page) {
      const params = {
        s: this.searchTerm || undefined,
        sort: this.sort === "ALL" ? undefined : this.sort,
        page: page ? page : this.page
      }

      this.onChangeRouterPage(page)
      await this.fetchReviews(params)
    },

    async openModal(ID) {
      await this.fetchReview(ID)
      this.currentId = ID
      this.showModal = true
    },

    onSort(key) {
      if (key !== this.sort) {
        this.sort = key
      }

      this.onFetchReviews(1)
    },

    onChangeRouterPage(page) {
      this.$router.push({
        name: ROUTE.REVIEWS.name,
        query: { ...this.$route.query, page }
      })
    },

    onChangePage(page) {
      this.page = page
      this.onFetchReviews(page)
    },

    trimArray(arr, maxLength) {
      return arr.length > maxLength ? arr.slice(0, maxLength) : arr
    },

    onSearch(value) {
      this.searchTerm = typeof value === "string" ? value : value.target.value
      this.searchHistory = this.trimArray(this.getSearchHistory(), 5)
      debounce(() => {
        this.onFetchReviews(1)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
}
.page-title {
  margin-bottom: 40px;
}
.search {
  margin-bottom: 40px;
}

:deep .timeline-container {
  height: fit-content;
  .row-label {
    padding-left: 16px;
  }
}

.table-wrapper {
  display: flex;
  overflow: auto;
}

:deep .table-header {
  padding: 8px 18px;
}

.table-grid {
  display: grid;
  grid-template-columns:
    calc(111px - 16px) calc(111px - 16px) 48px 240px 280px minmax(252px, 1fr)
    157px;
  align-items: center;
  gap: 35px;
  color: $black;

  &.body {
    padding: 13px 0;
  }

  &--head {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }

  &--sort {
    width: 100%;

    :deep .v-drop-down--head {
      justify-content: flex-end;
    }

    &_content {
      padding: 24px 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

      li {
        cursor: pointer;

        &.active {
          font-weight: 600;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  // &--body {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2px;
  //   font-size: 14px;
  //   line-height: 16px;
  // }
}

.ta-r {
  text-align: right;
}

.leavings-footer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
</style>
