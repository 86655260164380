<template>
  <div class="timeline-container">
    <div class="timeline-header">
      <span class="timeline-header-name">Назва / Тип</span>
      <div class="dates">
        <TimelineDate
          v-for="(date, idx) in datesBetween"
          :key="date.formatedDate"
          :date="date"
          :showDate="showDate(idx)"
          :showDateChip="showDateChip(idx)"
          :isLast="checkIsLastDateChip(idx)"
          @onChangeWidth="onChangeWidth"
          @onSelectDate="onSelectDate"
        />
      </div>
    </div>
    <div class="timeline-rows">
      <div
        class="vertical-divider"
        :style="{ left: `${getVerticalDividerOffset}px` }"
      />
      <div
        v-for="row in filteredRows"
        :key="row.id"
        class="timeline-rows--item"
      >
        <div class="row-label">
          <span
            class="timeline-rows--item-name"
            :class="[
              type === TIMELINE_TYPE.PROMOTION &&
                'timeline-rows--item-name-promotion'
            ]"
            @click="onPressTitleHandler(row)"
          >
            {{ row.rowLabel }}
          </span>
          <template v-if="type === TIMELINE_TYPE.PROMOTION">
            <PromotionChip
              v-if="row?.value?.label"
              class="promo-chip"
              :label="row.value.label.text"
              :background-color="row.value.label.color"
            />
            <span
              v-if="row.type === PROMOTION_TYPE.CUPON"
              class="promotion-type"
            >
              {{ row.type }}
            </span>
          </template>
        </div>
        <div class="row-elements">
          <TimelineElement
            v-for="subitem in row.subItems"
            :key="subitem.id"
            :data="subitem"
            :type="type"
            :backgroundColor="subitem.backgroundColor"
            :width="getElementWidth(subitem, 'banner')"
            :style="{
              left: `${getElementLeftOffset(subitem)}px`
            }"
            @click="onPressElementHandler(row, subitem)"
          />
          <TimelineElement
            v-for="(element, idx) in row.additionalElements"
            :key="element.id"
            :data="element"
            :backgroundColor="element.backgroundColor"
            :width="getElementWidth(element, 'button', row.subItems[idx])"
            :style="{
              left: `${getElementLeftOffset(element, row.subItems[idx])}px`,
              maxWidth: idx === row.additionalElements.length - 1 && '84px'
            }"
            @click="onPressElementHandler(row, element)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import dayjs from "dayjs"
import { uuid } from "vue-uuid"

import TimelineDate from "./components/TimelineDate.vue"
import TimelineElement from "./components/TimelineElement.vue"

import PromotionChip from "@/components/PromotionChip.vue"
import { DATE_FORMATS } from "@/constants/date"
import { PROMOTION_TYPES } from "@/constants/promotions"
import { TIMELINE_TYPES, TIMELINE_ELEMENT_STATUS } from "@/constants/timeline"
import {
  getDatesBetween,
  formatDate,
  checkIfDateIsBetween,
  isDateRangeValid
} from "@/helpers/date"
import TimelineSubitem from "@/models/timeline/TimelineSubitem"

const MAX_DAYS_IN_WEEK = 7
const MAX_HOURS_IN_DAY = 24

export default {
  components: {
    TimelineDate,
    TimelineElement,
    PromotionChip
  },

  props: {
    data: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      default: TIMELINE_TYPES.BANNER
    }
  },

  emits: ["onPressElement"],

  setup() {
    const TIMELINE_TYPE = TIMELINE_TYPES
    const PROMOTION_TYPE = PROMOTION_TYPES

    return {
      TIMELINE_TYPE,
      PROMOTION_TYPE
    }
  },

  mounted() {
    this.rows = cloneDeep(this.data)
    this.setTimeLineData()

    if (!this.isPromotionType) {
      this.addAdditionalElements()
    }
  },

  data: () => ({
    startDate: dayjs().subtract(1, "week").startOf("day").toDate(),
    endDate: dayjs().add(4, "week").startOf("day").toDate(),
    datesBetween: [],
    dateElement: {
      width: 0,
      offsetLeft: 0
    },

    rows: []
  }),

  computed: {
    startTimeLineDateMs() {
      return dayjs(this.startDate).valueOf() - 86400000
    },

    endTimeLineDateMs() {
      return dayjs(this.endDate).valueOf()
    },

    houreWidth() {
      return this.dateElement.width / MAX_HOURS_IN_DAY
    },

    currentDate() {
      return formatDate(dayjs().toDate(), "YYYY-MM-DD HH:mm:ss")
    },

    filteredRows() {
      const filteredRows = this.rows.map((row) => {
        const filteredSubitems = row.subItems.filter((subItem) => {
          return isDateRangeValid({
            data: {
              start: subItem.startDate,
              end: subItem.endDate
            },
            range: {
              start: this.startDate,
              end: this.endDate
            }
          })
        })
        // потрібно переглянути коректність роботи
        const filteredAddElements = row.additionalElements.filter((element) => {
          const start = checkIfDateIsBetween(
            element.startDate,
            this.startDate,
            this.endDate
          )
          const end = checkIfDateIsBetween(
            element.endDate,
            this.startDate,
            this.endDate
          )

          return start && end
        })

        return {
          ...row,
          subItems: filteredSubitems,
          additionalElements: filteredAddElements
        }
      })

      return filteredRows
    },

    getVerticalDividerOffset() {
      const index = this.datesBetween.findIndex(
        (el) =>
          el.formatedDate === formatDate(this.currentDate, DATE_FORMATS.DDMM)
      )
      const { offsetLeft, width } = this.dateElement

      return Math.ceil(
        index >= 1 ? this.dateElement.offsetLeft + width * index : offsetLeft
      )
    },

    isPromotionType() {
      return this.type === TIMELINE_TYPES.PROMOTION
    }
  },

  methods: {
    setTimeLineData() {
      this.datesBetween = getDatesBetween(this.startDate, this.endDate)
    },

    onPressElementHandler(row, subElement) {
      if (this.isPromotionType) return

      this.$emit("onPressElement", { row, element: subElement })
    },

    onPressTitleHandler(row) {
      if (!this.isPromotionType) return

      this.$emit("onPressElement", { row })
    },

    onSelectDate(date, isLast) {
      if (isLast) {
        this.endDate = date
      } else {
        this.startDate = date
      }

      this.setTimeLineData()
    },

    showDate(idx) {
      if (this.datesBetween.length <= MAX_DAYS_IN_WEEK) {
        return (
          idx % MAX_DAYS_IN_WEEK === 0 || idx === this.datesBetween.length - 1
        )
      }

      if (idx >= this.datesBetween.length - 4) return false

      return idx % MAX_DAYS_IN_WEEK === 0
    },

    showDateChip(idx) {
      if (this.datesBetween.length <= MAX_DAYS_IN_WEEK) {
        return (
          idx % MAX_DAYS_IN_WEEK === 0 || idx === this.datesBetween.length - 1
        )
      }

      const isLast = this.checkIsLastDateChip(idx)

      return idx === 0 || isLast
    },

    checkIsLastDateChip(idx) {
      return this.datesBetween.length - 1 === idx
    },

    onChangeWidth(data) {
      const { width, offsetLeft } = data

      if (this.dateElement.width !== width) {
        this.dateElement.width = Math.floor(width)
      }

      if (this.dateElement.offsetLeft === 0) {
        this.dateElement.offsetLeft = Math.floor(offsetLeft)
      }
    },

    getElementWidth(element, type, mainElement) {
      let endDate = this.datesBetween.findIndex(
        (date) =>
          date.formatedDate === formatDate(element.endDate, DATE_FORMATS.DDMM)
      )

      if (type === PROMOTION_TYPES.BANNER && endDate < 0) {
        endDate = this.datesBetween.findIndex(
          (date) =>
            date.formatedDate === formatDate(this.endDate, DATE_FORMATS.DDMM)
        )
      }

      if (
        !!mainElement &&
        mainElement.endDate > dayjs(this.endDate).valueOf()
      ) {
        return 0
      }

      return this.getBannerHoursWidth(element)
    },

    getMsHours(ms) {
      return Math.floor((ms / 1000 / 60 / 60) << 0)
    },

    getBannerHoursWidth(element) {
      let startDate = element?.startDate
      let endDate = element?.endDate

      if (this.startTimeLineDateMs > startDate) {
        startDate = this.startTimeLineDateMs
      }

      if (endDate > this.endTimeLineDateMs) endDate = this.endTimeLineDateMs

      return (
        (this.getMsHours(endDate) - this.getMsHours(startDate)) *
          this.houreWidth || 0
      )
    },

    getElementLeftOffset(element, mainElement = null) {
      const startDate =
        element.startDate > this.startTimeLineDateMs
          ? element.startDate - this.startTimeLineDateMs
          : this.startTimeLineDateMs - this.startTimeLineDateMs

      let offset =
        this.getMsHours(startDate) * this.houreWidth +
        this.dateElement.offsetLeft

      if (mainElement && mainElement.endDate > this.endTimeLineDateMs) {
        return (
          offset -
          this.getMsHours(mainElement.endDate - this.endTimeLineDateMs) *
            this.houreWidth
        )
      }

      return offset
    },

    getAdditionalElement(startDate, endDate) {
      return new TimelineSubitem({
        id: uuid.v4(),
        label: "+ додати",
        startDate: startDate,
        endDate: endDate,
        status: TIMELINE_ELEMENT_STATUS.NEW
      })
    },

    addAdditionalElements() {
      const firstDate = this.datesBetween[0].rawDate
      const lastDate = this.datesBetween[this.datesBetween.length - 1].rawDate
      const index = this.datesBetween.findIndex(
        (el) => el.formatedDate === formatDate(dayjs().toDate(), "DD.MM")
      )
      const currentDate =
        index > 1 ? this.datesBetween[index].rawDate : firstDate
      const updatedRows = cloneDeep(this.data)

      const isMilliseconds = (date) => {
        return Date.parse(date)
      }

      const isInRangeElement = (i, subItems) => {
        return i !== subItems.length - 1
          ? checkIfDateIsBetween(
              subItems[i + 1].startDate,
              this.startDate,
              this.endDate
            )
          : true
      }

      for (const [rowIdx, row] of updatedRows.entries()) {
        switch (row.subItems.length) {
          case 0:
            // за замовчуванням ставиться біля поточної дати, якщо немає банерів в категорії
            updatedRows[rowIdx].additionalElements.push(
              this.getAdditionalElement(currentDate, lastDate)
            )
            break

          default:
            // сортую по початковій даті, тому що кнопку додати тягнути до початку наступного банеру
            row.subItems.sort(
              (a, b) => new Date(a.startDate) - new Date(b.startDate)
            )

            for (let i = 0; i < row.subItems.length; i++) {
              const endDateMS =
                typeof row.subItems[i].endDate === "number"
                  ? row.subItems[i].endDate
                  : isMilliseconds(row.subItems[i].endDate)

              if (endDateMS > currentDate && row.subItems.length >= 1) {
                updatedRows[rowIdx].additionalElements.push(
                  this.getAdditionalElement(
                    row.subItems[i].endDate,
                    i === row.subItems.length - 1 ||
                      !isInRangeElement(i, row.subItems)
                      ? lastDate
                      : row.subItems[i + 1].startDate
                  )
                )
              }
            }

            // додає кнопку якщо немає жодної
            if (!row.additionalElements.length) {
              updatedRows[rowIdx].additionalElements.push(
                this.getAdditionalElement(currentDate, lastDate)
              )
            }
        }
      }

      this.rows = cloneDeep(updatedRows)
    }
  },

  watch: {
    data() {
      this.rows = cloneDeep(this.data)

      if (!this.isPromotionType) {
        this.addAdditionalElements()
      }
    },

    endDate() {
      this.addAdditionalElements()
    },

    startDate() {
      this.addAdditionalElements()
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.date {
  display: flex;
  flex: 1;
  height: 10px;
  border-right: 1px solid #000;
}
.timeline-header {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid $grey4;

  &-name {
    display: inline-block;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    padding-left: 16px;
    min-width: 396px;
  }
}

.dates {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.timeline-rows {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 57px;
    border-bottom: 1px solid $grey6;

    &-name {
      font-size: 14px;
      line-height: 16px;
      padding-left: 16px;
    }

    &-name-promotion {
      min-width: 168px;
      max-width: 168px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.row-label {
  display: flex;
  gap: 16px;
  min-width: 396px;
}

.row-elements {
  display: flex;
  align-items: center;
  width: 100%;
}

.promotion-type {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  text-transform: lowercase;
}
.vertical-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 1px dashed $grey4;
}
</style>
