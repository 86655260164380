<template>
  <div class="product-item-wrapper" v-if="product">
    <div class="product-item">
      <picture class="product-item--img">
        <img :src="product.image + 'small'" :alt="'Image' + product.name" />
      </picture>
      <div class="product-item--content">
        <div>
          <h5 class="product-item--title">{{ product.brand }}</h5>
          <p class="product-item--desc">
            {{ product.name }}
          </p>
        </div>
        <div class="product-item--count">{{ product.quantity }}</div>
        <div class="product-item--decount">
          {{ product.correct_quantity || "" }}
        </div>
        <div class="product-item--price" v-if="product.type === 'default'">
          {{ product.price }}₴
        </div>
        <div class="product-item--gift" v-else>
          <img :src="iconGift" alt="Icon gift" />
        </div>
      </div>
    </div>
    <div v-if="review" class="product-item--review">
      погано наноситься на шкіру, не подобається
    </div>
  </div>
</template>

<script>
import iconGift from "../assets/icons/gift.svg"
export default {
  name: "productItem",
  props: {
    product: {
      type: Object
    },

    review: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({ iconGift })
}
</script>

<style lang="scss" scoped>
.product-item {
  display: grid;
  grid-template-columns: 72px auto;
  gap: 24px;
  align-items: center;
  &--img {
    width: 72px;
    height: 72px;
    background-color: #ccc;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &--content {
    display: grid;
    grid-template-columns: auto 40px 40px 80px;
    gap: 24px;
    align-items: center;
  }
  &--review {
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    margin-left: calc(72px + 24px);
  }
  &--title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
  }
  &--desc {
    font-size: 14px;
    line-height: 16px;
  }
  &--count {
    font-size: 14px;
    line-height: 16px;
  }
  &--decount {
    font-size: 14px;
    line-height: 16px;
    color: #d8112c;
    background-color: #f2f2f2;
    border-radius: 8px;
    width: fit-content;
    padding: 0 4px;
    min-width: 24px;
    box-sizing: border-box;
    text-align: center;
  }

  &--price {
    font-size: 16px;
    line-height: 16px;
  }

  &--gift {
    img {
      width: 30px;
      height: 30px;
      margin-left: auto;
      display: block;
    }
  }
}
</style>
