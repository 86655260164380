<template>
  <div
    class="image-item"
    :style="{ backgroundImage }"
    @mouseover.stop="onMouseOver"
    @mouseleave.stop="isHover = false"
    @click="openChooseWindow"
  >
    <div
      v-if="isShowLinkInput"
      class="link-input-container"
      @click.stop
      v-click-outside="hideLinkInput"
    >
      <v-input
        :modelValue="item.link"
        input-type="url"
        class="v-input--circle circular-input"
        @update:modelValue="onChangeLink"
        style="height: 32px"
      >
        <template #action>
          <AppSquareButton
            class="clear-button"
            backgroundColor="transparent"
            iconColor="#000"
            @onPress="hideLinkInput"
          />
        </template>
      </v-input>
    </div>
    <div class="wrapper">
      <div v-if="isHover" class="overlay" />
      <AppSquareButton
        v-if="isHover && disabled"
        class="link-button"
        :type="BUTTON_TYPES.LINK"
        backgroundColor="#fff"
        iconColor="#000"
        @click="onShowLinkInput"
      />
      <AppSquareButton
        v-else-if="item.link"
        class="link-button"
        :type="BUTTON_TYPES.LINK"
        backgroundColor="#99cccd"
        iconColor="#fff"
      />
      <div v-if="loading" class="label">loading ...</div>
      <div v-else-if="!item?.image?.url || isHover" class="label">
        <span class="label--text" :style="{ color: textColor }">
          + завантажити
        </span>
        <span class="label--resolution" :style="{ color: textColor }">
          140 * 184 px
        </span>
      </div>
      <input ref="image" hidden type="file" @change="onChooseImage" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import AppSquareButton from "@/components/AppSquareButton.vue"
import { SQUARE_BUTTON_TYPES } from "@/constants/squareButton"
import { readFileAsync } from "@/helpers/files"
import { isValidUrl } from "@/helpers/strings.js"
import { namespace } from "@/store/modules/sectionsAndForms"

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onChangeLink", "onSelectImage"],

  components: {
    AppSquareButton
  },

  setup() {
    const BUTTON_TYPES = SQUARE_BUTTON_TYPES

    return {
      BUTTON_TYPES
    }
  },

  mounted() {
    this.image = this.item.image
  },

  data: () => ({
    image: {
      url: ""
    },

    isHover: false,
    isShowLinkInput: false,
    stopOpenDirectory: true,
    loading: false
  }),

  computed: {
    backgroundImage() {
      return this.image.url ? `url(${this.image.url})` : ""
    },

    textColor() {
      return this.image.url ? "rgba(255,255,255,0.8)" : ""
    }
  },

  methods: {
    ...mapActions(namespace, ["uploadImage"]),
    onMouseOver() {
      if (this.image.url) {
        this.isHover = true
      }
    },

    openChooseWindow() {
      if (!this.disabled || !this.stopOpenDirectory) return

      this.$refs.image.click()
    },

    async onChooseImage(e) {
      const file = e.target.files[0]

      if (!file) return

      this.loading = true
      const imgBase64 = await readFileAsync(file)
      const imgCDN = await this.uploadImage({ imgData: imgBase64 })

      this.image.url = imgCDN.url
      this.loading = false
      this.$emit("onSelectImage", imgCDN)
    },

    onShowLinkInput(event) {
      this.isShowLinkInput = !this.isShowLinkInput

      if (this.isShowLinkInput) {
        this.stopOpenDirectory = false
      } else {
        event.stopPropagation()
        this.stopOpenDirectory = true
      }
    },

    hideLinkInput() {
      this.isShowLinkInput = false
      this.stopOpenDirectory = true
    },

    onChangeLink(link) {
      if (isValidUrl(link) || !link) {
        this.$emit("onChangeLink", link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-item {
  height: 184px;
  max-width: 140px;
  min-width: 140px;
  background-color: $grey6;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.wrapper {
  position: relative;

  width: 100%;
  height: 100%;
}

.label {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  font-size: 14px;
  line-height: 16px;

  &--text {
    margin-bottom: 4px;
  }

  &--resolution {
    font-weight: 300;
    color: rgba($color: #000000, $alpha: 0.8);
  }
}

.overlay {
  position: absolute;

  inset: 0;
  background-color: rgba($color: #666, $alpha: 0.6);
}

.link-button {
  position: absolute;
  right: 0;
  z-index: 1;
}

.link-input-container {
  position: absolute;
  top: 15%;
  min-width: 434px;
  height: 48px;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 3;
}
</style>
