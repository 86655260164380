<template>
  <button
    class="button"
    :class="[disabled && 'disabled']"
    :disabled="disabled"
    @click="onPressHandler"
  >
    <span class="label">{{ label }}</span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onPress"],

  methods: {
    onPressHandler() {
      if (this.disabled) return

      this.$emit("onPress")
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  background-color: #000;
  width: fit-content;
  padding: 12px 30px;
  border: none;
  border-radius: 33px;
  outline: none;

  &:hover {
    &:not([disabled]) {
      background-color: rgba($color: #000, $alpha: 0.7);
    }
  }
}

.disabled {
  background-color: $grey5;
}

.label {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}
</style>
