<template>
  <router-link :to="href" class="nav-link">
    <slot name="icon" />
    <span class="nav-link--label" :class="[active && 'active-link']">
      <slot name="label" />
    </span>
    <WarningIcon v-if="error" />
  </router-link>
</template>

<script>
import WarningIcon from "./svg/WarningIcon.vue"

export default {
  props: ["href", "active", "error"],

  components: {
    WarningIcon
  },

  data: () => ({
    isHover: false,
    isOpen: false
  }),

  methods: {
    changePage(href) {
      this.$router.push({ path: href })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #fff;
  padding: 10px 16px 10px 48px;
  border-bottom: 1px solid #eff3f2;
  text-decoration: none;
  &--label {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    margin-right: 12px;
  }
}

.active-link {
  text-decoration: underline;
}
</style>
