import { alphabetOrdering } from "./../../../helpers/strings"
import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_FILTERS](state, data) {
    const mutationData = data.map((group) => ({
      id: group.group_id,
      name: group.group_name,
      items: group.items.map((sub_group) => ({
        ...sub_group,
        id: sub_group.id,
        name: sub_group.filter_name,
        icon: {
          url: sub_group.image_url ? `${sub_group.image_url}/small` : null
        }
      })),
      validate: group.validate,
      type: group.type,
      visibility_type: group.visibility_type,
      code: group.code
    }))

    state.groups = alphabetOrdering(mutationData) || []
  },

  [mutationTypes.CHANGE_TYPE_AND_VISIBILITY_TYPE](
    state,
    { id: groupId, type, visibility_type }
  ) {
    const groupItem = state.groups.find((item) => item.id === groupId)

    groupItem.type = type
    groupItem.visibility_type = visibility_type
  },

  [mutationTypes.CHANGE_ICON_FROM_ITEM](state, { groupId, imageUrl, itemId }) {
    const groupItem = state.groups.find((item) => item.id === groupId)
    const groupItemChild = groupItem.items.find((item) => item.id === itemId)

    groupItemChild.icon.url = imageUrl ? `${imageUrl}/small` : null
  },

  [mutationTypes.CHANGE_VALIDATION](state, { groupId, itemId }) {
    const groupItem = state.groups.find((item) => item.id === groupId)

    // Якщо зміна групи
    if (!itemId) {
      groupItem.validate = 1
      groupItem.items = groupItem.items.map((item) => ({
        ...item,
        validate: 1
      }))
    } else {
      // Якщо зміна item в групі
      const groupItemChild = groupItem.items.find((item) => item.id === itemId)

      groupItemChild.validate = 1
    }
  },
  [mutationTypes.CHANGE_ORDER_FILTERS](state, { data, groupId }) {
    state.groups.find((group) => group.id === groupId).items = data
  }
}
