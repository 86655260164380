<template>
  <div class="banners">
    <div
      class="banners-grid"
      :class="{
        vertical: isTypeBannerHeader,
        top: !isTypeBannerHeader && bannerType === 'top'
      }"
    >
      <div class="banners-grid--item">
        <div
          class="banners-desktop"
          :class="[{ border: counts > 1, header: bannerType === 'header' }]"
          :style="{
            height: params?.desktop?.height + 'px',
            width: params?.desktop?.width + 'px'
          }"
        >
          <input-image
            :ref="generateKey(platforms.DESKTOP)"
            :url="banners.desktop?.url"
            class="banners--item"
            @createImage="addBanner($event, platforms.DESKTOP)"
            @click="openDownloadWindow(platforms.DESKTOP)"
          />
          <div
            class="banners--items"
            :class="{ 'z-index--2': banners.desktop?.url }"
          >
            <div
              v-for="el of convertsNumberToArray(counts)"
              :key="generateKey(platforms.DESKTOP, el)"
            ></div>
          </div>
          <text-placeholder
            :text="params?.desktop?.text"
            :inline="isTypeBannerHeader"
            :text-size="params?.desktop?.placeholder"
          />
        </div>
        <DeleteBannerImage
          v-show="banners.desktop?.url"
          :label="banners.desktop?.name || `image desktop #0`"
          :style="{
            maxWidth: params?.desktop?.width + 'px'
          }"
          @click="removeBanner(platforms.DESKTOP)"
        />
        <div
          v-show="
            (banners.desktop?.url || banners.mobile?.url) &&
            !banners.desktop?.url
          "
          class="banners-delete--empty"
        ></div>
      </div>
      <div class="banners-grid--item">
        <div
          class="banners-mobile"
          :class="[{ border: counts > 1 }]"
          :style="{
            height: params?.mobile?.height + 'px',
            width: params?.mobile?.width + 'px'
          }"
        >
          <input-image
            :ref="generateKey(platforms.MOBILE)"
            :url="banners.mobile?.url"
            class="banners--item"
            @createImage="addBanner($event, platforms.MOBILE)"
            @click="openDownloadWindow(platforms.MOBILE)"
          />
          <div
            class="banners--items"
            :class="{ 'z-index--2': banners.mobile?.url }"
          >
            <div
              v-for="el of convertsNumberToArray(counts)"
              :key="generateKey(platforms.MOBILE, el)"
            ></div>
          </div>
          <text-placeholder
            :text="params?.mobile?.text"
            :inline="isTypeBannerHeader"
            :text-size="params?.mobile?.placeholder"
          />
        </div>
        <DeleteBannerImage
          v-show="banners.mobile?.url"
          :label="banners.mobile?.name || `image mobile #0}`"
          :style="{
            maxWidth: params?.mobile?.width + 'px'
          }"
          @click="removeBanner(platforms.MOBILE)"
        />
        <div
          v-show="
            (banners.desktop?.url || banners.mobile?.url) &&
            !banners.mobile?.url
          "
          class="banners-delete--empty"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import InputImage from "./components/InputImage.vue"
import TextPlaceholder from "./components/TextPlaceholder.vue"

import DeleteBannerImage from "@/components/DeleteBannerImage.vue"
import { PLATFORM, TYPE_BANNER } from "@/constants/banners.js"
import { convertsNumberToArray } from "@/helpers/files"

export default {
  name: "input-banner",
  components: { DeleteBannerImage, InputImage, TextPlaceholder },
  props: {
    counts: {
      type: Number,
      required: true,
      default: 1
    },

    banners: {
      type: Object,
      required: true,
      default: () => ({ desktop: null, mobile: null })
    },

    params: {
      type: Object
    },

    bannerType: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["addBanner", "removeBanner"],
  computed: {
    platforms() {
      return PLATFORM
    },

    typeBanner() {
      return TYPE_BANNER
    },

    isTypeBannerHeader() {
      return this.typeBanner.HEADER === this.bannerType
    }
  },

  methods: {
    convertsNumberToArray,

    generateKey(platforms, idx) {
      return idx ? platforms + "_" + idx : platforms
    },

    openDownloadWindow(name) {
      const nameRef = this.generateKey(name)

      if (!this.disabled) {
        this.$refs[nameRef].$refs.inputPicture.click()
      }
    },

    addBanner(image, platform) {
      const newBanner = {
        name: image.name,
        imgData: image.imgData,
        url: image.url,
        type: platform
      }

      if (!this.disabled) {
        this.$emit("addBanner", { banner: newBanner })
      }
    },

    removeBanner(platform) {
      const nameRef = this.generateKey(platform)

      if (!this.disabled) {
        this.$emit("removeBanner", { platform })
      }

      // очищаємо полу files в input
      this.$refs[nameRef].$refs.inputPicture.form.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.banners {
  width: 100%;

  &-grid {
    display: flex;
    align-items: flex-end;
    gap: 24px;

    &.vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &.top {
      gap: 34px;
    }

    &--item {
      max-width: 100%;
    }
  }

  &--item {
    display: flex;
    width: 100%;
    z-index: 1;
    cursor: pointer;
  }

  &-mobile,
  &-desktop {
    position: relative;
    display: flex;
    background-color: $grey6;
    max-width: 100%;

    &.border {
      border: 1px solid $grey4;
    }

    &.header {
      max-width: 1280px;
    }
  }

  &-mobile {
    background-color: $grey6;
  }

  &--items {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;

    &.z-index--2 {
      z-index: 2;
    }

    div {
      flex: 1;

      &:nth-child(2),
      &:nth-child(3) {
        border-left: 1px solid $grey4;
      }
    }
  }

  &-delete {
    &--empty {
      visibility: hidden;
      height: 16px;
      margin-top: 8px;
      width: 100%;
    }
  }

  :deep .image-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
    overflow: hidden;
  }
}
</style>
