import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "auth"

const state = () => ({
  user: null
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
