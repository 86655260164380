import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/components"

export default {
  components() {
    return sendSecureRequest().get(`${ENDPOINT}`)
  },
  uploadImage(data) {
    return sendSecureRequest().post(`${ENDPOINT}/upload-image`, data)
  },
  deleteImage(data) {
    return sendSecureRequest().delete(
      `${ENDPOINT}/upload-image?id=${data.id}&imgData=${data.imgData}`
    )
  }
}
