import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "orders"

const state = () => ({
  orders: null
})

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
