class GroupDropdownItem {
  constructor(
    id,
    name,
    sectionId,
    icon = { url: null, file: null },
    description = ""
  ) {
    this.id = id
    this.name = name
    this.sectionId = sectionId
    this.icon = icon
    this.description = description
  }
}

export default GroupDropdownItem
