<template>
  <div v-if="info.last_page > 1" class="pagination">
    <div
      v-if="toFirstPage > 1"
      class="pagination-item pagination-item--side pagination-item--side-back-page"
      @click="changePage(1)"
    >
      {{ "<<" }}
    </div>
    <div
      v-if="toFirstPage"
      class="pagination-item pagination-item--side pagination-item--side-first-page"
      @click="changePage(info.current_page - 1)"
    >
      {{ "<" }}
    </div>
    <div
      v-for="pageNumber in paginationData"
      :key="pageNumber"
      class="pagination-item"
      :class="{ active: pageNumber === info.current_page }"
      @click="changePage(pageNumber)"
    >
      {{ pageNumber }}
    </div>
    <div
      v-if="toLastPage"
      class="pagination-item pagination-item--side pagination-item--side-next-page"
      @click="changePage(info.current_page + 1)"
    >
      {{ ">" }}
    </div>
    <div
      v-if="toLastPage > 1"
      class="pagination-item pagination-item--side pagination-item--side-last-page"
      @click="changePage(info.last_page)"
    >
      {{ ">>" }}
    </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "PaginationLaravel",
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    paginationData() {
      const page = this.info.current_page
      const last = this.info.last_page

      if (last < 5) {
        return _.range(1, last + 1)
      }

      if (this.info.current_page <= 3) {
        return _.range(1, 6)
      } else if (page + 2 <= last) {
        return _.range(page - 2, page + 3)
      } else {
        return _.range(last - 4, last + 1)
      }
    },

    toFirstPage() {
      return +this.info.current_page - 1
    },

    toLastPage() {
      return +this.info.last_page - +this.info.current_page
    }
  },

  methods: {
    changePage(page = 1) {
      this.$emit("changePage", +page)
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  gap: 5px;
  font-size: 12px;
  position: relative;
  padding: 0px 50px;
  &-item {
    color: $grey4;
    text-decoration: none;
    cursor: pointer;
    width: 21px;
    text-align: center;
    &--side {
      position: absolute;
      &-next-page {
        right: 25px;
      }
      &-last-page {
        right: 0px;
      }
      &-back-page {
        left: 0px;
      }
      &-first-page {
        left: 25px;
      }
    }
  }
  & .active {
    color: $grey1;
    text-decoration: underline;
  }
}
</style>
