<template>
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9841_10765)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2327 16.7664C15.5982 15.3238 14.4479 13.9316 12.9536 13.006L13.5855 11.9858C15.2935 13.0437 16.6025 14.6267 17.3311 16.2833C18.0542 17.9273 18.2395 19.7315 17.5476 21.183C17.3642 21.5693 16.9994 21.9214 16.5571 22.2257C16.1015 22.5392 15.509 22.8394 14.7958 23.1006C13.3677 23.6237 11.4157 24.005 9.01025 24.005V22.805C11.2931 22.805 13.1033 22.4426 14.3831 21.9738C15.0238 21.7392 15.5217 21.4816 15.8768 21.2372C16.2453 20.9837 16.4115 20.7779 16.4636 20.6683L16.464 20.6674C16.9542 19.6397 16.8725 18.2212 16.2327 16.7664Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.668457 16.2833C1.39704 14.6267 2.70611 13.0437 4.4141 11.9858L5.04597 13.006C3.55164 13.9316 2.4014 15.3238 1.76691 16.7664C1.12706 18.2212 1.04538 19.6397 1.53554 20.6674L1.53599 20.6683C1.58804 20.7779 1.75431 20.9837 2.12275 21.2372C2.47791 21.4816 2.97578 21.7392 3.61647 21.9738C4.89625 22.4426 6.70644 22.805 8.98934 22.805V24.005C6.58386 24.005 4.63185 23.6237 3.20378 23.1006C2.49055 22.8394 1.89807 22.5392 1.44252 22.2257C1.00037 21.9215 0.635618 21.5695 0.452155 21.1834C-0.239907 19.7318 -0.0546835 17.9275 0.668457 16.2833Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2134 3.09457C10.4651 2.42049 9.5676 2.2 9 2.2V1C9.82426 1 11.0198 1.30513 12.0165 2.20296C13.0325 3.11813 13.786 4.59834 13.786 6.83636C13.786 9.07439 13.0325 10.5546 12.0165 11.4698C11.0198 12.3676 9.82426 12.6727 9 12.6727V11.4727C9.5676 11.4727 10.4651 11.2522 11.2134 10.5782C11.9425 9.92144 12.586 8.78346 12.586 6.83636C12.586 4.88927 11.9425 3.75129 11.2134 3.09457Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.98435 2.20296C6.98108 1.30513 8.17663 1 9.00089 1V2.2C8.43329 2.2 7.53582 2.42049 6.78749 3.09457C6.05842 3.75129 5.41484 4.88927 5.41484 6.83636C5.41484 8.78346 6.05842 9.92144 6.78749 10.5782C7.53582 11.2522 8.43329 11.4727 9.00089 11.4727V12.6727C8.17663 12.6727 6.98108 12.3676 5.98435 11.4698C4.96836 10.5546 4.21484 9.07439 4.21484 6.83636C4.21484 4.59834 4.96836 3.11813 5.98435 2.20296Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_9841_10765">
        <rect width="18" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
