import api from "@/api"
import { mutationTypes } from "./constants"
const { list, current, create, update, listSearch } = api.brands

export default {
  async getBrands({ commit }) {
    const { data } = await list()

    commit(mutationTypes.SET_BRANDS, data)
  },

  async getBanner({ commit }, id) {
    const { data } = await current(id)

    return data
  },

  async createBanner({ commit }, payload) {
    const { data } = await create(payload)

    commit(mutationTypes.CREATE_BRAND, data)
  },

  async updateBanner({ commit }, payload) {
    const { data } = await update(payload)
  },

  async searchBrands({ commit }, payload) {
    const { data } = await listSearch(payload)

    return data
  },

  async availableDate({ commit, getters }, { parentID, bannerID }) {
    const items = getters.brands.find((el) => el.brandId === +parentID)
    const arr = bannerID
      ? items.banners.filter((el) => el.id !== +bannerID)
      : items?.banners || []
    let arrDate = []
    for (let item of arr) {
      arrDate = [
        ...arrDate,
        {
          start: new Date(item.dateStart).getTime(),
          end: new Date(item.dateEnd).getTime()
        }
      ]
    }

    return arrDate
  }
}
