import dayjs from "dayjs"

import { mutationTypes } from "./constants"

import api from "@/api"

const { shopsFull, editShop } = api.shops

import { TRADING_POINTS_TYPES } from "@/constants/tradingPointsStatus.js"

export default {
  async getShopFull({ commit }, { search = "", page = 1 }) {
    const { data } = await shopsFull(search, page)

    commit(mutationTypes.SET_SHOPS, data)
  },
  async editShopFullElement({ commit }, { id, shopEdited }) {
    const { date, status } = shopEdited
    const isStartData =
      status &&
      status !== TRADING_POINTS_TYPES.WORKING &&
      status !== TRADING_POINTS_TYPES.NEW
    const isEndDate = status && status === TRADING_POINTS_TYPES.UPDATE
    const newShop = {
      ...shopEdited,
      date: {
        start: isStartData ? dayjs(date.date_start).format("YYYY-MM-DD") : "",
        end: isEndDate ? dayjs(date.date_end).format("YYYY-MM-DD") : ""
      }
    }
    const { data } = await editShop(newShop)

    commit(mutationTypes.SET_EDITED_SHOP, { id, editedShop: data })
  }
}
