import { mutationTypes } from "./constants"

import api from "@/api"

const mutationData = (arr, form = "") =>
  arr.map((item) => ({
    id: item.id,
    label: form === "form" ? `${item.name} (${item?.counter || 0})` : item.name,
    checked: item.checked,
    validate: item.validate,
    textError: item?.textError || ""
  }))

export default {
  async getCategoriesFirstLevel({ commit }) {
    const { data } = await api.placards.categories()

    commit(mutationTypes.CATEGORIES, data)
  },

  async getCategoryChildren({ commit }, ID) {
    const { data } = await api.placards.categoryChildren(ID)

    return data || []
  },

  async getCategoryExtraBlock({ commit }, ID) {
    const { data } = await api.placards.categoryExtraBlock(ID)

    return data || []
  },

  async getPlacard({ commit }) {
    const { data } = await api.placards.placards()

    commit(mutationTypes.PLACARD, data)
    commit(mutationTypes.PLACARD_COPY, data)
  },

  async updatePlacards({ commit }, placard) {
    const { data } = await api.placards.updatePlacards(placard)

    commit(mutationTypes.PLACARD, data)
    commit(mutationTypes.PLACARD_COPY, data)
  },

  async getPlacardFilters({ commit }, ID) {
    const { data } = await api.placards.placardFilters(ID)

    commit(mutationTypes.PLACARD_FILTERS, mutationData(data.filters))
    commit(mutationTypes.PLACARD_FILTERS_COPY, mutationData(data.filters))
    commit(mutationTypes.PLACARD_FORMS, mutationData(data.forms, "form"))
    commit(mutationTypes.PLACARD_FORMS_COPY, mutationData(data.forms, "form"))
  },

  async updatePlacardFilters({ state, commit }, ID) {
    const { data } = await api.placards.updatePlacardFilters(ID, {
      filters: state.filters,
      forms: state.forms
    })

    commit(mutationTypes.PLACARD_FILTERS, mutationData(data.filters))
    commit(mutationTypes.PLACARD_FILTERS_COPY, mutationData(data.filters))
    commit(mutationTypes.PLACARD_FORMS, mutationData(data.forms, "form"))
    commit(mutationTypes.PLACARD_FORMS_COPY, mutationData(data.forms, "form"))
  },

  async getPlacardsProductsByFilters({ commit, state }, params) {
    const { data } = await api.placards.placardsProductsByFilter(params)

    if (params.offset === 0) {
      commit(mutationTypes.PLACARD_PRODUCTS, data)
    } else {
      commit(mutationTypes.PLACARD_PRODUCTS, {
        count: data.count,
        products: [...state.products.products, ...data.products]
      })
    }
  },

  async getProductValidate({ commit }, params) {
    const { data } = await api.placards.placardsProductsValidate(params)

    return data
  },

  async uploadImage({ commit }, image) {
    const { data } = await api.placards.uploadImage(image)

    return data
  }
}
