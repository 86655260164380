import { mutationTypes } from "./constants"

import api from "@/api"

const { getColors, updateImage, updateValidate, updateType } = api.colors

export default {
  async getColors({ commit }) {
    const { data } = await getColors()

    commit(mutationTypes.SET_COLORS, data)
  },

  async updateImage({ commit }, { groupId, payload }) {
    const { data } = await updateImage({
      id: payload.id || groupId,
      imgData: payload.imgData
    })

    commit(mutationTypes.CHANGE_ICON, {
      groupId,
      id: payload.id,
      icon: data?.image_url || null
    })
  },

  async updateType({ commit }, payload) {
    const { data } = await updateType(payload)

    commit(mutationTypes.CHANGE_TYPE, payload)
  },

  async updateValidate({ state, commit, dispatch }, payload) {
    await updateValidate({
      id: payload.itemId || payload.groupId,
      validate: payload.validate
    })
    commit(mutationTypes.CHANGE_VALIDATION, payload)
    // Якщо всі вложені елементи провалідовані, автоматично валідувати їх батьківський елемент
    const groupItem = state.groups.find((item) => item.id === payload.groupId)

    if (groupItem.items.every((el) => !!el.validate) && !groupItem.validate) {
      dispatch("updateValidate", { groupId: payload.groupId })
    }
  }
}
