import { mapActions, mapGetters } from "vuex"

import { getItemlocalStorage } from "@/helpers/storage.js"
import { ROUTE } from "@/router/routeNames"

export default {
  computed: {
    ...mapGetters({
      shops: `shops/shops`
    }),
    filterShops() {
      return this.isActiveShopFiltering
        ? this.shops.filter((shop) =>
            shop.address
              .toLowerCase()
              .includes(this.searchDropDown.toLowerCase())
          )
        : this.shops
    }
  },
  methods: {
    ...mapActions({
      getShops: `shops/getShops`
    }),
    listSearches() {
      return (getItemlocalStorage(this.prevSearchList) || []).slice(0, 5)
    },
    setActiveShop(shop, getProducts = true) {
      if (!shop) return

      this.isActiveShopFiltering = false
      this.selectedShop = shop
      this.searchDropDown = this.selectedShop.address
      const query = { page: this.page }

      query.activeShop = shop.id

      if (this.$route.query.search) query.search = this.$route.query.search

      this.$router.push({
        name: ROUTE[this.routeKey].name,
        query
      })

      if (getProducts) {
        this.getProducts()
      }
    },
    changeShop(shop) {
      this.page = 1
      this.setActiveShop(shop)
    },
    setSearch(searchPhrase) {
      this.searchDropDown = searchPhrase
      this.isActiveShopFiltering = true
    },
    changePage(page, getMethod = "getProducts") {
      this.page = page
      this.$router.push({
        name: ROUTE[this.routeKey].name,
        query: { ...this.$route.query, page }
      })
      this[getMethod]()
    },
    setSearchResult(phrase, getMethod = "getProducts") {
      this.page = 1
      this.$router.push({
        name: ROUTE[this.routeKey].name,
        query: { ...this.$route.query, page: 1, search: phrase }
      })
      this[getMethod]()
    }
  }
}
