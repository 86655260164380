<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.170609 0.0996215C0.366637 -0.0572005 0.652678 -0.0254182 0.8095 0.170609L4.30938 4.54546H4.54546V0.454561C4.54546 0.207349 4.74281 0.00623065 4.98856 0.00015798C4.99236 6.40001e-05 4.99617 1.67341e-05 5 1.67341e-05C5.25104 1.67341e-05 5.45454 0.203523 5.45454 0.454561V4.54546H5.69062L9.1905 0.170609C9.34732 -0.0254182 9.63336 -0.0572005 9.82939 0.0996215C10.0254 0.256443 10.0572 0.542484 9.90038 0.738512L6.49119 5L9.90038 9.26149C10.0572 9.45751 10.0254 9.74356 9.82939 9.90038C9.63336 10.0572 9.34732 10.0254 9.1905 9.82939L5.69062 5.45454H5.45454V9.54544C5.45454 9.79648 5.25104 9.99998 5 9.99998C4.74896 9.99998 4.54546 9.79648 4.54546 9.54544V5.45454H4.30938L0.8095 9.82939C0.652678 10.0254 0.366637 10.0572 0.170609 9.90038C-0.0254182 9.74356 -0.0572005 9.45751 0.0996215 9.26149L3.50881 5L0.0996215 0.738512C-0.0572005 0.542484 -0.0254182 0.256443 0.170609 0.0996215Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000"
    }
  }
}
</script>

<style></style>
