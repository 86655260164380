<script setup>
import { ref, computed } from "vue"
import PlusIcon from "./svg/PlusIcon.vue"
import { onHighlight } from "@/helpers/strings"
import ArrowUpIcon from "./svg/ArrowUpIcon.vue"
import { useVirtualList, onClickOutside } from "@vueuse/core"
import AppDropdownGroupItem from "./AppDropdownGroupItem.vue"
import InputImage from "@/components/InputBanner/components/InputImage.vue"

const emit = defineEmits([
  "changeValidGroup",
  "onRemoveGroupIcon",
  "changeValid",
  "onSelectIcon",
  "onOpen",
  "onSelectGroupIcon",
  "onRemoveIcon",
  "onDragDropItem"
])
const props = defineProps({
  group: {
    type: Object,
    required: true
  },

  isOpen: {
    type: Boolean,
    default: false
  },

  highlight: {
    type: String,
    default: ""
  },

  isItemsDraggable: {
    type: Boolean,
    default: false
  },

  noIcon: {
    type: Boolean,
    default: false
  },

  noGroupIcon: {
    type: Boolean,
    default: true
  },

  notValidate: {
    type: Boolean,
    default: false
  }
})

const groupRef = ref(null)
const unchangedNode = ref(null)
const modalKeepUnchanged = ref(false)
const isValid = computed(
  () => props.group && "validate" in props.group && !props.group?.validate
)

const {
  list: groupList,
  containerProps,
  wrapperProps
} = useVirtualList(props.group?.items, {
  itemHeight: 56
})

const onOpen = (ID) => emit("onOpen", ID)

const openDownloadWindow = (nameRef) => {
  groupRef.value.resetField()
  groupRef.value.openDirectory()
  //   this.$refs[nameRef].$refs.inputPicture.value = null
  //   this.$refs[nameRef].$refs.inputPicture.click()
}

const onSelectIconHandler = (image) => {
  const { name, imgData, url } = image
  emit("onSelectGroupIcon", { name, imgData, url })
}

const onRemoveGroupItemIcon = (event, nameRef) => {
  groupRef.value.resetField()
  emit("onRemoveIcon", event)
}

const closeModalKeepUnchanged = () => (modalKeepUnchanged.value = false)

const toggleModalKeepUnchanged = () =>
  !props.notValidate && (modalKeepUnchanged.value = !modalKeepUnchanged.value)
const onSelectIcon = (event) => emit("onSelectIcon", event)

onClickOutside(unchangedNode, () => closeModalKeepUnchanged())
</script>

<!-- <script>
// import AppDropdownGroupItem from "./AppDropdownGroupItem.vue"
// import ArrowUpIcon from "./svg/ArrowUpIcon.vue"
// import PlusIcon from "./svg/PlusIcon.vue"

// import InputImage from "@/components/InputBanner/components/InputImage.vue"
// import { onHighlight } from "@/helpers/strings"
// import { useVirtualList } from "@vueuse/core"

// export default {
//   props: {
//     group: {
//       type: Object,
//       required: true
//     },

//     isOpen: {
//       type: Boolean,
//       default: false
//     },

//     highlight: {
//       type: String,
//       default: ""
//     },

//     isItemsDraggable: {
//       type: Boolean,
//       default: false
//     },

//     noIcon: {
//       type: Boolean,
//       default: false
//     },

//     noGroupIcon: {
//       type: Boolean,
//       default: true
//     },

//     notValidate: {
//       type: Boolean,
//       default: false
//     }
//   },

//   emits: [
//     "changeValidGroup",
//     "onRemoveGroupIcon",
//     "changeValid",
//     "onSelectIcon",
//     "onOpen",
//     "onSelectGroupIcon",
//     "onRemoveIcon",
//     "onDragDropItem"
//   ],

//   components: {
//     AppDropdownGroupItem,
//     ArrowUpIcon,
//     PlusIcon,
//     InputImage
//   },

//   data() {
//     return {
//       modalKeepUnchanged: false
//     }
//   },

//   setup(props) {
//     console.log(props.group.items)
//     const { list: groupList } = useVirtualList(props.group.items, {
//       itemHeight: 56
//     })

//     return {
//       groupList
//     }
//   },

//   computed: {
//     isValid() {
//       // eslint-disable-next-line
//       return this.group.hasOwnProperty("validate") && !this.group.validate
//     }
//   },

//   methods: {
//     onHighlight,
//     onOpen(ID) {
//       this.$emit("onOpen", ID)
//     },

//     openDownloadWindow(nameRef) {
//       this.$refs[nameRef].$refs.inputPicture.value = null
//       this.$refs[nameRef].$refs.inputPicture.click()
//     },

//     onSelectIconHandler(image) {
//       const icon = {
//         name: image.name,
//         imgData: image.imgData,
//         url: image.url
//       }

//       this.$emit("onSelectGroupIcon", icon)
//     },

//     onRemoveGroupItemIcon(event, nameRef) {
//       this.$refs[nameRef].$refs.inputPicture.value = null
//       this.$emit("onRemoveIcon", event)
//     },

//     closeModalKeepUnchanged() {
//       this.modalKeepUnchanged = false
//     },

//     toggleModalKeepUnchanged() {
//       if (this.notValidate) return

//       this.modalKeepUnchanged = !this.modalKeepUnchanged
//     }
//   }
// }
// </script> -->

<template>
  <div>
    <div
      class="dropdown-group"
      :class="{
        'opened-group': isOpen,
        'no-valide': isValid
      }"
    >
      <div class="dropdown-group-row">
        <div class="label" @click="onOpen(group.id)">
          <div
            ref="unchangedNode"
            class="item-name-modal"
            v-if="modalKeepUnchanged && isValid"
          >
            <v-checkbox
              label="зберегти без змін"
              :value="!!group.validate"
              @change="$emit('changeValid', { validate: $event })"
            />
          </div>
          <ArrowUpIcon class="label--icon" :class="{ 'icon-down': isOpen }" />
          <span
            @click.stop="toggleModalKeepUnchanged"
            class="label--text"
            v-html="onHighlight(group.name, highlight)"
          ></span>
        </div>
        <div class="dropdown-group--setting">
          <span v-show="noGroupIcon" class="dropdown-group__icons">
            <input-image
              v-show="group.icon?.url"
              ref="groupRef"
              :url="group.icon?.url"
              class="icon"
              @createImage="onSelectIconHandler"
              @click="openDownloadWindow"
            />
            <!-- <input-image
              v-show="group.icon?.url"
              :ref="`group_${group.id}`"
              :url="group.icon?.url"
              class="icon"
              @createImage="onSelectIconHandler"
              @click="openDownloadWindow(`group_${group.id}`)"
            /> -->
            <PlusIcon
              v-if="!group.icon?.url && !noIcon && noGroupIcon"
              @click="openDownloadWindow"
              class="plus-icon icon"
              color="#000"
            />
            <span
              v-if="group.icon?.url && noGroupIcon"
              class="delete"
              @click="$emit('onRemoveGroupIcon', group.id)"
              >delete</span
            >
          </span>

          <slot name="groupActions"></slot>
        </div>
      </div>
    </div>
    <div
      v-bind="containerProps"
      v-if="isOpen"
      :style="{
        height: groupList.length > 6 ? '336px' : `${groupList.length * 56}px`
      }"
    >
      <div v-bind="wrapperProps" class="dropdown-items">
        <AppDropdownGroupItem
          v-for="{ index, data } in groupList"
          :key="index"
          class="dropdown-items--item"
          :item="data"
          :noIcon="noIcon"
          :highlight="highlight"
          :isItemDraggable="isItemsDraggable"
          @changeValid="
            $emit('changeValid', { validate: $event, itemId: data.id })
          "
          @onSelectIcon="onSelectIcon"
          @onRemoveIcon="onRemoveGroupItemIcon($event, `group_${group.id}`)"
          @onDragDropItem="$emit('onDragDropItem', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-group {
  display: flex;
  height: 56px;
  width: 100%;
  padding: 0 17px;
  border-bottom: 1px solid $grey6;
  background-color: $white;

  &.no-valide {
    background-color: $pink;
    color: $light-red;

    :deep .arrow-up path {
      fill: $light-red;
    }
  }

  &--setting {
    display: flex;
    gap: 85px;
  }

  &__icons {
    display: flex;
  }

  &-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      .plus-icon {
        visibility: visible;
      }

      .label--text {
        text-decoration: underline;
      }

      .delete {
        visibility: visible;
      }
    }
  }
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  max-width: calc(486px - 16px);
  width: 100%;
  height: 100%;
  cursor: pointer;

  &--icon {
    margin-right: 17px;
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  &--text {
    font-size: 14px;
    line-height: 16px;
  }
}

.icon-down {
  transform: rotate(0deg);
}

.icon {
  min-width: 16px;
  width: 16px;
  height: 16px;
}

.opened-group {
  background-color: $green2;
}

.plus-icon {
  cursor: pointer;
  visibility: hidden;
}

.dropdown-items {
  &--item {
    margin-top: 1px;
  }
}

.delete {
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
  visibility: hidden;
}

.item-name-modal {
  position: absolute;
  bottom: 70%;
  left: -17px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 4px;
}
</style>
