import { mutationTypes } from "./constants"

import api from "@/api"

export default {
  async getPromotions({ commit }) {
    const { data } = await api.promotions.promotions()

    commit(mutationTypes.SET_PROMOTIONS, data)
  },

  async getPromotionsAll({ commit }, params) {
    const { data } = await api.promotions.promotionsAll(params)

    if (data.data) {
      commit(mutationTypes.SET_ALL_PROMOTIONS, data)
    }
  },

  async getPromotion({ commit }, ID) {
    try {
      const { data } = await api.promotions.getPromotion(ID)

      return data
    } catch (error) {
      return null
    }
  },

  async updatePromotion({ commit }, { id, params }) {
    try {
      const { data } = await api.promotions.updatePromotion({ id, params })

      return data
    } catch (error) {
      return null
    }
  }
}
