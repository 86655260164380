<template>
  <div class="container">
    <AppInput class="search-input" v-model="searchTerm" placeholder="№">
      <template #icon>
        <icon-search class="icon" />
      </template>
    </AppInput>
    <div class="promotions">
      <div
        v-for="action in filteredPromotions"
        :key="`action_${action.id}`"
        @click="onSelectPromotionHandler(action)"
        class="promotions-item"
      >
        <span class="promotion-text promotions-item--number">
          {{ action.code }}
        </span>
        <span class="promotion-text promotions-item--date">
          {{ action.date }}
        </span>
        <span class="promotion-text promotions-item--description">
          {{ action.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/AppInput.vue"
import IconSearch from "@/components/svg/SearchIcon.vue"

export default {
  emits: ["onSelectPromotion"],
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  components: {
    AppInput,
    IconSearch
  },

  data: () => ({
    searchTerm: ""
  }),

  computed: {
    filteredPromotions() {
      return this.data.filter((promotion) =>
        promotion.name.toUpperCase().includes(this.searchTerm.toUpperCase())
      )
    }
  },

  methods: {
    onSelectPromotionHandler(promotion) {
      this.$emit("onSelectPromotion", promotion)
    }
  },

  unmounted() {
    this.searchTerm = ""
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: fit-content;
  max-height: 298px;
}
.search-input {
  margin-bottom: 18px;
}
.icon {
  margin-right: 20px;
}
.promotions-item {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  &--date {
    width: 84px;
  }

  // &--description {
  //   flex: 1;
  // }
}

.promotion-text {
  font-size: 14px;
  line-height: 16px;
  margin-right: 16px;
}
</style>
