<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.94417 7.0871H15.0737C15.5853 7.0871 16 7.50183 16 8.01342C16 8.52502 15.5853 8.93975 15.0737 8.93975H8.94417V15.042C8.94417 15.5711 8.51528 16 7.98622 16C7.45715 16 7.02826 15.5711 7.02826 15.042V8.93975H0.926326C0.414731 8.93975 0 8.52502 0 8.01342C0 7.50183 0.41473 7.0871 0.926326 7.0871H7.02826V0.957957C7.02826 0.428892 7.45715 0 7.98622 0C8.51528 0 8.94417 0.428892 8.94417 0.957956V7.0871Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#fff"
    }
  }
}
</script>
