<template>
  <div class="triangle-container">
    <svg viewbox="0 0 15 15" class="size outer">
      <path d="M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z" :fill="borderColor" />
    </svg>
    <svg viewbox="0 0 15 15" class="size inner">
      <path d="M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z" :fill="innerColor" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    borderColor: {
      type: String,
      default: "#000"
    },

    innerColor: {
      type: String,
      default: "#fff"
    }
  }
}
</script>

<style lang="scss" scoped>
.triangle-container {
  position: relative;
}

.size {
  position: absolute;
  height: 10px;
  width: 5px;
  transform: rotate(90deg);
}

.outer {
  position: absolute;
  top: 0;
  left: 0;
}

.inner {
  top: 1px;
  left: 0px;
}
</style>
