import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "brands"

const state = () => ({
  brands: []
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
