<template>
  <textarea
    class="input"
    :class="[{ disabled: disabled }]"
    type="text"
    :disabled="disabled"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :placeholder="placeholder"
  ></textarea>
</template>

<script>
export default {
  props: ["modelValue", "placeholder", "disabled"],
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
}

.input {
  outline: 0;
  border: 1px solid $grey5;
  border-radius: 8px;
  padding: 16px;
  width: 248px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  background-color: $white;
}

input::placeholder {
  color: $grey;
}
</style>
