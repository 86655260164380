import dayjs from "dayjs"

export const formatDate = (date, format = "DD.MM.YYYY") => {
  return dayjs(date).format(format)
}

export const formatDateLocal = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  return dayjs(date).format(format)
}

export const getDatesBetween = (start, end, format = "DD.MM") => {
  const dates = []
  const startDate = new Date(start).setHours(0, 0, 0, 0)
  const endDate = new Date(end).setHours(0, 0, 0, 0)
  const currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push({
      formatedDate: formatDate(currentDate, format),
      rawDate: new Date(currentDate).setHours(0, 0, 0, 0)
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const checkIfDateIsBetween = (date, start, end) => {
  const startDate = new Date(start).setHours(0, 0, 0, 0)
  const endDate = new Date(end).setHours(0, 0, 0, 0)
  const currentDate = new Date(date).setHours(0, 0, 0, 0)

  return startDate <= currentDate && currentDate <= endDate
}

export const validateDateTime = (date) => {
  return dayjs(Date.now().valueOf()) >= dayjs(date.valueOf())
}

export const isDateRangeValid = ({ data, range }) => {
  const datesInRange = getDatesBetween(data.start, data.end)
  const rangeStart = new Date(range.start).setHours(0, 0, 0, 0)
  const rangeEnd = new Date(range.end).setHours(0, 0, 0, 0)

  const datesInRangeFiltered = datesInRange.some(
    (item) => item.rawDate >= rangeStart && item.rawDate <= rangeEnd
  )

  return datesInRangeFiltered
}
