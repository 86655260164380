<template>
  <div class="auth-container">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  height: 100%;
}
</style>
