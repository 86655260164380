import { mutationTypes } from "./constants"

import api from "@/api"

export default {
  async getComponents({ commit }) {
    const { data } = await api.activeComponents.components()

    commit(mutationTypes.SET_COMPONENTS_GROUPS, data)
  },
  async uploadImage({ commit }, { payload, dataImage }) {
    const { data } = await api.activeComponents.uploadImage(payload)

    commit(
      mutationTypes[
        dataImage?.itemId ? "ADD_ICON_TO_GROUP_ITEM" : "ADD_ICON_TO_GROUP"
      ],
      {
        ...dataImage,
        icon: {
          file: null,
          url: data.url
        }
      }
    )
  },
  async deleteImage({ commit }, { payload, dataImage }) {
    await api.activeComponents.deleteImage(payload)
    commit(
      mutationTypes[
        dataImage?.itemId
          ? "REMOVE_ICON_FROM_GROUP_ITEM"
          : "REMOVE_ICON_FROM_GROUP"
      ],
      dataImage
    )
  }
}
