<template>
  <div class="promotion-chip" :style="{ 'background-color': backgroundColor }">
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    backgroundColor: {
      type: String,
      default: "#000"
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  border-radius: 7.5px;
  width: fit-content;
  height: fit-content;
}
.label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: #fff;
}
</style>
