<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FC536A" />
    <path
      d="M9.19215 11.0028C9.23752 11.4723 9.31327 11.8218 9.41921 12.0513C9.52496 12.2806 9.71452 12.3957 9.98734 12.3957C10.0385 12.3957 10.0845 12.3873 10.13 12.3786C10.176 12.3873 10.2213 12.3957 10.2723 12.3957C10.5448 12.3957 10.734 12.2806 10.8405 12.0513C10.946 11.8218 11.022 11.4723 11.0671 11.0028L11.3094 7.37839C11.3548 6.67189 11.3775 6.1647 11.3775 5.8572C11.3775 5.43833 11.2678 5.11152 11.0488 4.87733C10.8288 4.64295 10.5399 4.52539 10.1818 4.52539C10.163 4.52539 10.1486 4.5297 10.1304 4.53045C10.1122 4.5297 10.0976 4.52539 10.079 4.52539C9.72052 4.52539 9.43196 4.64277 9.21221 4.87733C8.99284 5.1117 8.88315 5.43852 8.88315 5.85739C8.88315 6.16508 8.90602 6.67208 8.95121 7.37858L9.19327 11.0028H9.19215ZM10.1373 13.7193C9.79065 13.7193 9.49459 13.8293 9.24934 14.0481C9.00446 14.2683 8.88184 14.5341 8.88184 14.8465C8.88184 15.2005 9.00577 15.4775 9.25327 15.6788C9.50152 15.8806 9.79065 15.9822 10.1205 15.9822C10.4568 15.9822 10.7499 15.8821 11.0006 15.6831C11.2513 15.4838 11.3756 15.205 11.3756 14.8463C11.3756 14.534 11.2556 14.2681 11.0161 14.048C10.7769 13.8293 10.4838 13.7193 10.1366 13.7193"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
