import api from "@/api"
import { mutationTypes } from "./constants"

export default {
  async getBanners({ commit }) {
    const { data } = await api.banners.banners()

    commit(mutationTypes.SET_BANNERS, data)
  },

  async getCurrentBanner({ commit }, id) {
    const { data } = await api.banners.currentBanner(id)

    commit(mutationTypes.SET_BANNER, data)
    return data
  },

  async createBanner({ commit }, payload) {
    const { data } = await api.banners.createBanner(payload)

    commit(mutationTypes.ADD_BANNER, data)
  },

  async updateBanner({ commit }, payload) {
    const { data } = await api.banners.updateBanner(payload)

    commit(mutationTypes.ADD_BANNER, data)
  },

  async availableDate({ commit, getters }, { parentID, bannerID }) {
    const items = getters.allBanners.find((el) => el.id === +parentID)
    const arr = bannerID
      ? items.banners.filter((el) => el.id !== bannerID)
      : items.banners
    let arrDate = []

    for (let item of arr) {
      arrDate = [
        ...arrDate,
        {
          start: new Date(item.dateStart).getTime(),
          end: new Date(item.dateEnd).getTime()
        }
      ]
    }

    return arrDate
  }
}
