const CLASS_NAME_DRAGGING = "dragging"

export default {
  methods: {
    startDrag(event, item, isDraggable = false) {
      if (!isDraggable) return

      event.target.classList.add(CLASS_NAME_DRAGGING)
      event.dataTransfer.dropEffect = "move"
      event.dataTransfer.effectAllowed = "move"
      event.dataTransfer.setData("itemID", item.id)
    },
    endDrag(event, item, isDraggable = false, emitName) {
      if (!isDraggable) return

      event.target.classList.remove(CLASS_NAME_DRAGGING)
      const newItem = event.dataTransfer.getData("itemID")

      this.$emit(emitName, { old: item.id, new: newItem })
    }
  }
}
