import { sendSecureRequest, sendRequest } from "../config"

import { isAddSearch } from "./../../helpers/urlModificator"

const ENDPOINT = "admin/documents"

export default {
  documents(page = 1, searchPhrase = "") {
    return sendSecureRequest().get(`${ENDPOINT}${(searchPhrase, page)}`)
  },
  documentsShop({ id, page = 1, searchPhrase = "" }) {
    return sendSecureRequest().get(
      `${ENDPOINT}/${id}/${isAddSearch(searchPhrase, page)}`
    )
  },
  getFile(url, params) {
    return sendRequest().get(url, params)
  }
}
