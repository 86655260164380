<template>
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.61582 0.147687L0.158317 6.14267C-0.0531317 6.33908 -0.0527762 6.65709 0.159411 6.85318C0.37157 7.04912 0.715252 7.04861 0.926865 6.85217L7.00003 1.21403L13.0732 6.85237C13.2848 7.04879 13.6283 7.04929 13.8405 6.85338C13.9468 6.75508 14 6.62631 14 6.49753C14 6.36908 13.9472 6.24081 13.8415 6.14269L7.3842 0.147687C7.28254 0.053085 7.14418 -3.12282e-07 7.00003 -3.05981e-07C6.85587 -2.9968e-07 6.71768 0.0532371 6.61582 0.147687Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
