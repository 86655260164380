import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "promotions"

const state = () => ({
  promotions: [], //for banners
  promotionsAll: []
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
