import { mutationTypes } from "./constants"

import api from "@/api"

const { orders, ordersID, updateNp, cancel } = api.orders

export default {
  async getOrders({ commit }, params) {
    const response = await orders(params)
    if (response) {
      const { data } = response
      commit(mutationTypes.SET_ORDERS, data)
    }
  },

  async getOrdersId({ commit }, ID) {
    const response = await ordersID(ID)
    return response.data?.data
  },

  async updateNp({ commit }, ID) {
    const response = await updateNp(ID)

    if (response) {
      return response.data?.data
    }
  },

  async cancelOrder({ commit }, ID) {
    const response = await cancel(ID)

    if (response) {
      return response.data?.data
    }
  }
}
