export const mutationTypes = {
  ADD_SECTION: "ADD_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  SELECT_SECTION: "SELECT_SECTION",
  CHANGE_SECTION_IDX: "CHANGE_SECTION_IDX",
  ADD_BLOCK: "ADD_BLOCK",
  DELETE_BLOCK: "DELETE_BLOCK",
  CHANGE_BLOCK_IDX: "CHANGE_BLOCK_IDX",
  ADD_ITEM_TO_BLOCK: "ADD_ITEM_TO_BLOCK",
  SELECT_ITEM: "SELECT_ITEM",
  DELETE_ITEM: "DELETE_ITEM",
  TOGGLE_TEXT_ITEM_BOLD: "TOGGLE_TEXT_ITEM_BOLD",
  SET_ITEM_LINK: "SET_ITEM_LINK",
  SET_BANNER_ITEM_IMAGE: "SET_BANNER_ITEM_IMAGE",
  CATEGORIES: "CATEGORIES",
  PLACARD: "PLACARD",
  PLACARD_COPY: "PLACARD_COPY",
  PLACARD_FILTERS: "PLACARD_FILTERS",
  PLACARD_FORMS: "PLACARD_FORMS",
  PLACARD_FILTERS_COPY: "PLACARD_FILTERS_COPY",
  PLACARD_FORMS_COPY: "PLACARD_FORMS_COPY",
  PLACARD_PRODUCTS: "PLACARD_PRODUCTS",
  CHECKED_ITEM_FORM: "CHECKED_ITEM_FORM",
  CHECKED_ITEM_FILTER: "CHECKED_ITEM_FILTER",
  MOVE_ITEM_FORM: "MOVE_ITEM_FORM",
  MOVE_ITEM_FILTER: "MOVE_ITEM_FILTER",
  CHANGE_ITEM_FORM_VALIDATE: "CHANGE_ITEM_FORM_VALIDATE",
  CHANGE_ITEM_FILTER_VALIDATE: "CHANGE_ITEM_FILTER_VALIDATE",
  CHANGE_ITEMS_FORM_VALIDATE: "CHANGE_ITEMS_FORM_VALIDATE",
  CHANGE_ITEMS_FILTER_VALIDATE: "CHANGE_ITEMS_FILTER_VALIDATE",
  DRAG_CHILD_ITEM: "DRAG_CHILD_ITEM"
}

export const actionTypes = {}
