<template>
  <div class="download-picture">
    <div
      class="download-picture--container"
      :style="styles"
      @click="openDirectory"
    >
      <input-image
        ref="download-picture"
        :url="banner?.url"
        @createImage="addBanner"
      />
      <text-placeholder
        v-show="!banner?.url"
        :text="params?.text"
        :text-size="params?.placeholder"
      />
    </div>
    <delete-banner-image
      v-visible="banner?.url"
      :label="banner?.name || `image`"
      :style="{
        maxWidth: styles.width
      }"
      @click="removeBanner"
    />
  </div>
</template>

<script>
import DeleteBannerImage from "@/components/DeleteBannerImage.vue"
import InputImage from "@/components/InputBanner/components/InputImage.vue"
import TextPlaceholder from "@/components/InputBanner/components/TextPlaceholder.vue"

export default {
  name: "download-picture",
  components: {
    InputImage,
    TextPlaceholder,
    DeleteBannerImage
  },

  emits: ["addBanner", "removeBanner"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    params: {
      type: Object,
      default: () => ({
        placeholder: "",
        text: "",
        width: "0px",
        height: "0px"
      })
    },

    banner: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    styles() {
      const { height, width } = this.params

      return {
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
        height: `${height}px`,
        width: `${width}px`
      }
    },

    refsElementFieldImage() {
      return this.$refs["download-picture"]
    }
  },

  methods: {
    openDirectory() {
      if (!this.disabled) {
        this.refsElementFieldImage.openDirectory()
      }
    },

    addBanner(picture) {
      if (!this.disabled) {
        this.$emit("addBanner", picture)
      }
    },

    removeBanner() {
      if (!this.disabled) {
        this.$emit("removeBanner")
        this.refsElementFieldImage.resetField()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.download-picture {
  display: flex;
  flex-direction: column;

  &--container {
    height: 100%;
    position: relative;
    background-color: $grey6;
  }

  .input-image,
  :deep .input-image--form {
    height: inherit;
  }
}
</style>
