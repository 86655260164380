<template>
  <div class="app-items-table">
    <table class="app-items-table-container">
      <tr v-for="item in modifiedData" :key="item.id" class="item-row">
        <td
          class="row-item-cell"
          :style="{ color: !item?.validate ? '#FC536A' : '' }"
          @mouseenter="setPosition"
          @mouseleave="showModal = false"
        >
          <span>{{ item.nameRow }}</span>
        </td>
        <td
          class="row-item-cell"
          @mouseenter="setPosition"
          @mouseleave="showModal = false"
        >
          <span>{{ item.filtersName }}</span>
        </td>
        <td
          class="row-item-cell"
          @mouseenter="setPosition"
          @mouseleave="showModal = false"
        >
          <span>{{ item.filters }}</span>
        </td>
      </tr>
    </table>
    <v-btn
      v-if="!showMoreBtn"
      class="show-more-btn"
      label="Показати ще"
      @click="$emit('onShowMore')"
    />
    <AppPopup
      ref="popupShowAllText"
      class="checkboxes-form-item-popup"
      :style="styles"
      :text="textModal"
    />
  </div>
</template>

<script>
import AppPopup from "@/components/AppPopup.vue"

export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    },

    showMoreBtn: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppPopup
  },

  data: () => ({
    position: { x: 0, y: 0 },
    textModal: "",
    showModal: false
  }),

  methods: {
    setPosition(event) {
      const paddingCell = 33
      const { x, y, width } = event.target.getBoundingClientRect()
      const { width: widthText } =
        event.target.children[0].getBoundingClientRect()

      this.showModal =
        !!event.target.innerText && widthText >= width - paddingCell
      const { height } = this.$refs.popupShowAllText.$el.getBoundingClientRect()

      this.textModal = event.target.innerText
      this.position = { x: x, y: y - height }
    }
  },

  computed: {
    modifiedData() {
      return this.list
        .map((item) => {
          const nameRow = `${item.code} ${item.name}`

          return {
            ...item,
            nameRow
          }
        })
        .sort((a, b) => a.validate - b.validate)
    },

    styles() {
      return {
        position: "absolute!important",
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
        visibility: this.showModal ? "visible" : "hidden"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-items-table {
  max-height: 295px;
  overflow-y: auto;
  overflow-x: hidden;
  .show-more-btn {
    background: $grey;
    border-radius: 4px;
    padding: 4px;
    width: 100%;
  }
}
.app-items-table-container {
  width: 940px;
  border: 1px solid $grey6;
  border-collapse: collapse;
  table-layout: fixed;
}
.item-row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $grey6;
  }
}

.row-item-cell {
  padding: 6px 16px;
  border-right: 1px solid $grey6;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(1) {
    min-width: 37%;
  }

  &:nth-child(2) {
    min-width: 15%;
  }
  &:nth-child(3) {
    width: 100%;
  }
}
</style>
