<template>
  <button
    :class="[
      'v-button',
      `v-button--${size ? size : 'md'}`,
      `v-button--${type ? type : 'default'}`
    ]"
    :disabled="disabled"
    @click="onPressHandler"
  >
    <span class="v-button--text">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "v-btn",
  props: {
    label: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: ""
    }
  },

  emits: ["click"],

  methods: {
    onPressHandler() {
      if (this.disabled) return

      this.$emit("click")
    }
  }
}
</script>

<style lang="scss" scoped>
.v-button {
  width: fit-content;
  background-color: $black;
  padding: 12px 30px;
  border-radius: 33px;
  border: none;
  outline: none;

  &:disabled {
    background-color: $grey5;
  }

  &:hover {
    &:not(:disabled) {
      background-color: rgba($color: $black, $alpha: 0.7);
    }
  }
  &--text {
    color: $white;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &--sm {
    padding: 5px 20px;

    .v-button--text {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &--danger {
    background-color: #fc536a !important;

    .v-button--text {
      color: #000;
    }
  }
}
</style>
