import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/effects"

export default {
  getEffects() {
    return sendSecureRequest().get(ENDPOINT)
  },
  updateValidate(ID) {
    return sendSecureRequest().post(`${ENDPOINT}/${ID}`)
  }
}
