<template>
  <div
    class="order-status"
    v-if="getStatus[status]"
    :style="getStatus[status].style"
  >
    <img :src="getStatus[status].icon" :alt="getStatus[status].title" />
    <span>{{ getStatus[status].title }}</span>
  </div>
</template>

<script>
import { ORDER_STATUSES, INNER_STATUS_CODES } from "../constants"
import iconDelivery from "@/pages/Orders/assets/icons/delivery.svg"

export default {
  name: "orderStatus",
  props: {
    status: {
      type: String,
      default: ORDER_STATUSES["PROCESSING"],
      required: true,
      validator(value) {
        return Object.keys(ORDER_STATUSES).includes(value)
      }
    },

    directTitle: {
      type: String,
      default: ""
    }
  },

  computed: {
    getStatus() {
      return {
        ...INNER_STATUS_CODES,
        NP: {
          title: this.directTitle,
          icon: iconDelivery,
          style: { background: "#f23c32" },
          cancel: false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-status {
  border-radius: 26.5px;
  display: inline-flex;
  padding: 6px 16px;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  path {
    fill: #fff;
  }
}
</style>
