import BannerPosition from "@/models/banners/BannerPosition"

export const PARAMS_BANNER = {
  imageDesktopBackground: new BannerPosition(
    "1920 * 3000 px",
    268,
    354,
    `+ завантажити </br> для ПК`
  ),
  desktop: new BannerPosition(
    "1329 * 316 px",
    268,
    64,
    `+ завантажити </br> для ПК`
  ),
  mobile: new BannerPosition(
    "600 * 500 px",
    144,
    64,
    "+ завантажити </br> для mob"
  )
}
