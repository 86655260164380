import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_ORDERS](state, data) {
    state.orders = data
  },
  [mutationTypes.CHANGE_DETAILS_STATUS](state, { key, status }) {
    state.orders.data.find((item) => item.id === key).showDetails = status
  }
}
