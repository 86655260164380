<template>
  <div v-if="!loading && promotion" class="page-wrapper">
    <div class="status-row">
      <AppNavigationBackTitle
        navLinkLabel="Акції"
        :additionalLabel="promotion.code"
        :status="promotion.status"
      />
      <span
        v-if="!promotion.changed && promotion.history.length"
        class="changes-confirmed"
        >підтверджено зміни
      </span>
      <div v-if="promotion.changed" class="confirm-changes">
        <span class="edited-status">змінено</span>
        <ArrowUpIcon
          class="arrow-down"
          @click="showConfirmChanges = !showConfirmChanges"
        />
        <div
          v-if="showConfirmChanges"
          @click="onConfirmChanges"
          v-click-outside="() => (showConfirmChanges = false)"
          class="confirm-changes-dropdown"
        >
          <span class="confirm-changes-dropdown--text">підтверджено зміни</span>
        </div>
      </div>
    </div>
    <div class="info">
      <span class="action-number mr-16">{{ promotion.code }}</span>
      <PromotionChip
        v-if="promotion.label"
        class="mr-16"
        :background-color="promotion.label.color"
        :label="promotion.label.text"
      />
      <div class="time-info">
        <span class="text mr-8">з</span>
        <span class="text mr-8">{{ cutText(promotion.dateStart) }}</span>
        <span class="text mr-8">по</span>
        <span class="text">{{ cutText(promotion.dateEnd) }}</span>
      </div>
    </div>
    <div class="input-row align-items-center">
      <label class="input-label" for="promotion-page">Сторінка Акції </label>
      <v-input
        id="promotion-page"
        :value="promotion.link"
        :disabled="true"
        class="v-input--circle page-input"
      />
      <copy-label
        class="copy"
        :disabled="copySuccessful"
        :textToCopy="promotion.link || ''"
        @on-copy="changeCopySuccessful($event)"
      />
    </div>
    <div class="input-row">
      <label for="promotion-name" class="input-label">Назва</label>
      <v-input
        id="promotion-name"
        :disabled="!isChange"
        class="promotion-name"
        :class="[
          { valid: promotion.changed },
          { 'coupon-qr': checkOnCouponOrQr }
        ]"
        v-model="promotion.name"
      />
    </div>
    <div class="input-row">
      <label for="promotion-desc" class="input-label">Опис</label>
      <AppTextArea
        id="promotion-desc"
        :disabled="!isChange"
        class="promotion-description"
        :class="[
          { valid: promotion.changed },
          { 'coupon-qr': checkOnCouponOrQr }
        ]"
        v-model="promotion.description"
      />
    </div>
    <template v-if="checkOnCouponOrQr">
      <div class="input-row promocode">
        <label for="promotion-promo" class="input-label">Промокод</label>
        <v-input
          id="promotion-promo"
          :disabled="!isChange"
          class="promotion-promo"
          :class="[{ valid: promotion.changed }]"
          v-model="promotion.promocode"
        />
      </div>
      <div class="banner-block">
        <download-picture
          :params="PARAMS_BANNER['coupon']"
          :banner="promotion.imageCoupon"
          :disabled="!isChange"
          class="bdr-8"
          @addBanner="addBanner({ ...$event, type: 'imageCoupon' })"
          @removeBanner="removeBanner({ platform: 'imageCoupon' })"
        />
      </div>
    </template>
    <div v-else class="banner-block">
      <download-picture
        :params="PARAMS_BANNER['desktop']"
        :banner="promotion.imageDesktop"
        :disabled="!isChange"
        class=""
        @addBanner="addBanner({ ...$event, type: 'imageDesktop' })"
        @removeBanner="removeBanner({ platform: 'imageDesktop' })"
      />
      <download-picture
        :params="PARAMS_BANNER['mobile']"
        :banner="promotion.imageMobile"
        :disabled="!isChange"
        class=""
        @addBanner="addBanner({ ...$event, type: 'imageMobile' })"
        @removeBanner="removeBanner({ platform: 'imageMobile' })"
      />
    </div>

    <div v-if="promotion.history.length" class="history-section">
      <div class="drop-down" @click="showHistory = !showHistory">
        <span class="drop-down--label">Історія змін</span>
        <ArrowUpIcon
          class="arrow-up"
          :class="[showHistory && 'arrow-up-active']"
        />
      </div>
      <ChangesHistory
        v-if="showHistory"
        :history="promotion.history"
        class="history-table"
      />
    </div>
    <div class="buttons-row">
      <v-btn
        :label="isChange ? 'ЗБЕРЕГТИ' : 'ЗМІНИТИ'"
        :disabled="isChange ? !allowRequest || preload : false"
        @click="isChange ? onEditPromotion() : onChange(true)"
      />
      <v-btn label="ЗАКРИТИ" @click="$router.back" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import AppNavigationBackTitle from "@/components/AppNavigationBackTitle.vue"
import AppTextArea from "@/components/AppTextArea.vue"
import ChangesHistory from "@/components/ChangesHistory.vue"
import CopyLabel from "@/components/CopyLabel.vue"
import PromotionChip from "@/components/PromotionChip.vue"
import ArrowUpIcon from "@/components/svg/ArrowUpIcon.vue"
import { FORMAT } from "@/constants/banners"
import { PARAMS_BANNERS } from "@/constants/promotion"
import { PROMOTION_TYPES } from "@/constants/promotions"
import { formatDateLocal } from "@/helpers/date"
import downloadPicture from "@/pages/CreateBrand/downloadPicture.vue"
import { namespace } from "@/store/modules/promotions"

const NAME_BANNER_TYPES = ["imageDesktop", "imageMobile", "imageCoupon"]

export default {
  components: {
    AppNavigationBackTitle,
    AppTextArea,
    ArrowUpIcon,
    CopyLabel,
    ChangesHistory,
    PromotionChip,
    downloadPicture
  },

  setup() {
    const BANNERS_FORMAT = FORMAT
    const PARAMS_BANNER = PARAMS_BANNERS
    const PROMOTION_TYPE = PROMOTION_TYPES

    return {
      BANNERS_FORMAT,
      PARAMS_BANNER,
      PROMOTION_TYPE
    }
  },

  async mounted() {
    const promotionId = this.$route.params.promotionId
    const promotion = await this.getPromotion(promotionId)

    if (promotion) {
      this.promotion = this.mutationsBannerTypes(promotion)
    }

    this.copyData()
    this.loading = false
  },

  data: () => ({
    promotion: null,
    copyPromotion: null,
    loading: true,
    showHistory: false,
    showConfirmChanges: false,
    isChange: false,
    copySuccessful: false,
    preload: false
  }),

  computed: {
    checkOnCouponOrQr() {
      const validTypes = [
        this.PROMOTION_TYPE.CUPON,
        this.PROMOTION_TYPE.QR,
        this.PROMOTION_TYPE.PROMO
      ]

      return validTypes.includes(this.promotion.type)
    },

    allowRequest() {
      return this.checkOnCouponOrQr
        ? this.promotion.imageCoupon?.url || this.promotion.promocode
        : this.promotion.imageDesktop?.url && this.promotion.imageMobile?.url
    }
  },

  methods: {
    ...mapActions(namespace, ["getPromotion", "updatePromotion"]),

    cutText(text) {
      return formatDateLocal(text, "DD.MM")
    },

    changeCopySuccessful(bool) {
      this.copySuccessful = bool
    },

    copyData() {
      this.copyPromotion = JSON.parse(JSON.stringify(this.promotion))
    },

    async onEditPromotion() {
      await this.onUpdatePromotion()
      this.onChange(false)
    },

    onChange(bool) {
      this.isChange = bool
    },

    async onConfirmChanges() {
      this.showConfirmChanges = false
      this.promotion.changed = true
      await this.onUpdatePromotion()
    },

    async onUpdatePromotion() {
      this.preload = true
      const promotionId = this.$route.params.promotionId
      const params = this.compareObjects(this.copyPromotion, this.promotion)

      const promotion = await this.updatePromotion({ id: promotionId, params })

      this.promotion = this.mutationsBannerTypes(promotion)
      this.copyData()
      this.preload = false
    },

    compareObjects(defaultObj, newObj) {
      let changedKeys = {}

      for (let key in defaultObj) {
        for (let key in newObj) {
          if (key !== "history" && key !== "label") {
            if (
              Object.prototype.hasOwnProperty.call(defaultObj, key) &&
              defaultObj[key] !== newObj[key]
            ) {
              if (NAME_BANNER_TYPES.includes(key)) {
                changedKeys[key] = newObj[key].imgData
              } else {
                changedKeys[key] = newObj[key]
              }
            } else if (!Object.prototype.hasOwnProperty.call(defaultObj, key)) {
              if (NAME_BANNER_TYPES.includes(key)) {
                changedKeys[key] = newObj[key].imgData
              } else {
                changedKeys[key] = newObj[key]
              }
            }
          }
        }
      }

      return changedKeys
    },

    addBanner(banner) {
      this.promotion[banner.type] = banner
    },

    removeBanner({ platform }) {
      this.promotion[platform] = null
    },

    mutationsBannerTypes(promotion) {
      for (let key in promotion) {
        if (NAME_BANNER_TYPES.includes(key) && promotion[key]) {
          if (NAME_BANNER_TYPES[2] === key) {
            promotion[key] = { url: promotion[key] + "coupon", name: "" }
          } else {
            promotion[key] = { url: promotion[key] + "public", name: "" }
          }
        }
      }

      return promotion
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  height: 100%;
}

.status-row {
  display: flex;
  margin-bottom: 46px;
  align-items: center;
}

.status {
  font-weight: 600;
  line-height: 20px;
  margin-left: 8px;
}

.edited-status {
  font-weight: 600;
  line-height: 20px;
  color: $light-red;
  margin-left: 8px;
}

.confirm-changes {
  position: relative;
  display: flex;
  align-items: center;

  &-dropdown {
    position: absolute;
    top: 30px;
    display: flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: $white;
    padding: 16px;
    width: 189px;
    cursor: pointer;

    &--text {
      line-height: 20px;
    }
  }
}

.changes-confirmed {
  line-height: 20px;
  text-decoration: underline;
  margin-left: 8px;
}

.info {
  display: flex;
  margin-bottom: 32px;
}

.text {
  font-size: 14px;
  line-height: 16px;
}

.input-row {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  &.promocode {
    .input-label {
      min-width: auto;
    }
  }
}

.align-items-center {
  align-items: center;
}

.input-label {
  font-size: 14px;
  line-height: 16px;
  min-width: 112px;
  max-width: 112px;
  padding-top: 8px;
}

.v-input.page-input {
  width: 512px;
}

.v-input.promotion-name,
.promotion-description {
  &.disabled {
    background: $grey6;
    border-color: $grey6;
  }

  &.valid {
    border-color: $light-red;
  }

  &.coupon-qr {
    width: 360px;
  }
}

.v-input.promotion-name {
  width: 248px;
  padding: 16px;
  border: 1px solid $grey5;
  border-radius: 8px;

  &.coupon-qr {
    padding: 7px 16px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    :deep .v-input--filed {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  :deep .v-input--filed {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}

.v-input.promotion-promo {
  padding-left: 0;

  &.valid {
    border-color: $light-red;
  }

  :deep .v-input--filed {
    font-weight: 600;
  }
}

.promotion-description {
  height: 140px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &.coupon-qr {
    height: 72px;
  }
}

.qr-description-input {
  width: 360px;
  height: 72px;
}

.banners {
  margin-bottom: 32px;
}

.buttons-row {
  display: flex;
  gap: 24px;
  margin-top: 32px;
}

.history-section {
  margin-top: 20px;
}

.history-table {
  margin-top: 17px;
  width: 882px;
}

.drop-down {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--label {
    font-size: 14px;
    line-height: 16px;
    margin-right: 9px;
  }
}

.arrow-up {
  transition: transform 0.3s;
}

.arrow-up-active {
  transform: rotate(180deg);
}

.arrow-down {
  transform: rotate(180deg);
  margin-left: 9px;
  cursor: pointer;
}

.mt-8 {
  margin-top: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.banner-block {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.bdr-8 {
  :deep .download-picture--container {
    border-radius: 8px;
  }
}
</style>
