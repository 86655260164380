<template>
  <svg
    width="102"
    height="24"
    viewBox="0 0 102 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.3259 15.3503C37.9123 11.5323 43.4302 10.8072 43.8882 14.6057C44.3816 18.6977 38.7282 19.065 38.3259 15.3503ZM38.2342 8.91016C38.1449 6.36708 39.1119 2.01427 36.0373 1.86194C32.7992 1.70146 33.2713 5.85645 33.3339 8.51714C33.3941 11.0765 33.1421 15.0822 33.7895 17.3167C34.4394 19.5597 36.1799 21.4331 38.38 22.092C44.1207 23.8115 48.7927 19.9499 48.5385 14.4164C48.2946 9.10732 43.2543 6.30499 39.7346 8.00494C39.1414 8.29139 38.8193 8.72665 38.2343 8.91021L38.2342 8.91016Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.9209 15.9681C93.3076 16.1422 93.3143 16.3371 93.6281 16.8199C94.3687 17.9592 96.1727 20.3333 97.2259 21.1799C97.7787 21.6244 98.3896 22.3002 99.4849 22.083C101.159 21.7507 101.977 20.1567 100.569 18.3049C99.9104 17.439 98.2703 15.4446 97.8804 14.6013C98.506 13.2488 101.853 11.6111 101.167 9.28294C100.879 8.30707 99.6225 7.37742 98.4424 8.11577C97.9917 8.3978 97.5134 8.76411 97.1634 9.08524L94.9155 11.2357C94.2076 11.9249 92.7901 13.6435 92.8176 11.0446C92.835 9.4031 92.9531 5.62893 92.8225 4.40785C92.6709 2.99002 92.4047 1.98082 91.1886 1.5717C89.7616 1.09161 88.5729 1.92973 88.1773 2.81357C87.677 3.93153 87.9245 14.2144 87.9323 15.8182C87.9444 18.3125 87.235 22.2813 90.324 22.3158C91.6994 22.3311 92.5419 21.5106 92.7647 20.3197C93.0269 18.9185 92.8741 17.4086 92.9209 15.9681Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.749 18.5735C77.5724 19.0847 75.7678 18.2651 74.8056 17.7637C72.0032 16.3034 70.0932 20.4161 74.811 21.8823C79.9846 23.4902 85.032 20.6443 83.4826 16.4812C82.3037 13.314 78.062 13.3436 77.4169 12.2509C76.8442 11.2811 77.8819 11.0933 78.6879 11.2617C79.3034 11.3903 80.0317 11.6914 80.5453 11.9328C82.4616 12.8335 83.5112 11.5129 83.3646 10.3229C83.2143 9.10286 81.7881 8.28782 80.5863 7.95976C74.7133 6.35662 70.7774 10.6093 73.0257 14.1157C74.8838 17.0134 79.2575 16.2808 78.749 18.5735Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.846 1.83991C52.9622 2.4827 53.0535 4.25016 53.0535 6.45761L53.0518 19.1888C53.0569 20.2326 53.2879 21.0042 53.8451 21.5948C54.3049 22.0823 55.1565 22.5288 56.119 22.2397C57.9992 21.6747 57.9442 19.8229 57.9442 17.6902L57.9453 4.95903C57.9301 2.6765 56.5799 1.24829 54.846 1.83995V1.83991Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.9053 20.1244C68.0448 19.3946 68.0055 11.6831 67.9618 10.4792C67.9069 8.96916 67.3196 7.81466 65.8942 7.6825C64.2443 7.5295 63.3304 8.28642 63.1163 9.75699C62.9678 10.7765 63.0012 18.0359 63.0393 19.4703C63.1362 23.1114 67.313 23.2221 67.9053 20.1244Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7126 16.0696C20.0742 15.5388 17.9166 15.3013 16.8914 15.0259C16.2381 14.8503 15.6332 14.6565 15.049 14.4328C14.4553 14.2054 13.9587 13.7587 13.3636 13.9249C13.1902 14.5361 13.8069 15.3443 13.5289 16.6241C13.2016 18.1313 12.5873 20.2094 13.8906 21.4885C14.2257 21.8174 15.1463 22.5693 15.7331 22.6488C15.6562 22.2638 15.5583 22.3427 15.3349 22.0105C14.6044 20.9243 14.9661 19.3112 14.8034 17.6915C14.75 17.1589 14.4595 16.5597 14.63 16.0463C15.6453 15.9441 17.0549 17.1776 17.4952 17.6947C17.8036 18.057 18.0775 18.6062 18.2528 19.1799C18.5524 20.1602 18.5194 20.3785 18.0857 20.8625C16.9785 20.9093 17.1472 19.9454 16.9484 18.9811C16.8063 18.2921 16.1102 17.3883 15.398 17.9344C15.1254 18.2982 15.5967 19.7504 15.708 20.4235C15.8797 21.4615 15.8922 21.9885 16.4945 22.6281C17.32 23.5047 19.4843 24.2056 21.1405 23.9453C22.2335 22.6642 21.981 17.1244 20.7125 16.0696H20.7126Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.26071 7.11332C5.7475 7.44232 6.21971 7.36041 6.7903 7.90124C7.21195 8.30093 7.54489 8.72531 7.90928 9.21707C8.39885 9.87772 8.94085 10.6473 9.54476 11.1354C9.771 11.3183 10.0208 11.4229 10.0777 11.7936C9.47435 12.498 7.2583 12.2819 6.38408 11.9566C5.6612 11.6875 3.55508 10.4163 4.56745 9.71098C5.42485 9.17086 6.19085 10.9075 7.15697 11.1924C7.57522 11.3158 8.06613 11.2645 8.25841 10.856C8.46072 10.354 8.03557 10.2071 7.72116 9.96652C6.84157 9.29344 5.86776 8.02977 4.97179 7.6593C3.50242 7.05167 0.65799 8.34361 0.00249074 9.06045C-0.0645713 10.0352 1.24092 11.9277 1.70369 12.5197C2.40362 13.4151 3.276 14.3939 4.38143 14.9881C5.80504 15.7533 7.85036 14.0852 10.2646 13.457C11.0261 13.2588 11.7569 13.2119 12.3708 12.9526C12.359 11.9781 11.7456 12.5539 10.8601 11.1323C10.1447 9.98406 9.74461 8.02351 8.12581 7.2206C7.59535 6.9575 5.58913 6.64491 5.26066 7.11341L5.26071 7.11332Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4974 12.3535C14.0029 11.6731 13.824 11.1203 14.6557 10.3517C16.1183 8.99996 18.2759 7.5981 17.3948 5.34788C17.2266 4.91813 17.1451 4.43769 16.7519 4.27395C16.2568 5.07015 17.1816 4.9855 15.3788 7.33012C15.0455 7.76363 14.7836 8.10574 14.4776 8.58408C14.1955 9.02511 13.9436 9.73568 13.6629 10.0351C12.785 9.82842 11.8893 7.386 12.5133 5.5862C12.6643 5.15049 13.2952 3.66739 13.9913 4.34821C14.5825 4.92654 13.5641 6.08104 13.4093 6.58497C13.009 7.888 14.155 8.56073 14.5583 7.72654C14.9727 6.86928 14.8281 6.8746 15.5406 5.62194C16.683 3.61344 15.98 2.60075 14.8094 1.28684C14.5831 1.03282 13.5891 -0.00818322 13.1037 4.8548e-05C12.0858 0.0173174 9.59294 3.35409 8.9361 5.09994C8.30462 6.77846 8.93963 7.29008 10.6719 8.97889C10.8822 9.18397 11.1452 9.51561 11.3652 9.7848L12.6106 11.6293C12.8569 12.1096 12.864 12.4059 13.4975 12.3535H13.4974Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.15144 16.8451C8.77556 16.6097 8.4742 16.9215 8.20166 17.0946C6.299 18.3029 3.5619 18.2693 4.56819 22.7493C4.84091 23.9635 4.77049 24.1179 6.15468 23.9284C6.91299 23.8246 7.69594 23.6946 8.40772 23.486C14.1364 21.8072 12.5963 20.0925 12.4797 17.0146C12.4412 15.9982 12.5316 15.3561 12.6434 14.3978C12.8118 12.9542 11.6794 14.0371 11.2705 14.2683C9.70364 15.1542 6.76361 14.1966 5.2329 16.4681C4.98912 16.8299 4.54881 17.7374 4.60554 18.2408C4.62603 18.4227 4.62755 18.375 4.67699 18.4597C5.64158 17.5824 4.88873 17.5416 7.53435 16.7105C8.1461 16.5184 8.72925 16.3401 9.27103 16.1063C9.72995 15.9083 10.3535 15.4465 10.7737 15.5284C11.4789 18.4445 7.54124 21.2436 6.96699 19.9904C6.83496 19.1649 7.80153 18.8745 8.44521 18.5585C9.57529 18.0037 9.68973 17.1653 9.15136 16.8451L9.15144 16.8451Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9328 13.2177C14.7345 13.5828 15.4333 13.2335 16.5259 13.797C17.8305 14.4697 19.5796 15.7005 21.3467 15.1722C22.0177 14.9717 22.8683 14.2483 23.0874 13.7668C21.9655 13.7637 22.4119 14.4118 19.6876 13.5388C19.1091 13.3534 18.6748 13.2268 18.0894 13.0803C17.5233 12.9387 16.8684 12.921 16.3618 12.7651C16.4646 11.9585 17.8812 10.9672 18.7745 10.6314C19.6298 10.3098 22.4095 9.99944 22.1307 11.2628C21.6757 12.4013 18.0823 10.3542 18.5008 12.6809C18.7841 12.7947 21.5291 13.1604 22.0865 13.2123C24.8922 13.474 26.3215 9.58714 26.1748 9.11498C25.9039 8.24371 21.7826 7.00635 20.1257 6.91718C16.9099 6.74414 17.8803 8.45858 14.8301 11.9321C14.2383 12.606 13.9097 12.5565 13.9328 13.2177V13.2177Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
