// асінхронно завантажує файли з ПК
export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

// перетворює число в масив
export const convertsNumberToArray = (nymber) => {
  const count = []

  for (let i = 0; i < nymber; i++) {
    count.push(i)
  }

  return count
}
