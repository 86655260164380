<template>
  <div
    class="checkboxes-form-item"
    :style="{ backgroundColor: !item?.validate ? '#FFF3F3' : '' }"
  >
    <AppCheckbox
      :checked="item.checked"
      :label="item.label"
      :labelColor="!item?.validate ? '#FC536A' : ''"
      @onPress="onPress"
      @onCheckLabel="$emit('onCheckLabel')"
      @onLabelMouseOver="$emit('onLabelMouseOver', $event)"
      @onLabelMouseOut="$emit('onLabelMouseOut', $event)"
    />
  </div>
</template>

<script>
import AppCheckbox from "@/components/AppCheckbox.vue"

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  emits: ["onCheckItem", "onCheckLabel", "onLabelMouseOver", "onLabelMouseOut"],

  components: {
    AppCheckbox
  },

  data: () => ({}),
  methods: {
    onPress() {
      this.$emit("onCheckItem")
    }
  }
}
</script>

<style lang="scss" scoped>
.checkboxes-form-item {
  position: relative;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: $white;
}
</style>
