import { sendSecureRequest } from "../config"

const ENDPOINT = "admin/feedbacks"

export default {
  reviews(params) {
    return sendSecureRequest().get(`${ENDPOINT}`, { params })
  },

  review(ID) {
    return sendSecureRequest().get(`${ENDPOINT}/${ID}`)
  },

  updateReview({ id, status, content }) {
    return sendSecureRequest().put(`${ENDPOINT}/${id}`, { content, status })
  }
}
