<template>
  <div class="page-wrapper">
    <PageTitle class="mb-40" title="Фільтри" />
    <SearchInput
      class="mb-40"
      v-model="search"
      :list-searches="listSearches()"
      :name-storage="prevSearchList"
      @selectPrevItem="search = $event"
    />
    <div class="dropdowns-container">
      <AppDropdownGroup
        v-for="group in filtersGroups"
        :key="group.id"
        :group="group"
        :highlight="search"
        :isOpen="checkOpenedGroup(group.id)"
        :no-group-icon="false"
        :no-icon="!idCompositionFeatures(group)"
        :not-validate="idCompositionFeatures(group)"
        :isItemsDraggable="true"
        @changeValid="changeValidation($event, group.id)"
        @onOpen="onOpenGroup"
        @onSelectIcon="onSelectGroupItemIcon(group.id, $event)"
        @onRemoveIcon="onRemoveIconFromGroupItem(group.id, $event)"
        @onDragDropItem="changeOrderFiltersHandler(group, $event)"
      >
        <template v-slot:groupActions>
          <v-drop-down
            :value="group.type"
            :items="typeFilters"
            @onSelect="
              onSelectTypeFilter($event, group.id, group.visibility_type)
            "
          >
            <template v-slot:toggler>
              <span>тип фільтру </span>
              <span>{{ group.type }}</span>
            </template>
          </v-drop-down>

          <v-drop-down
            :value="group.visibility_type"
            :items="displayLevels"
            @onSelect="onSelectDisplayLevel($event, group.id, group.type)"
          >
            <template v-slot:toggler>
              <span>рівень відображення </span>
              <span>{{ group.visibility_type }}</span>
            </template>
          </v-drop-down>
        </template>
      </AppDropdownGroup>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import AppDropdownGroup from "@/components/AppDropdownGroup.vue"
import PageTitle from "@/components/PageTitle.vue"
import SearchInput from "@/components/SearchInput.vue"
import { TYPE_FILTERS, DISPLAY_LEVEL } from "@/constants/filters"
import { PREV_SEARCH_FILTERS } from "@/constants/localStorageKeys.js"
import { getItemlocalStorage } from "@/helpers/storage.js"
import { namespace } from "@/store/modules/catalogFilter"

export default {
  name: "CatalogFilter",
  components: {
    PageTitle,
    SearchInput,
    AppDropdownGroup
  },

  data: () => ({
    search: "",
    openedGroups: [],
    typeFilter: null,
    displayLevel: null
  }),

  async mounted() {
    await this.getFilters()
  },

  watch: {
    search(value) {
      this.openedGroups = []

      if (value) {
        this.filtersGroups.forEach((catalog) => {
          catalog.items.forEach((sub_catalog) => {
            if (
              this.ckeckIncludes(sub_catalog.name, this.search) &&
              !this.openedGroups.includes(catalog.id)
            ) {
              this.onOpenGroup(catalog.id)
            }
          })
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      groups: `${namespace}/groups`
    }),

    typeFilters() {
      return TYPE_FILTERS
    },

    displayLevels() {
      return DISPLAY_LEVEL
    },

    prevSearchList() {
      return PREV_SEARCH_FILTERS
    },

    filtersGroups() {
      const arr = []

      for (let catalog of this.groups) {
        if (this.ckeckIncludes(catalog.name, this.search)) {
          arr.push(catalog)
          continue
        }

        for (let sub_catalog of catalog.items) {
          if (
            this.ckeckIncludes(sub_catalog.name, this.search) &&
            !arr.find((el) => el.id === catalog.id)
          ) {
            arr.push({
              ...catalog,
              items: catalog.items.filter((e) =>
                this.ckeckIncludes(e.name, this.search)
              )
            })
            break
          }
        }
      }

      return arr
    }
  },

  methods: {
    ...mapActions(namespace, [
      "getFilters",
      "filtersUpdate",
      "filtersUpdateImage",
      "filtersUpdateValidate",
      "changeOrderFilters"
    ]),

    changeOrderFiltersHandler(group, data) {
      const selectedGroup = group.items
      const item = selectedGroup.find((item) => item.id === +data.new)
      const newIdx = selectedGroup.indexOf(item)
      const oldIdx = selectedGroup.findIndex((item) => item.id === +data.old)

      selectedGroup.splice(newIdx, 1)
      selectedGroup.splice(oldIdx, 0, item)
      this.changeOrderFilters({
        data: selectedGroup,
        groupId: group.id
      })
    },

    async onSelectTypeFilter(item, groupId, visibility_type) {
      const params = {
        id: groupId,
        type: item.type,
        visibility_type: visibility_type
      }

      await this.filtersUpdate(params)
    },

    async onSelectDisplayLevel(item, groupId, type) {
      const params = {
        id: groupId,
        type: type,
        visibility_type: item.type
      }

      await this.filtersUpdate(params)
    },

    ckeckIncludes(name, search) {
      return name.toUpperCase().includes(search.toUpperCase())
    },

    onOpenGroup(groupId) {
      const index = this.openedGroups.indexOf(groupId)

      if (index === -1) {
        this.openedGroups.push(groupId)
      } else {
        this.openedGroups.splice(index, 1)
      }
    },

    checkOpenedGroup(groupId) {
      return this.openedGroups.includes(groupId)
    },

    async onSelectGroupItemIcon(groupId, params) {
      await this.filtersUpdateImage({ params, groupId })
    },

    async onRemoveIconFromGroupItem(groupId, itemId) {
      const params = {
        id: itemId,
        imgData: null
      }

      await this.filtersUpdateImage({ params, groupId })
    },

    async changeValidation({ validate, itemId }, groupId) {
      await this.filtersUpdateValidate({ validate, itemId, groupId })
    },

    idCompositionFeatures(group) {
      return group.name === "Особливості складу"
    },

    listSearches() {
      return (getItemlocalStorage(this.prevSearchList) || []).slice(0, 5)
    }
  }
}
</script>
