<template>
  <div class="v-input" :class="[{ disabled: disabled }]">
    <input
      :id="id"
      :value="modelValue || value"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :type="inputType"
      class="v-input--filed"
      @input="sendData"
    />
    <slot name="action"> </slot>
  </div>
</template>

<script>
export default {
  name: "v-input",
  props: {
    modelValue: {
      type: String,
      default: ""
    },

    value: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    id: {
      type: String
    },

    inputType: {
      type: String,
      default: "text"
    }
  },

  emits: ["update:modelValue"],
  methods: {
    sendData(event) {
      this.$emit("update:modelValue", event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  &--filed {
    width: 100%;
    outline: 0;
    padding: 0;
    border: none;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 16px;
    background: inherit;

    &::placeholder {
      color: $grey;
    }
  }

  &--circle {
    border: 1px solid $grey5;
    border-radius: 15px;
    &.disabled {
      border-color: $grey6;
      background-color: $grey6;
    }
  }
  &--underline {
    border-bottom: 1px solid $grey5;
  }
}
</style>
