<template>
  <div class="banners-placeholder" :class="{ inline: inline }">
    <span class="banners-placeholder--text" v-html="text"> </span>
    <span
      class="banners-placeholder--size"
      :class="{ 'text-red': text.includes('mob') }"
    >
      {{ textSize }}
    </span>
  </div>
</template>

<script>
export default {
  name: "text-placeholder",
  props: {
    text: {
      type: String,
      default: ""
    },

    textSize: {
      type: String
    },

    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.banners-placeholder {
  position: absolute;
  top: calc(50%);
  right: calc(50%);
  display: flex;
  gap: 4px;
  flex-direction: column;
  text-align: center;
  transform: translate(50%, -50%);
  &.inline {
    flex-direction: row;
    .banners-placeholder--text {
      order: 2;
    }
    .banners-placeholder--size {
      order: 1;
    }
  }

  &--text,
  &--size {
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
  }
  &--text {
    color: $black;
  }
  &--size {
    font-weight: 300;
    color: rgba($black, 0.8);
    &.text-red {
      color: $red;
    }
  }
}
</style>
