<template>
  <div class="action-row">
    <span @click="onDelete" class="delete-btn spacing">delete</span>
    <span class="text spacing">{{ actionData.number }}</span>
    <span class="text spacing"
      >{{ formatDate(actionData.startDate, DATE_FORMAT) }} -
      {{ formatDate(actionData.endDate, DATE_FORMAT) }}</span
    >
    <span class="text">{{ actionData.name }}</span>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/date"

export default {
  props: {
    actionData: {
      type: Object
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["remove"],

  setup() {
    const DATE_FORMAT = "DD.MM"

    return {
      DATE_FORMAT
    }
  },

  methods: {
    formatDate,
    onDelete() {
      if (!this.disabled) {
        this.$emit("remove")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-row {
  display: flex;
}
.delete-btn {
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  opacity: 0.8;
  cursor: pointer;
}
.text {
  font-size: 14px;
  line-height: 16px;
}
.spacing {
  margin-right: 16px;
}
</style>
