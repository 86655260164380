export const TIMELINE_TYPES = {
  BANNER: "banner",
  PROMOTION: "promotion",
  BRAND: "brand"
}

export const TIMELINE_ELEMENT_COLORS = {
  GREEN: "#86C8A2",
  BLUE: "#56CCF2",
  GREY: "#F2F2F2",
  RED: "#FC536A",
  WHITE: "#fff",
  DERK_GREY: "#BDBDBD"
}

export const TIMELINE_ELEMENT_STATUS = {
  ACTIVE: "ACTIVE",
  SCHEDULED: "SCHEDULED",
  EXPIRED: "EXPIRED",
  NEW: "NEW"
}
