<template>
  <h1 class="title">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      requried: true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}
</style>
