<script setup>
import labelStatus from "./labelStatus.vue"
import DateFormat from "@/components/date/DateFormat.vue"

const emit = defineEmits(["modal:open"])
const props = defineProps([
  "created_at",
  "updated_at",
  "number",
  "customer",
  "product",
  "feedback",
  "status",
  "id"
])

const openModal = (id) => emit("modal:open", id)
</script>

<template>
  <div class="table-grid body cursor-pointer" @click.prevent="openModal(id)">
    <div class="table-grid--body">
      <DateFormat
        :date="created_at"
        formatter="DD.MM.YY HH:mm"
        v-slot="{ formattedDate }"
      >
        {{ formattedDate }}
      </DateFormat>
    </div>
    <div class="table-grid--body">
      <DateFormat
        :date="updated_at"
        formatter="DD.MM.YY HH:mm"
        v-slot="{ formattedDate }"
      >
        {{ formattedDate }}
      </DateFormat>
    </div>
    <div class="table-grid--body">{{ number }}</div>
    <div class="table-grid--body">
      <span>{{ customer.name }} </span>
      <span>{{ customer.phone }}</span>
    </div>
    <div class="table-grid--body">{{ product }}</div>
    <div class="table-grid--body" v-html="feedback"></div>
    <label-status :status="status" />
  </div>
</template>

<style lang="scss" scoped>
.table-grid {
  display: grid;
  grid-template-columns: calc(111px - 16px) 48px 240px 280px minmax(352px, 1fr) 157px;
  align-items: center;
  gap: 72px;
  color: $black;

  &.body {
    padding: 13px 0;
  }

  &--body {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
