export class Section {
  constructor(id, name, blocks = [], validate = true) {
    this.id = id
    this.label = name
    this.blocks = blocks
    this.validate = validate
  }
}
export class CategoryItem {
  constructor(id, label, parent_id, children = [], bold = false) {
    this.id = id
    this.label = label
    this.parent_id = parent_id
    this.children = children
    this.bold = bold
  }
}
