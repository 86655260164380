class Block {
  constructor(id, type, width, items) {
    this.id = id
    this.type = type
    this.width = width
    this.items = items
  }
}

export default Block
