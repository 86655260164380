<template>
  <div class="page-brand">
    <AppNavigationBackTitle :show-arrow="true" navLinkLabel="Бренди" />
    <div class="page-brand--form">
      <div class="data-row">
        <label for="dropdown-input" class="page-brand--label">Бренд</label>
        <v-input
          v-if="!isBrandID"
          id="dropdown-input"
          v-model="search"
          :disabled="true"
          placeholder="введіть бренд"
          class="v-input--underline"
        />
        <v-drop-down-slots
          v-else
          :disabled="allowEditing"
          class="page-brand--drop"
        >
          <template v-slot:toggler>
            <v-input
              id="dropdown-input"
              v-model="search"
              placeholder="введіть бренд"
            />
          </template>
          <template v-slot:content="{ events: { onToggle } }">
            <ul>
              <li
                v-for="brand of filteredBrands"
                :key="brand.id"
                class="page-brand--drop_item"
                @click="onSelectBrand(brand), onToggle()"
              >
                {{ brand.brand_name }}
              </li>
            </ul>
          </template>
        </v-drop-down-slots>
      </div>

      <div class="data-row">
        <span class="page-brand--label">Показ</span>
        <div class="date-row gap-16">
          <span class="mr-16">з</span>
          <VDatePicker
            v-model.range="date"
            mode="dateTime"
            is24hr
            :mask="`DD.MM.YY HH:mm`"
            :minDate="Date.now()"
            :maxDate="dayjs().add(4, 'week').toDate()"
            :locale="{ id: 'uk', masks: { weekdays: 'WW' } }"
            :disabled-dates="notAvailableDate"
          >
            <template #default="{ inputValue, togglePopover }">
              <div class="page-brand--calendar">
                <DateChip
                  :date="cutText(inputValue.start)"
                  @click="!allowEditing && isTouchDate(togglePopover)"
                />

                <span>по</span>
                <DateChip
                  :date="cutText(inputValue.end)"
                  @click="!allowEditing && isTouchDate(togglePopover)"
                />
              </div>
            </template>
          </VDatePicker>
          <span v-if="isErroDate" class="error">некоректна дата показу</span>
        </div>
      </div>
      <div class="data-row">
        <label class="page-brand--label">Сторінка </label>
        <v-input :value="linkShop" :disabled="true" class="v-input--circle" />
        <copy-label class="copy" :textToCopy="linkShop" />
      </div>
      <div class="data-row">
        <label for="nameBrand" class="page-brand--label">Назва</label>
        <v-input
          id="nameBrand"
          v-model="name"
          :disabled="allowEditing"
          placeholder="введіть назву"
          class="v-input--underline"
        />
      </div>
      <download-picture
        :params="paramsBanner['desktop']"
        :banner="imageDesktop"
        :disabled="allowEditing"
        :class="{ 'mb-24': imageDesktop }"
        @addBanner="addBanner({ ...$event, type: 'desktop' })"
        @removeBanner="removeBanner({ platform: 'desktop' })"
      />

      <div
        class="data-grid"
        :class="[imageDesktopBackground || imageMobile ? 'mb-32' : 'mb-16']"
      >
        <download-picture
          :params="paramsBanner['imageDesktopBackground']"
          :banner="imageDesktopBackground"
          :disabled="allowEditing"
          @addBanner="addBanner({ ...$event, type: 'imageDesktopBackground' })"
          @removeBanner="removeBanner({ platform: 'imageDesktopBackground' })"
        />
        <download-picture
          :params="paramsBanner['mobile']"
          :banner="imageMobile"
          :disabled="allowEditing"
          @addBanner="addBanner({ ...$event, type: 'mobile' })"
          @removeBanner="removeBanner({ platform: 'mobile' })"
        />
      </div>
      <div class="buttons-row">
        <v-btn
          v-if="allowEditing && isEdit"
          label="ЗМІНИТИ"
          @click="onAllowEditing()"
        />
        <v-btn
          v-else
          :disabled="
            !isEdit ? validateDataCreate : validateDataUpdate || isErroDate
          "
          label="ЗБЕРЕГТИ"
          @click="!isEdit ? onCrateBrandBanner() : onUpdateBanner()"
        />
        <v-btn label="ЗАКРИТИ" @click="onClose()" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { mapActions, mapGetters } from "vuex"

import DownloadPicture from "./downloadPicture.vue"

import AppNavigationBackTitle from "@/components/AppNavigationBackTitle.vue"
import CopyLabel from "@/components/CopyLabel.vue"
import DateChip from "@/components/DateChip.vue"
// my
import { LINK_SHOP } from "@/constants/app"
import { STATUS_BANNER, PLATFORM } from "@/constants/banners"
import { PARAMS_BANNER } from "@/constants/brand"

import { formatDate, getDatesBetween, validateDateTime } from "@/helpers/date"
import { namespace } from "@/store/modules/brands"

dayjs.extend(customParseFormat)

export default {
  components: {
    AppNavigationBackTitle,
    DateChip,
    CopyLabel,
    DownloadPicture
  },

  data: () => ({
    search: "",
    selectedBrand: null,
    date: {
      start: new Date(),
      end: new Date(Date.now() + 3600 * 1000 * 24)
    },

    name: "",
    imageDesktop: null,
    imageMobile: null,
    imageDesktopBackground: null,
    imageMobileBackground: null,
    allowEditing: false,
    loading: false,
    brand: null,
    brands: [],
    notAvailableDate: [],
    touchDate: false
  }),

  async mounted() {
    if (!this.bannersBrands.length) {
      await this.getBrands()
    }

    this.brands = await this.searchBrands()

    if (!this.isBrandID) {
      const dataBrand = this.brands.find((brand) => brand.id === +this.brandID)

      this.onSelectBrand({
        id: dataBrand.id,
        name: dataBrand.brand_name
      })
    }

    if (this.isEdit) {
      this.allowEditing = true
      this.brand = await this.getBanner(this.$route.query.bannerId)
      await this.init()
    }
  },

  computed: {
    ...mapGetters(namespace, {
      bannersBrands: "brands"
    }),

    isEdit() {
      return this.$route.meta.status === STATUS_BANNER.EDIT
    },

    groupID() {
      return this.$route.params.groupId
    },

    brandID() {
      return this.$route.query.brandId
    },

    isBrandID() {
      return this.brandID === "default"
    },

    linkShop() {
      return `${LINK_SHOP}brand/${this.selectedBrand?.id || ""}`
    },

    paramsBanner() {
      return PARAMS_BANNER
    },

    validateDataCreate() {
      return !(
        this.imageDesktop &&
        this.imageMobile &&
        this.imageDesktopBackground &&
        this.name &&
        this.selectedBrand &&
        !this.loading &&
        !this.isErroDate
      )
    },

    validateDataUpdate() {
      return (
        this.loading &&
        this.name === this.brand.name &&
        this.date.start === this.brand.dateStart &&
        this.date.end === this.brand.dateEnd &&
        this.imageDesktop?.url === this.brand.imageDesktop?.url &&
        this.imageMobile?.url === this.brand.imageMobile?.url &&
        this.imageDesktopBackground?.url ===
          this.brand.imageDesktopBackground?.url &&
        !this.isErroDate
      )
    },

    filteredBrands() {
      return this.brands.filter((brand) =>
        brand.brand_name.toUpperCase().includes(this.search.toUpperCase())
      )
    },

    isErroDate() {
      return (
        this.date.end - this.date.start < 3600000 ||
        new Date(this.date.end) <= new Date(this.date.start) ||
        this.isValidDate ||
        this.isEndDateTimeValid
      )
    },

    isValidDate() {
      const arrDisabledDate = this.notAvailableDate
        .map((el) =>
          getDatesBetween(el.start, el.end, "DD.MM.YYYY").reduce(
            (sum, el) => [...sum, el.formatedDate],
            []
          )
        )
        .reduce((sum, el) => [...sum, ...el], [])
      const arrSelectDate = getDatesBetween(
        this.date.start,
        this.date.end,
        "DD.MM.YYYY"
      ).reduce((sum, el) => [...sum, el.formatedDate], [])
      const finish = arrSelectDate.some((el) => arrDisabledDate.includes(el))

      return this.touchDate ? finish : false
    },

    isEndDateTimeValid() {
      return !this.isEdit && validateDateTime(this.date.end)
    }
  },

  methods: {
    dayjs,
    ...mapActions(namespace, [
      "createBanner",
      "getBanner",
      "updateBanner",
      "searchBrands",
      "availableDate",
      "getBrands"
    ]),

    formatDate,
    addBanner(banner) {
      switch (banner.type) {
        case PLATFORM.DESKTOP:
          this.imageDesktop = this.isEdit
            ? { ...banner, id: this.brand.imageDesktop.id }
            : banner
          break
        case PLATFORM.MOBILE:
          this.imageMobile = this.isEdit
            ? { ...banner, id: this.brand.imageMobile.id }
            : banner
          break
        case "imageDesktopBackground":
          this.imageDesktopBackground = this.isEdit
            ? { ...banner, id: this.brand.imageDesktopBackground.id }
            : banner
          break
        case "imageMobileBackground":
          this.imageMobileBackground = this.isEdit
            ? { ...banner, id: this.brand.imageMobileBackground.id }
            : banner
          break
      }
    },

    removeBanner({ platform }) {
      switch (platform) {
        case PLATFORM.DESKTOP:
          this.imageDesktop = null
          break
        case PLATFORM.MOBILE:
          this.imageMobile = null
          break
        case "imageDesktopBackground":
          this.imageDesktopBackground = null
          break
        case "imageMobileBackground":
          this.imageMobileBackground = null
          break
      }
    },

    onClose() {
      this.$router.back()
    },

    onAllowEditing() {
      this.allowEditing = false
    },

    init() {
      this.name = this.brand.name
      this.date = { start: this.brand.dateStart, end: this.brand.dateEnd }
      this.imageDesktop = this.brand.imageDesktop
      this.imageMobile = this.brand.imageMobile
      this.imageDesktopBackground = this.brand.imageDesktopBackground
      this.imageMobileBackground = this.brand.imageMobileBackground
    },

    formatDateLocal(date, format = "YYYY-MM-DD HH:mm:ss") {
      return dayjs(date).format(format)
    },

    newBanner() {
      return {
        groupId: +this.groupID || this.brand?.groupId || null,
        brandId: this.selectedBrand.id,
        name: this.name,
        dateStart: this.formatDateLocal(this.date.start),
        dateEnd: this.formatDateLocal(this.date.end),
        imageDesktop: this.generateImageData(this.imageDesktop),
        imageMobile: this.generateImageData(this.imageMobile),
        imageDesktopBackground: this.generateImageData(
          this.imageDesktopBackground
        ),

        imageMobileBackground: this.generateImageData(
          this.imageDesktopBackground
        )
      }
    },

    generateImageData(obj) {
      if (!obj) return null

      return obj?.imgData
        ? { name: obj.name, imgData: obj.imgData, id: obj.id }
        : { name: obj.name, url: obj.url, id: obj.id }
    },

    async onCrateBrandBanner() {
      this.touchDate = true

      if (!this.validateDataCreate) {
        try {
          this.loading = true
          await this.createBanner(this.newBanner())
        } catch (e) {
          console.log("create brand error", e)
        }
      }
    },

    async onUpdateBanner() {
      this.touchDate = true

      if (!this.validateDataUpdate) {
        try {
          this.loading = true
          await this.updateBanner({ ...this.newBanner(), id: this.brand.id })
          this.loading = false
          this.allowEditing = true
        } catch {
          this.loading = false
        }
      }
    },

    async onSelectBrand(brand) {
      this.selectedBrand = brand
      this.search = brand.name || brand.brand_name
      this.notAvailableDate = await this.availableDate({
        parentID: this.selectedBrand.id,
        bannerID:
          this.$route.query?.bannerId &&
          !this.$route.query?.bannerId.includes("-")
            ? this.$route.query.bannerId
            : null
      })
    },

    cutText(text) {
      if (!text) return ""
      const mask = "DD.MM.YY HH:mm"
      const momentDate = dayjs(text, "DD.MM.YYYY HH:mm")
      return dayjs(momentDate).format(mask)
    },

    isTouchDate(fn) {
      this.touchDate = true
      fn()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/pages/brand.scss";

.data-grid {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}
</style>
