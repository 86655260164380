<template>
  <v-drop-down-slots
    ref="dropDown"
    class="drop-down-search"
    :style="{
      '--height': scrollClass
    }"
  >
    <template v-slot:toggler>
      <input
        autocomplete="off"
        type="text"
        name="dropDownSearch"
        id="dropDownSearch"
        :value="search"
        @input="changeInputHandler($event.target.value)"
      />
    </template>
    <template v-slot:content>
      <div
        ref="dropDownContent"
        @scroll="onScroll($event)"
        class="drop-down-search__content"
      >
        <div
          class="drop-down-search__item"
          v-for="item in items"
          :key="item.id"
          @click="selectElement(item)"
        >
          {{ item[nameKey] }}
        </div>
      </div>
    </template>
  </v-drop-down-slots>
</template>

<script>
import { debounce } from "@/helpers/strings"

export default {
  name: "DropDownWithSearch",
  props: {
    items: {
      type: Array,
      required: true
    },

    nameKey: {
      type: String,
      default: "name"
    },

    startSearch: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    search: "",
    activeItem: {},
    scrollClass: null
  }),

  watch: {
    startSearch(value) {
      this.search = value
    },

    items(value) {
      this.scrollClass = value?.length >= 8 ? "50px" : "0px"
    }
  },

  methods: {
    changeInputHandler(value) {
      debounce(() => {
        this.search = value
        this.$emit("searchChange", value)
      }, 200)
    },

    selectElement(item) {
      this.search = item[this.nameKey]
      this.activeItem = item
      this.$emit("searchChange", item[this.nameKey])
      this.$emit("selectedItem", item)
      this.$refs.dropDown.onClose()
    },

    onScroll(event) {
      const { scrollHeight, scrollTop, clientHeight } = event.target

      this.scrollClass =
        clientHeight + scrollTop < scrollHeight - 5 ? "50px" : "0px"
    }
  }
}
</script>

<style lang="scss" scoped>
$h: --height;

.drop-down-search {
  height: 20px;
  border: none;
  border-bottom: 1px solid $grey5;

  &__item {
    min-width: 200px;
    cursor: pointer;
    width: 100%;
    padding: 5px 16px;
    font-size: 14px;
  }

  &__content {
    overflow-y: scroll;
    position: relative;
    max-height: 196px;
  }

  input {
    height: 19px;
    border: none;
    width: 350px;
    font-family: "Open Sans", sans-serif;
  }

  :deep .v-drop-down--content {
    width: 380px;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      height: var(--height);
      width: 380px;
      pointer-events: none;
      margin-top: -50px;
      bottom: 0;
      background: linear-gradient(to top, $white, transparent);
    }
  }
}
</style>
