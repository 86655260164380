<template>
  <div class="page-wrapper">
    <PageTitle class="page-title" title="Бренди" />
    <v-search
      class="search"
      v-model="searchTerm"
      placeholder="cторінка"
      :name-store="prevSearchList"
      :list-searches="listSearches()"
    />
    <my-timeline :data="filteredBrands" @onPressElement="onPressBanner" />
    <span class="add-brand-button" @click="goToCreateBrand('NEW')">
      + додати бренд
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import MyTimeline from "@/components/MyTimeline"
import PageTitle from "@/components/PageTitle"
import { PREV_SEARCH_BRANDS } from "@/constants/localStorageKeys.js"
import { TIMELINE_TYPES, TIMELINE_ELEMENT_STATUS } from "@/constants/timeline"
import { getBackgroundColor, getElementLabel } from "@/helpers/elements"
import { getItemlocalStorage } from "@/helpers/storage.js"
import TimelineRow from "@/models/timeline/TimelineRow"
import TimelineSubitem from "@/models/timeline/TimelineSubitem"
import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/brands/"

export default {
  components: {
    MyTimeline,
    PageTitle
  },

  async mounted() {
    await this.getBrands()
  },

  data() {
    return {
      searchTerm: ""
    }
  },

  computed: {
    ...mapGetters(namespace, ["brands"]),

    filteredBrands() {
      return this.timelineRows.filter((group) =>
        group.rowLabel.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    },

    timelineRows() {
      return this.brands.map((brand) => {
        const subItems = brand.banners.map((banner) => {
          return new TimelineSubitem({
            id: banner.id,
            label: getElementLabel(banner.name, banner.status),
            startDate: banner.dateStart,
            endDate: banner.dateEnd,
            status: banner.status,
            title: `${banner.counter} ${banner.name}`,
            backgroundColor: getBackgroundColor(banner.status)
          })
        })

        return new TimelineRow({
          id: brand.id,
          rowLabel: brand.name,
          type: TIMELINE_TYPES.BRAND,
          subItems,
          value: brand
        })
      })
    },

    prevSearchList() {
      return PREV_SEARCH_BRANDS
    }
  },

  methods: {
    ...mapActions(namespace, ["getBrands"]),

    onPressBanner({ element, row }) {
      this.goToCreateBrand(
        element.status,
        row.id,
        row.value.brandId,
        element.id
      )
    },

    goToCreateBrand(
      status,
      groupId = "default",
      brandId = "default",
      bannerId
    ) {
      const isNew = status === TIMELINE_ELEMENT_STATUS.NEW

      this.$router.push({
        name: isNew ? ROUTE.CREATE_BRAND.name : ROUTE.EDIT_BRAND.name,
        params: { groupId, brandId },
        query: { brandId, bannerId }
      })
    },

    listSearches() {
      return (getItemlocalStorage(this.prevSearchList) || []).slice(0, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-title {
  margin-bottom: 40px;
}

.search {
  margin-bottom: 40px;
}

:deep .timeline-container {
  height: fit-content;

  .row-label {
    padding-left: 16px;
  }
}

.add-brand-button {
  padding: 20px 0 20px 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}
</style>
