<template>
  <svg
    width="7"
    height="14"
    viewBox="0 0 7 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.85231 6.61582L0.857334 0.158317C0.660916 -0.0531317 0.34291 -0.0527762 0.146821 0.159411C-0.0491166 0.37157 -0.0486103 0.715252 0.147833 0.926865L5.78597 7.00003L0.147631 13.0732C-0.0487876 13.2848 -0.0492939 13.6283 0.146618 13.8405C0.244916 13.9468 0.373693 14 0.50247 14C0.630918 14 0.759189 13.9472 0.857309 13.8415L6.85231 7.3842C6.94691 7.28254 7 7.14418 7 7.00003C7 6.85587 6.94676 6.71768 6.85231 6.61582Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#BDBDBD"
    }
  }
}
</script>

<style lang="scss" scoped></style>
