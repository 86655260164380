<template>
  <label class="v-checkbox" :class="classes">
    <input type="checkbox" class="v-checkbox--input" @change="onChange" />
    <div class="v-checkbox--checkmark" :class="{ active: modelValue || value }">
      <CheckIcon v-show="modelValue || value" />
    </div>
    <span v-show="label" class="v-checkbox--label">{{ label }}</span>
  </label>
</template>
<script>
import CheckIcon from "@/components/svg/CheckIcon.vue"

export default {
  name: "v-checkbox",
  components: {
    CheckIcon
  },

  props: {
    label: {
      type: String,
      default: ""
    },

    value: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["change", "update:modelValue"],
  computed: {
    classes() {
      return {
        "v-checkbox--disabled": this.disabled
      }
    }
  },

  methods: {
    onChange(event) {
      if (!this.disabled) {
        this.$emit("update:modelValue", event.target.checked)
        this.$emit("change", event.target.checked)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$size: 18px;
.v-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  &--disabled {
    .v-checkbox--checkmark {
      cursor: no-drop;
    }
  }
  &--input {
    display: none;
  }
  &--label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000000;
  }
  &--checkmark {
    min-width: $size;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #666666;
    border-radius: 4px;
    box-sizing: border-box;
    &.active {
      background-color: #000;
      border-color: #000;
    }
  }
}
</style>
