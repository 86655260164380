<template>
  <div class="checkboxes-form" :class="[activeClass, scrollClass]">
    <span v-if="!items.length">Данні відсутні</span>
    <div
      class="checkboxes-form--content"
      ref="listWrapper"
      @scroll="onScroll($event)"
    >
      <div ref="list">
        <CheckboxesFormItem
          v-for="item in items"
          :key="item.id"
          :data-id="item.id"
          :draggable="disabled"
          :item="item"
          @dragover.prevent
          @dragenter.prevent
          @dragstart="startDrag($event, item, disabled)"
          @drop="endDrag($event, item, disabled, 'onMoveItem')"
          @onCheckItem="onCheckItem(item)"
          @onCheckLabel="onCheckLabel(item)"
          @onLabelMouseOver="setPosition($event, item)"
          @onLabelMouseOut="showModal = false"
        />
      </div>
    </div>
    <AppPopup
      ref="popupError"
      class="checkboxes-form-item-popup"
      :style="styles"
      :text="textError"
    />
  </div>
</template>

<script>
import CheckboxesFormItem from "./CheckboxesFormItem.vue"

import AppPopup from "@/components/AppPopup.vue"
import DraggableMixin from "@/mixins/draggable.mixin"

export default {
  mixins: [DraggableMixin],
  props: {
    items: {
      type: Array,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onCheck", "onMoveItem", "onCheckLabel"],

  components: {
    CheckboxesFormItem,
    AppPopup
  },

  data: () => ({
    showModal: false,
    scrollClass: "",
    position: { x: 0, y: 0 },
    textError: ""
  }),

  computed: {
    activeClass() {
      return this.items.length ? "active" : ""
    },

    styles() {
      return {
        position: "fixed!important",
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
        visibility: this.showModal ? "visible" : "hidden"
      }
    }
  },

  watch: {
    items() {
      this.$nextTick(() => {
        this.setScrollClass()
      })
    }
  },

  methods: {
    onCheckItem(item) {
      if (!this.disabled) return

      this.$emit("onCheck", item)
    },

    onCheckLabel(item) {
      this.$emit("onCheckLabel", item)
    },

    setPosition(event, item) {
      if (!this.disabled || item.validate) return

      this.showModal = true
      this.textError = item.textError
      const { x, y } = event.target.getBoundingClientRect()
      const { height } = this.$refs.popupError.$el.getBoundingClientRect()

      this.$nextTick(() => {
        this.position = { x: x, y: y - height }
      })
    },

    setScrollClass() {
      this.scrollClass =
        this.$refs.list?.offsetHeight > this.$refs.listWrapper?.offsetHeight
          ? "scroll"
          : ""
    },

    onScroll(event) {
      const { scrollHeight, scrollTop, clientHeight } = event.target

      this.scrollClass = clientHeight + scrollTop < scrollHeight ? "scroll" : ""
    }
  }
}
</script>

<style lang="scss" scoped>
.checkboxes-form {
  display: flex;
  flex-direction: column;
  width: 234px;
  padding: 16px;
  max-height: 300px;
  height: max-content;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $grey6;
  &--content {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: none;
    }
  }
  .checkboxes-form-item-popup {
    width: fit-content;
    white-space: nowrap;
    z-index: 1;
  }
  &.active {
    opacity: 1;
    z-index: 2;
  }

  &.scroll {
    position: relative;
    &:before,
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      height: 50px;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      background: linear-gradient(to bottom, $white, transparent);
    }
    &:after {
      bottom: 0;
      background: linear-gradient(to top, $white, transparent);
    }
  }
}
</style>
