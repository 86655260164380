<template>
  <div class="documents-page">
    <div class="documents-header">
      <PageTitle class="mb-40" title="Документи" />
      <DropDownWithSearch
        :items="filterShops"
        :clearInChange="isActiveShopFiltering"
        nameKey="address"
        :startSearch="searchDropDown"
        @searchChange="setSearch($event)"
        @selectedItem="changeShop($event)"
      />
    </div>
    <SearchInput
      class="mb-40"
      v-model="search"
      :list-searches="listSearches()"
      :name-storage="prevSearchList"
      @selectPrevItem="search = $event"
    />
    <div v-if="loading" class="loading-status">Loading ...</div>
    <div v-if="!loading && documents && documents.length">
      <AppTable :list="documents" :openRowOnIcon="true">
        <template v-slot:header>
          <div class="table-grid">
            <div>дата</div>
            <div>назва</div>
            <div></div>
            <div>посилання</div>
          </div>
        </template>
        <template #body="{ date, name, link, document_type }">
          <div class="table-grid">
            <div>{{ date }}</div>
            <div>{{ name }}</div>
            <div
              class="flag"
              :class="{
                'flag--balance': document_type === 'balance',
                'flag--movements': document_type === 'movement'
              }"
            >
              <span v-if="document_type">{{
                document_type === "balance" ? "баланс" : "зміни балансу"
              }}</span>
            </div>

            <div
              class="download"
              @click="
                downloadDocument({
                  url: link,
                  label: name
                })
              "
            >
              <img src="@/assets/icons/download.svg" alt="" />
            </div>
          </div>
        </template>
        <template #endElement>
          <span></span>
        </template>
      </AppTable>
      <div class="documents-footer">
        <CountElements
          v-if="documentsFullData"
          :current="documentsFullData.to || NaN"
          :total="documentsFullData.total || NaN"
        />
        <PaginationLaravel
          v-if="documentsFullData"
          :info="documentsFullData"
          @changePage="changePage($event)"
        />
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import api from "@/api"
import AppTable from "@/components/AppTable.vue"
import DropDownWithSearch from "@/components/DropDownWithSearch"
import PageTitle from "@/components/PageTitle.vue"
import SearchInput from "@/components/SearchInput.vue"
import CountElements from "@/components/pagination/countElements.vue"
import PaginationLaravel from "@/components/pagination/paginationLaravel.vue"
import { PREV_SEARCH_DOCUMENTS } from "@/constants/localStorageKeys.js"
import { debounce } from "@/helpers/strings"
import LeftoversFolderMixin from "@/mixins/leftoversFolder.mixin"
import { namespace } from "@/store/modules/documents"

export default {
  name: "Documents",
  mixins: [LeftoversFolderMixin],
  components: {
    PageTitle,
    SearchInput,
    DropDownWithSearch,
    AppTable,
    CountElements,
    PaginationLaravel
  },

  async created() {
    this.search = this.$route.query.search || ""
    this.page = this.$route.query.page
    await this.getShops()
    this.setActiveShop(
      this.shops.find((shop) => shop.id === +this.$route.query.activeShop) ||
        this.shops[0],
      false
    )
    await this.getProducts()
  },

  data: () => ({
    search: "",
    searchDropDown: "",
    isActiveShopFiltering: true,
    selectedShop: {},
    loading: true,
    disableSearchRequest: true,
    page: 1,
    routeKey: "DOCUMENTS"
  }),

  computed: {
    ...mapGetters({
      documentsFullData: `${namespace}/documentsFullData`,
      documents: `${namespace}/documents`
    }),

    prevSearchList() {
      return PREV_SEARCH_DOCUMENTS
    },

    filterShops() {
      return this.isActiveShopFiltering
        ? this.shops.filter((shop) =>
            shop.address
              .toLowerCase()
              .includes(this.searchDropDown.toLowerCase())
          )
        : this.shops
    }
  },

  watch: {
    search(phrase) {
      if (!this.disableSearchRequest) {
        debounce(() => {
          this.setSearchResult(phrase)
        }, 500)
      }
    }
  },

  methods: {
    ...mapActions({
      getDocuments: `${namespace}/getDocuments`,
      shopDocuments: `${namespace}/shopDocuments`
    }),

    async getProducts() {
      this.loading = true
      const id = this.selectedShop?.id || this.$route.query.activeShop

      if (id) {
        await this.shopDocuments({
          id,
          page: this.page,
          searchPhrase: this.search
        })
      } else {
        await this.getDocuments({ page: this.page, searchPhrase: this.search })
      }

      this.loading = false

      if (this.disableSearchRequest) this.disableSearchRequest = false
    },

    downloadDocument({ url, label }) {
      api.documents
        .getFile(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")

          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    }
  }
}
</script>

<style lang="scss" scoped>
.documents {
  &-header {
    display: flex;
    gap: 100px;
  }
  &-footer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
.download img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.loading-status {
  text-align: center;
}
.flag {
  font-size: 14px;
  border-radius: 6px;
  width: max-content;
  padding: 3px 10px;
  color: #fff;
  &--balance {
    background: rgb(134, 200, 162);
  }
  &--movements {
    background: rgb(86, 204, 242);
  }
}
.table-grid {
  display: grid;
  grid-template-columns: 150px 580px 120px 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
