export const PREV_SEARCH_FILTERS = "prev-search-filters"
export const PREV_SEARCH_EFFECTS = "prev-search-effects"
export const PREV_SEARCH_COLORS = "prev-search-colors"
export const PREV_SEARCH_ACTIVE_COMPONENTS = "prev-search-active-components"
export const PREV_SEARCH_LEAVINGS = "prev-search-leavings"
export const PREV_SEARCH_DOCUMENTS = "prev-search-documents"
export const PREV_SEARCH_ORDERS = "prev-search-orders"
export const PREV_SEARCH_BANNER = "prev-search-banner"
export const PREV_SEARCH_BRANDS = "prev-search-brands"
export const PREV_TRADING_POINTS_BRANDS = "prev-trading-points"
export const PREV_SEARCH_PROMOTIONS = "prev-search-promotions"
export const PREV_SEARCH_REVIEWS = "prev-search-reviews"
