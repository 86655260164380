import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "tradingPoints"

const state = () => ({
  tradingPoints: {}
})

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
