<template>
  <div class="page-wrapper">
    <PageTitle class="mb-40" title="Кольори" />
    <SearchInput
      class="mb-40"
      v-model="search"
      :list-searches="listSearches()"
      :name-storage="prevSearchList"
      @selectPrevItem="search = $event"
    />
    <div class="dropdowns-container">
      <AppDropdownGroup
        v-for="group in filtersGroups"
        :key="group.id"
        :group="group"
        :highlight="search"
        :no-group-icon="false"
        :isOpen="checkOpenedGroup(group.id)"
        @onOpen="onOpenGroup"
        @onSelectGroupIcon="onUpdateIcon(group.id, $event)"
        @onRemoveGroupIcon="onRemoveIcon($event)"
        @onSelectIcon="onUpdateIcon(group.id, $event)"
        @onRemoveIcon="onRemoveIcon(group.id, $event)"
        @changeValid="changeValidation($event, group.id)"
      >
        <template v-slot:groupActions>
          <v-drop-down
            :value="group.type"
            :items="typeColors"
            @onSelect="onSelectType($event, group.id)"
          >
            <template v-slot:toggler>
              <span>тип: </span>
              <span>{{ group.type }}</span>
            </template>
          </v-drop-down>
        </template>
      </AppDropdownGroup>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import AppDropdownGroup from "@/components/AppDropdownGroup.vue"
import PageTitle from "@/components/PageTitle.vue"
import SearchInput from "@/components/SearchInput.vue"
import { PREV_SEARCH_COLORS } from "@/constants/localStorageKeys.js"
import { getItemlocalStorage } from "@/helpers/storage.js"
import { namespace } from "@/store/modules/catalogColors"
import { mutationTypes } from "@/store/modules/catalogColors/constants"

export default {
  name: "CatalogColors",
  components: {
    PageTitle,
    SearchInput,
    AppDropdownGroup
  },

  data: () => ({
    search: "",
    openedGroups: []
  }),

  async mounted() {
    await this.getColors()
  },

  watch: {
    search(value) {
      this.openedGroups = []

      if (value) {
        this.filtersGroups.forEach((catalog) => {
          catalog.items.forEach((sub_catalog) => {
            if (
              this.ckeckIncludes(sub_catalog.name, this.search) &&
              !this.openedGroups.includes(catalog.id)
            ) {
              this.onOpenGroup(catalog.id)
            }
          })
        })
      }
    }
  },

  computed: {
    ...mapGetters(namespace, ["groups"]),
    filtersGroups() {
      const arr = []

      for (let catalog of this.groups) {
        if (this.ckeckIncludes(catalog.name, this.search)) {
          arr.push(catalog)
          continue
        }

        for (let sub_catalog of catalog.items) {
          if (
            this.ckeckIncludes(sub_catalog.name, this.search) &&
            !arr.find((el) => el.id === catalog.id)
          ) {
            arr.push({
              ...catalog,
              items: catalog.items.filter((e) =>
                this.ckeckIncludes(e.name, this.search)
              )
            })
            break
          }
        }
      }

      return arr
    },

    prevSearchList() {
      return PREV_SEARCH_COLORS
    },

    typeColors() {
      return [
        { id: 1, type: 0, value: "круг" },
        { id: 2, type: 1, value: "квадрат" }
      ]
    }
  },

  methods: {
    ...mapActions(namespace, [
      "getColors",
      "updateImage",
      "updateValidate",
      "updateType"
    ]),

    ckeckIncludes(name, search) {
      return name.toUpperCase().includes(search.toUpperCase())
    },

    onOpenGroup(groupId) {
      const index = this.openedGroups.indexOf(groupId)

      if (index === -1) {
        this.openedGroups.push(groupId)
      } else {
        this.openedGroups.splice(index, 1)
      }
    },

    checkOpenedGroup(groupId) {
      return this.openedGroups.includes(groupId)
    },

    async onUpdateIcon(groupId, { id, imgData }) {
      await this.updateImage({ groupId, payload: { id, imgData } })
    },

    async onRemoveIcon(groupId, itemId) {
      await this.updateImage({
        groupId,
        payload: { id: itemId, imgData: null }
      })
    },

    async changeValidation({ validate, itemId }, groupId) {
      await this.updateValidate({ validate, itemId, groupId })
    },

    async onSelectType(item, groupId) {
      await this.updateType({
        id: groupId,
        color_type: item.type
      })
    },

    listSearches() {
      return (getItemlocalStorage(this.prevSearchList) || []).slice(0, 5)
    }
  }
}
</script>
