import { mutationTypes } from "./constants"

import api from "@/api"

const { documents, documentsShop } = api.documents

export default {
  async getDocuments({ commit }, { page = 1, searchPhrase = "" }) {
    const { data } = await documents(page, searchPhrase)

    commit(mutationTypes.SET_DOCUMENTS, data)
  },

  async shopDocuments({ commit }, { id, page = 1, searchPhrase = "" }) {
    const { data } = await documentsShop({ id, page, searchPhrase })

    commit(mutationTypes.SET_DOCUMENTS, data)
  }
}
