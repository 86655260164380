<template>
  <div
    @click="$emit('onPress')"
    class="delete-button"
    :style="{ backgroundColor }"
  >
    <CloseIcon v-if="type === SQUARE_BUTTON_TYPE.DELETE" :color="iconColor" />
    <BoldIcon v-else-if="type === SQUARE_BUTTON_TYPE.BOLD" :color="iconColor" />
    <LinkIcon v-else :color="iconColor" />
  </div>
</template>

<script>
import BoldIcon from "./svg/BoldIcon.vue"
import CloseIcon from "./svg/CloseIcon.vue"
import LinkIcon from "./svg/LinkIcon.vue"

import { SQUARE_BUTTON_TYPES } from "@/constants/squareButton"

export default {
  props: {
    type: {
      type: String,
      default: SQUARE_BUTTON_TYPES.DELETE
    },

    backgroundColor: {
      type: String,
      default: "#fc536a"
    },

    iconColor: {
      type: String,
      default: "#fff"
    }
  },

  emits: ["onPress"],
  components: {
    BoldIcon,
    CloseIcon,
    LinkIcon
  },

  setup() {
    const SQUARE_BUTTON_TYPE = SQUARE_BUTTON_TYPES

    return {
      SQUARE_BUTTON_TYPE
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;

  border-radius: 2px;
  cursor: pointer;
}
</style>
