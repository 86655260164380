<template>
  <div
    :class="{
      'row--dropDown': item[listSubItemsKey] && item[listSubItemsKey].length,
      'row--dropDown-open': showSubElements
    }"
  >
    <div @click="!openRowOnIcon && changeDetailsShowStatus(!isDetailsShow)">
      <slot name="row" v-bind="item"></slot>
    </div>
    <div
      v-if="showSubElements"
      class="details"
      :class="{ 'details--open': showSubElements }"
    >
      <div>
        <slot name="subItem" v-bind="item[listSubItemsKey] || item" />
      </div>
    </div>
    <slot name="endElement">
      <ArrowRightIcon
        @click="openRowOnIcon && changeDetailsShowStatus(!isDetailsShow)"
        class="icon-down row__endLineElement"
        :class="{ 'icon-down--open': showSubElements }"
    /></slot>
  </div>
</template>

<script>
import ArrowRightIcon from "@/components/svg/ArrowRightIcon.vue"

export default {
  props: {
    isDisabledEditingSubElements: {
      type: Boolean,
      default: true
    },

    disabledSubElementId: {
      type: [String, Number],
      default: null
    },

    listSubItemsKey: {
      type: String,
      default: "products"
    },

    item: {
      type: Object,
      default: () => {}
    },

    openRowOnIcon: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ArrowRightIcon
  },

  data: () => ({
    isDetailsShow: false
  }),

  computed: {
    showSubElements() {
      return this.isDetailsShow
    }
  },

  watch: {
    disabledSubElementId(newId, prevId) {
      if (this.item.id === prevId && !newId) this.isDetailsShow = false
    }
  },

  methods: {
    changeDetailsShowStatus(status) {
      if (
        this.isDisabledEditingSubElements ||
        this.item.id === this.disabledSubElementId
      ) {
        this.$emit("clickDisabledElement")
        return
      }

      this.isDetailsShow = status
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin gridOffsets {
  column-gap: 40px;
}

.table {
  &-header {
    display: grid;
    color: $grey2;
    border-bottom: 2px solid $grey5;
    @include gridOffsets;
    padding: 10px 16px;
    font-size: 12px;
  }

  &-body .row {
    position: relative;
    display: grid;
    @include gridOffsets;
    padding: 0px 16px;
    font-size: 14px;
    border-bottom: 1px solid $grey5;

    &--dropDown {
      cursor: pointer;
      padding-right: 0px;
      padding-left: 0px;

      &-open {
        background-color: $green2;
      }
    }

    &.row--dropDown-open {
      padding-bottom: 0;
    }

    &__endLineElement {
      position: absolute;
      top: 19px;
      right: 16px;
    }
  }

  .details {
    padding: 5px 16px 5px;
    margin-top: 10px;
    cursor: default;

    &--open {
      background-color: $nav-color;
    }
  }
}

.icon-down {
  cursor: pointer;
  transform: rotate(90deg);

  :deep path {
    fill: #000;
  }

  &--open {
    transform: rotate(-90deg);
  }
}
</style>
