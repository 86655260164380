class BannerPosiont {
  constructor(placeholder, width, height, text = "") {
    this.placeholder = placeholder
    this.width = width
    this.height = height
    this.text = text
  }
}

export default BannerPosiont
