import activeComponents from "./activeComponents"
import banners from "./banners"
import brands from "./brands"
import colors from "./colors"
import documents from "./documents"
import effects from "./effects"
import fillters from "./fillters"
import leavings from "./leavings"
import orders from "./orders"
import placards from "./placards"
import promotions from "./promotions"
import reviews from "./reviews"
import shops from "./shops"

export default {
  banners,
  brands,
  promotions,
  placards,
  activeComponents,
  fillters,
  effects,
  colors,
  leavings,
  documents,
  shops,
  orders,
  reviews
}
