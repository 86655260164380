<template>
  <div
    class="app-block"
    @mouseover.stop="isHoverAllForm = true"
    @mouseleave.stop="isHoverAllForm = false"
    :style="{ width: `${blockWidth}px` }"
  >
    <div class="app-block-border app-block-border_top" />
    <slot name="items" />
    <div class="app-block-border app-block-border_bottom bottom-border">
      <div
        v-if="(isHoverAllForm && disabled) || (isShowDropdown && disabled)"
        class="action-buttons"
      >
        <Dropdown
          v-if="isShowDropdown"
          v-click-outside="() => (isShowDropdown = false)"
          class="text-dropdown"
          max-height="300px"
          :cascade="true"
          :items="dropdownItems"
          @onPressItem="onSelectDropdownItemHanlder"
        />
        <AddButton
          class="bottom-border--add-button"
          @mouseover="isHoverAddButtonFalse = true"
          @mouseout="isHoverAddButtonFalse = false"
          :backgroundColor="addButtonBackgroundColor"
          :iconSize="10"
          :iconColor="addButtonIconColor"
          @onPress="toggleShowDropdown()"
        />
        <AppSquareButton
          @mouseover="isHoverDeleteButton = true"
          @mouseout="isHoverDeleteButton = false"
          :backgroundColor="deleteButtonBackgroundColor"
          :iconColor="deleteButtonIconColor"
          @onPress="onDelete()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"

import AddButton from "@/components/AddButton.vue"
import AppSquareButton from "@/components/AppSquareButton.vue"
import Dropdown from "@/components/DropDown.vue"
import { BLOCK_TYPES } from "@/constants/sections"
import { namespace } from "@/store/modules/sectionsAndForms"
import { mutationTypes } from "@/store/modules/sectionsAndForms/constants"

export default {
  props: {
    type: {
      type: String,
      required: true
    },

    dropdownItems: {
      type: Array,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onDelete", "onSelectDropdownItem"],

  components: {
    AddButton,
    AppSquareButton,
    Dropdown
  },

  data: () => ({
    isHoverAllForm: false,
    isHoverAddButtonFalse: false,
    isHoverDeleteButton: false,
    isShowDropdown: false
  }),

  computed: {
    addButtonBackgroundColor() {
      return this.isHoverAddButtonFalse ? "#5DACAD" : "transparent"
    },

    addButtonIconColor() {
      return this.isHoverAddButtonFalse ? "#fff" : "#000"
    },

    deleteButtonBackgroundColor() {
      return this.isHoverDeleteButton ? "#fc536a" : "transparent"
    },

    deleteButtonIconColor() {
      return this.isHoverDeleteButton ? "#fff" : "#000"
    },

    blockWidth() {
      switch (this.type) {
        case BLOCK_TYPES.TEXT:
          return 224
        case BLOCK_TYPES.BANNER:
          return 172
        case BLOCK_TYPES.EXTRA:
          return 100
        default:
          return 100
      }
    }
  },

  methods: {
    onSelectDropdownItemHanlder(item) {
      // якщо обрали "пуста строка" то додавати пусту строку замість назви
      const result = item.id === "empty" ? { ...item, label: "" } : item

      this.$emit("onSelectDropdownItem", result)
      this.isShowDropdown = false
    },

    toggleShowDropdown() {
      this.isShowDropdown = !this.isShowDropdown
      this.selectItem({})
    },

    onDelete() {
      this.$emit("onDelete")
      this.selectItem({})
    },

    ...mapMutations(namespace, {
      selectItem: mutationTypes.SELECT_ITEM
    })
  }
}
</script>

<style lang="scss" scoped>
$border-height: 24px;

.app-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;

  &-border {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: $border-height;
    height: 100%;
    max-height: $border-height;
    background-color: rgba($color: $light-green, $alpha: 0.4);
    &_bottom {
      bottom: -$border-height;
    }
    &_top {
      top: -$border-height;
    }
  }
}

.bottom-border {
  justify-content: flex-end;
  height: $border-height;

  &--add-button {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.action-buttons {
  position: relative;
  display: flex;
  padding-right: 5px;
}

.text-dropdown {
  position: absolute;
  z-index: 1;
  top: $border-height;
  width: 230px;
}
</style>
