<template>
  <div class="info-popup">
    <div class="header">
      <img
        v-if="item?.icon?.url"
        :src="item?.icon?.url"
        class="header--item-icon"
      />
      <span class="header--item-name">{{ item.name }}</span>
    </div>
    <p class="item-description">{{ item.description }}</p>
    <AppTriangle
      class="trianlge"
      :class="[triangleDirection]"
      borderColor="#fff"
    />
  </div>
</template>

<script>
import AppTriangle from "@/components/AppTriangle.vue"

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    triangleDirection: {
      type: String,
      default: "top"
    }
  },

  components: {
    AppTriangle
  }
}
</script>

<style lang="scss" scoped>
$fontSize: 14px;
.info-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 266px;
  height: max-content;
  padding: 22px 25px;
  background-color: $white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &--item-icon {
    width: 12px;
    height: 12px;
    margin-right: 18px;
  }

  &--item-name {
    font-style: italic;
    font-weight: 300;
    font-size: $fontSize;
    line-height: 20px;
  }
}

.item-description {
  font-size: $fontSize;
  line-height: 20px;
  text-align: center;
}

.trianlge {
  position: absolute;
  left: 50%;
  transform: rotate(180deg);
  &.top {
    bottom: -7px;
  }
  &.bottom {
    top: -7px;
    transform: rotate(363deg);
  }
}
</style>
