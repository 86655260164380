<template>
  <div class="page-wrapper">
    <PageTitle class="page-title" title="Акції" />
    <div class="search-input-row">
      <v-search
        class="search"
        v-model="search"
        :name-store="keySaveSearchHistory"
        :list-searches="searchHistory"
        @input="onSearch($event)"
        @change="onSearch($event)"
      />
      <EmptyFilter :active="isSort" @click="switchSort" />
    </div>
    <div class="timeline">
      <div v-if="!loading" class="timeline">
        <my-timeline
          :data="timelineRows"
          :type="TIMELINE_TYPE"
          @onPressElement="onPressElement"
        />
      </div>
      <div v-else style="margin: 0 auto">Loading ...</div>
    </div>
    <div class="leavings-footer">
      <CountElements
        v-if="promotionsAllOptions"
        :current="promotionsAllOptions.to || NaN"
        :total="promotionsAllOptions.total || NaN"
      />
      <PaginationLaravel
        v-if="promotionsAllOptions"
        :info="promotionsAllOptions"
        @changePage="changePage($event)"
      />
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import EmptyFilter from "@/components/EmptyFilter.vue"
import MyTimeline from "@/components/MyTimeline"
import PageTitle from "@/components/PageTitle.vue"
import CountElements from "@/components/pagination/countElements.vue"
import PaginationLaravel from "@/components/pagination/paginationLaravel.vue"
import { STATUS } from "@/constants/banners"
import { PREV_SEARCH_PROMOTIONS } from "@/constants/localStorageKeys.js"
import { TIMELINE_TYPES } from "@/constants/timeline"
import { getItemlocalStorage } from "@/helpers/storage.js"
import { truncateString, debounce } from "@/helpers/strings"
import TimelineRow from "@/models/timeline/TimelineRow"
import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/promotions"

const BG_PROMOTION = {
  GREEN: "#86C8A2",
  GREY: "#D3D3D3",
  LITE_GREY: "#F2F2F2",
  BACKGROUND_PATTERN:
    "repeating-linear-gradient(-45deg, #fff,#fff 10px,#fc536a8f 10px,#fc536a8f 20px)"
}

export default {
  components: {
    MyTimeline,
    EmptyFilter,
    PageTitle,
    PaginationLaravel,
    CountElements
  },

  setup() {
    const TIMELINE_TYPE = TIMELINE_TYPES.PROMOTION
    const keySaveSearchHistory = PREV_SEARCH_PROMOTIONS

    return {
      TIMELINE_TYPE,
      keySaveSearchHistory
    }
  },

  async created() {
    if (this.$route.query?.page) {
      this.page = this.$route.query.page
    }

    await this.fetchAllPromotions({ page: this.page })
    this.loading = false
  },

  data: () => ({
    filteredPromotions: [],
    search: "",
    isSort: false,
    searchHistory: [],
    loading: true,
    page: 1
  }),

  computed: {
    timelineRows() {
      const items = this.filteredPromotions.map((promotion) => {
        const timelineSubItems = {
          id: promotion.id,
          label: this.getElementLabel(promotion),
          title: promotion.name,
          startDate: promotion.dateStart
            ? new Date(promotion.dateStart).getTime()
            : "-",
          endDate: promotion.dateEnd
            ? new Date(promotion.dateEnd).getTime()
            : "-",
          status: promotion.status,
          isEdited: promotion.changed,
          code: promotion.code,
          backgroundColor: this.getBackgroundColor(promotion)
        }
        const timelineRow = new TimelineRow({
          id: promotion.id,
          rowLabel: promotion.name,
          value: promotion,
          type: promotion.type,
          subItems: [timelineSubItems]
        })

        return timelineRow
      })

      return items
    },

    ...mapGetters(namespace, [
      "promotions",
      "promotionsAll",
      "promotionsAllOptions"
    ])
  },

  methods: {
    ...mapActions("promotions", ["getPromotionsAll"]),

    async fetchAllPromotions(page) {
      const params = {
        s: this.search || undefined,
        sort: this.isSort || undefined,
        page: page ? page : this.page
      }

      await this.getPromotionsAll(params)
      this.filteredPromotions = this.promotionsAll
    },

    switchSort() {
      this.isSort = !this.isSort
      this.fetchAllPromotions({ page: 1 })
    },

    onSearch(value) {
      this.search = typeof value === "string" ? value : value.target.value
      this.searchHistory = this.trimArray(this.getSearchHistory(), 5)
      debounce(() => {
        this.fetchAllPromotions({ page: 1 })
      }, 500)
    },

    trimArray(arr, maxLength) {
      return arr.length > maxLength ? arr.slice(0, maxLength) : arr
    },

    getSearchHistory() {
      return getItemlocalStorage(this.keySaveSearchHistory) || []
    },

    onPressElement(elem) {
      this.navigateToCreate({
        promotionId: elem.row.value.id
      })
    },

    navigateToCreate({ promotionId }) {
      this.$router.push({
        name: ROUTE.CREATE_PROMOTION.name,
        params: { promotionId }
      })
    },

    getElementLabel(promotion) {
      const { status, banners, code } = promotion
      let text = ""

      switch (status) {
        case STATUS.PLANNED:
          text = `${code} ${banners ? "заплановано" : "+ додати банер"}`
          break
        case STATUS.PROCESSING:
          text = `${code} ${banners ? "в процесі" : "+ додати банер"}`
          break
        case STATUS.COMPLETED:
          text = `${code} ${banners ? "не активна" : "+ додати банер"}`
          break
        default:
          text = `${code} + додати банер`
          break
      }

      return text
    },

    getBackgroundColor({ status, banners, changed }) {
      let background = ""

      switch (status) {
        case STATUS.PLANNED:
          background =
            banners && changed
              ? BG_PROMOTION.BACKGROUND_PATTERN
              : BG_PROMOTION.LITE_GREY
          break
        case STATUS.PROCESSING:
          background = BG_PROMOTION.GREEN
          break
        case STATUS.COMPLETED:
          background = BG_PROMOTION.GREY
          break
        default:
          background = BG_PROMOTION.LITE_GREY
          break
      }

      return background
    },

    changePage(page) {
      this.page = page
      this.$router.push({
        name: ROUTE.PROMOTIONS.name,
        query: { ...this.$route.query, page }
      })
      this.fetchAllPromotions()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
}

.page-title {
  margin-bottom: 40px;
}
.search-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 40px;
}

.search {
  max-width: 611px;
  width: 100%;
}
.timeline {
  display: flex;
  width: 100%;
}
.leavings {
  &-footer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
