<template>
  <div class="login-wrapper">
    <div class="login-container">
      <img class="logo" src="@/assets/images/logo_dark.png" alt="Logo" />
      <form class="form">
        <div class="form-group">
          <label for="email" class="input-label">Email</label>
          <input
            id="email"
            v-model="email"
            @focus="emailInputFocused = true"
            @blur="emailInputFocused = false"
            :style="[
              emailInputFocused && focusedStyles,
              errorsContainer.emptyEmail && inputErrorStyles
            ]"
            type="email"
            class="input"
          />
        </div>
        <div class="form-group">
          <label for="password" class="input-label">Password</label>
          <div
            class="pass-input-wrapper input"
            style="padding: 0"
            :style="[
              passwordInputFocused && focusedStyles,
              errorsContainer.emptyPassword && inputErrorStyles
            ]"
          >
            <input
              id="password"
              class="pass-input"
              v-model="password"
              @focus="passwordInputFocused = true"
              @blur="passwordInputFocused = false"
              :type="isShowPassword ? 'text' : 'password'"
            />
            <div
              v-if="passwordInputFocused || password.length > 0"
              @click="isShowPassword = !isShowPassword"
              class="icon-wrapper"
            >
              <svg
                class="show-pass-icon"
                width="200"
                height="200"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 110 448 224 224 0 010-448zm0 64a160.192 160.192 0 00-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="submit-row">
          <span class="login-error">{{ loginError }}</span>
          <input
            class="login-btn"
            @click.prevent="onLogin"
            type="submit"
            value="Log in"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/auth"
import { actionTypes } from "@/store/modules/auth/constants"

export default {
  data: () => ({
    email: "",
    password: "",
    loginError: "",
    errorsContainer: {
      emptyEmail: false,
      emptyPassword: false
    },

    isShowPassword: false,
    emailInputFocused: false,
    passwordInputFocused: false
  }),

  // mounted() {
  //   this.email = "admin@example.com"
  //   this.password = "secret2"
  // },

  computed: {
    focusedStyles() {
      return {
        outline: "1px solid rgb(0, 162, 255)"
      }
    },

    inputErrorStyles() {
      return {
        outline: "1px solid rgb(255, 0, 0)"
      }
    },

    ...mapGetters(namespace, ["getUser"])
  },

  methods: {
    async onLogin() {
      if (this.validate()) {
        try {
          await this.loginUser({ email: this.email, password: this.password })
          this.$router.push({ name: ROUTE.MAIN.name, replace: true })
        } catch (e) {
          this.loginError = "Невірні пошта або пароль"
        }
      }
    },

    validate() {
      this.errorsContainer = {
        emptyEmail: !this.email,
        emptyPassword: !this.password
      }
      return (
        !this.errorsContainer.emptyEmail && !this.errorsContainer.emptyPassword
      )
    },

    ...mapActions(namespace, {
      loginUser: actionTypes.LOGIN_USER
    })
  }
}
</script>

<style lang="scss" scoped>
$input-height: 40px;
$input-horizontal-padding: 15px;

.login-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(243, 244, 246);
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 448px;

  .logo {
    width: 100%;
    margin-bottom: 24px;
  }
  .form {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background-color: #fff;
    box-shadow: 0 1px 3px 1px rgba($color: #000000, $alpha: 0.1);
    border-radius: 8px;
    width: 100%;

    &-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .input-label {
        display: block;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: rgb(55, 65, 81);
      }

      .input {
        display: inline-block;
        height: $input-height;
        line-height: 40px;
        padding: 0 $input-horizontal-padding;
        border-radius: 3px;
        width: 100%;
        border: 1px solid #6062664f;
        outline: 0;
        color: #606266;
      }

      .pass-input {
        border: none;
        width: 100%;
        height: 100%;
        padding: 0 $input-horizontal-padding;
      }

      .pass-input-wrapper {
        display: flex;
        align-items: center;
        .icon-wrapper {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 $input-horizontal-padding;
          cursor: pointer;
          .show-pass-icon {
            width: 1em;
            height: 1em;
            color: rgb(208, 209, 211);
          }
        }
      }
    }

    .submit-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .login-btn {
      align-self: flex-end;
      background-color: #47284a;
      padding: 12px 20px;
      font-size: 14px;
      color: #fff;
      min-height: 40px;
      border: none;
      border-radius: 4px;

      &:hover {
        background-color: #47284aa2;
      }
    }
  }
}
.login-error {
  font-size: 14px;
  line-height: 16px;
  color: $red;
}
</style>
