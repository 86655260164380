import { TIMELINE_ELEMENT_COLORS } from "@/constants/timeline"

class TimelineSubitem {
  constructor(data) {
    this.id = data.id
    this.label = data.label
    this.title = data.title
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.status = data.status
    this.backgroundColor = data?.backgroundColor || TIMELINE_ELEMENT_COLORS.GREY
    this.counter = data?.counter || 1
    this.isEdited = data?.isEdited || false
  }
}

export default TimelineSubitem
