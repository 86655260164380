import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/catalog-filter"
const ENDPOINT_IMAGE = `${ENDPOINT}/filter-image`
const ENDPOINT_VALIDATE = `${ENDPOINT}/validate-filter`
const ENDPOINT_SORT = `${ENDPOINT}/sort`

export default {
  filters() {
    return sendSecureRequest().get(ENDPOINT)
  },
  filtersUpdate(data) {
    return sendSecureRequest().post(ENDPOINT, data)
  },
  filtersUpdateImage(data) {
    return sendSecureRequest().post(ENDPOINT_IMAGE, data)
  },
  filtersUpdateValidate(data) {
    return sendSecureRequest().post(ENDPOINT_VALIDATE, data)
  },
  filtersSort(data) {
    return sendSecureRequest().post(ENDPOINT_SORT, data)
  }
}
