<template>
  <table class="history-container">
    <tr v-for="item in history" :key="item" class="history-row">
      <td class="row-item-cell">
        {{ formatDate(item.created_at, "DD.MM.YY HH:mm") }}
      </td>
      <td class="row-item-cell">{{ item.name || "" }}</td>
      <td class="row-item-cell">{{ item.user || "Приходько Тетяна" }}</td>
    </tr>
  </table>
</template>

<script>
import { formatDate } from "@/helpers/date"

export default {
  props: {
    history: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  methods: {
    formatDate
  }
}
</script>

<style lang="scss" scoped>
.history-container {
  border: 1px solid $grey6;
  border-collapse: collapse;
}
.history-row {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid $grey6;
  }
}

.row-item-cell {
  padding: 6px 16px;
  border-right: 1px solid $grey6;
  font-size: 14px;
  line-height: 15px;
  white-space: nowrap;

  &:nth-child(2) {
    width: 100%;
  }
}
</style>
