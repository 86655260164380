class GroudDropdown {
  constructor(id, name, items = [], icon = { url: null, file: null }) {
    this.id = id
    this.name = name
    this.items = items
    this.icon = icon
  }
}

export default GroudDropdown
