import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_PROMOTIONS]: (state, data) => {
    state.promotions = data
  },

  [mutationTypes.SET_ALL_PROMOTIONS]: (state, data) => {
    state.promotionsAll = data
  }
}
