<template>
  <div class="app-popup">
    <WarningIcon class="app-popup--icon" />
    <span class="app-popup--text">{{ text }}</span>
  </div>
</template>

<script>
import WarningIcon from "./svg/WarningIcon.vue"

export default {
  props: {
    text: {
      type: String,
      requried: true
    }
  },

  components: {
    WarningIcon
  }
}
</script>

<style lang="scss" scoped>
.app-popup {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: $red2;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  &--icon {
    margin-right: 12px;
  }

  &--text {
    font-size: 14px;
    line-height: 16px;
    color: $light-red;
  }
}
</style>
