<template>
  <div
    class="section-label"
    @mouseover="onHover(true)"
    @mouseout="onHover(false)"
  >
    <span
      class="section-label--text"
      :class="{ 'text-underline': isSelected }"
      @click="onClick"
    >
      {{ text }}
    </span>
    <AppSquareButton
      :class="{ show: hoverEffect }"
      class="section-label--delete-button"
      @onPress="onDelete"
    />
    <AppTriangle
      v-if="isSelected"
      class="triangle"
      borderColor="#BDBDBD"
      innerColor="rgba(239, 243, 242, 1)"
    />
  </div>
</template>

<script>
import AppSquareButton from "@/components/AppSquareButton.vue"
import AppTriangle from "@/components/AppTriangle.vue"

export default {
  props: {
    text: {
      type: String,
      required: true
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onPress", "onDelete"],

  components: {
    AppSquareButton,
    AppTriangle
  },

  data: () => ({
    hoverEffect: false
  }),

  methods: {
    onHover(bool = false) {
      if (!this.disabled) return

      this.hoverEffect = bool
    },

    onDelete() {
      if (!this.disabled) return

      this.$emit("onDelete")
    },

    onClick() {
      this.$emit("onPress")
    }
  }
}
</script>

<style lang="scss" scoped>
.section-label {
  position: relative;
  display: flex;
  align-items: center;
  height: 16px;

  &:hover {
    .section-label--text {
      text-decoration: underline;
    }
  }

  &--text {
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    cursor: pointer;
  }

  &--delete-button {
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
}

.triangle {
  position: absolute;
  bottom: -9px;
  left: calc(50% - 14px);
}
</style>
