import { alphabetOrdering } from "./../../../helpers/strings"
import { mutationTypes } from "./constants"

import { formatterGrout } from "@/models/dropdown/formaterGroups"

const emptyIcon = { file: null, url: null }

export default {
  [mutationTypes.SET_COMPONENTS_GROUPS](state, data) {
    state.groups = formatterGrout(alphabetOrdering(data))
  },
  [mutationTypes.ADD_ICON_TO_GROUP](state, { groupId, icon }) {
    const group = state.groups.find((group) => group.id === groupId)

    group.icon = icon
    group.items.forEach((element) => {
      if (element.icon.file === null && element.icon.url === null) {
        element.icon = icon
      }
    })
  },
  [mutationTypes.ADD_ICON_TO_GROUP_ITEM](state, { groupId, itemId, icon }) {
    const group = state.groups.find((group) => group.id === groupId)
    const item = group.items.find((item) => item.id === itemId)

    item.icon = icon
  },
  [mutationTypes.REMOVE_ICON_FROM_GROUP_ITEM](state, { groupId, itemId }) {
    const group = state.groups.find((group) => group.id === groupId)
    const item = group.items.find((item) => item.id === itemId)

    item.icon = emptyIcon

    if (
      group.items.every((el) => el.icon.file === null && el.icon.url === null)
    ) {
      group.icon = emptyIcon
    }
  },
  [mutationTypes.REMOVE_ICON_FROM_GROUP](state, { groupId }) {
    const group = state.groups.find((group) => group.id === groupId)

    group.icon = emptyIcon
    group.items.forEach((element) => {
      element.icon = emptyIcon
    })
  }
}
