<template>
  <div class="input-wrapper">
    <slot name="icon" />
    <input
      class="input"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
    />
    <ArrowUpIcon
      v-if="isDropdown"
      class="arrow-up"
      :class="[dropdownData.length > 0 && 'arrow-up-active']"
    />
    <div v-if="isDropdown && dropdownData.length > 0" class="input-dropdown">
      <div
        v-for="item in dropdownData"
        :key="item.id"
        class="input-dropdown--item"
      >
        <div class="input-dropdown--item-text">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowUpIcon from "@/components/svg/ArrowUpIcon.vue"

export default {
  props: {
    modelValue: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    isDropdown: {
      type: Boolean,
      default: false
    },

    dropdownData: {
      type: Array,
      default: () => []
    }
  },

  emits: ["update:modelValue"],

  components: {
    ArrowUpIcon
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  display: flex;
  border-bottom: 1px solid $grey5;
  padding: 0px 16px 7px 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 20px;
}

.input {
  outline: 0;
  border: none;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  height: 24px;
}

input::placeholder {
  color: $grey;
}

.input-dropdown {
  position: absolute;
  top: 39px;
  left: 1px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 100px;
  width: 100%;
  background-color: #fff;
  overflow-y: scroll;

  &--item {
    padding: 10px 8px;
    cursor: pointer;

    &-text {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
.arrow-up {
  transition: 0.3s transform;
  transform: rotate(180deg);
}

.arrow-up-active {
  transform: rotate(0deg);
}
</style>
