<template>
  <div class="page-wrapper">
    <PageTitle title="Розділи та форми" />
    <div class="work-container">
      <div class="sections-container-wrapper">
        <div
          ref="sectionsContainer"
          class="sections-container"
          :style="stylesSectionsContainer"
          @dragover="
            onDragOver($event, $refs.sectionsContainer, draggedSection)
          "
        >
          <SectionLabel
            v-for="section in placard"
            class="sections-container--label"
            :key="section.id"
            :text="section.label"
            :isSelected="checkSelectedSection(section)"
            :data-id="section.id"
            :draggable="allowEditingPlacard"
            :disabled="allowEditingPlacard"
            @onPress="onSelectSection(section)"
            @onDelete="onDeleteSection(section)"
            @dragstart="onStartDrag($event, 'sections')"
            @dragend="onEndDrag($event, 'sections')"
          />
        </div>

        <AddButton
          v-if="editPlacard"
          v-click-outside="hideDropDownCategoriesFirstLevel"
          class="add-sections-button"
          :focused="showDropDownCategoriesFirstLevel"
          @onPress="toggleVisibilityDropDownCategoriesFirstLevel()"
        />
        <DropDown
          v-if="showDropDownCategoriesFirstLevel"
          class="sections-dropdown"
          :items="categoriesFirstLevel"
          @onPressItem="onAddToSections"
        />
      </div>
      <div class="vertical-border left-border" />
      <div class="vertical-border right-border" />
      <div
        class="border"
        :style="{
          borderTop: placard.length > 0 ? `1px solid #BDBDBD` : 'none'
        }"
      />
      <div class="blocks-container-wrapper">
        <div ref="blocksContainer" class="blocks-container">
          <div class="blocks-without-extra">
            <div
              v-for="(block, idx) in blocksWithoutExtra"
              :key="block.id"
              :data-id="block.id"
              :draggable="editPlacard"
              @dragover.prevent
              @dragenter.prevent
              @dragstart="onStartDragBlock($event, block.id, idx)"
              @drop="onEndDragBlock($event, block.id, idx)"
            >
              <AppTextBlock
                v-if="block.type === blockType.TEXT"
                :type="blockType.TEXT"
                :block="block"
                :id="block.id"
                :sectionId="selectedSection.id"
                :items="block.items"
                :list="categoryChildren"
                :disabled="editPlacard"
                :selectedItem="selectedItem"
                :style="{
                  marginRight:
                    idx === selectedSection.blocks.length - 1 && !extraBlock
                      ? '0'
                      : '32px'
                }"
                @onAddItem="
                  onAddItemToBlock(selectedSection.id, block.id, $event)
                "
                @onDelete="
                  deleteBlock({
                    sectionId: selectedSection.id,
                    blockId: block.id
                  })
                "
                @selectItem="selectSybCategory($event)"
                @selectDisabledItem="setRedirectBlocking()"
              />
              <AppBannerBlock
                v-else-if="block.type === blockType.BANNER"
                :type="blockType.BANNER"
                :id="block.id"
                :sectionId="selectedSection.id"
                :items="block.items"
                :list="categoryChildren"
                :disabled="editPlacard"
                :selectedItem="selectedItem"
                :style="{
                  marginRight:
                    idx === selectedSection.blocks.length - 1 && !extraBlock
                      ? '0'
                      : '32px'
                }"
                @onAddItem="
                  onAddItemToBlock(selectedSection.id, block.id, $event)
                "
                @onDelete="
                  deleteBlock({
                    sectionId: selectedSection.id,
                    blockId: block.id
                  })
                "
                @selectItem="selectSybCategory($event)"
                @selectDisabledItem="setRedirectBlocking()"
              />
            </div>
          </div>
          <AppExtraBlock
            v-if="extraBlock"
            class="extra-block"
            :type="blockType.EXTRA"
            :id="extraBlock.id"
            :sectionId="selectedSection?.id"
            :items="extraBlock.items"
            :list="categoryExtraBlock"
            :disabled="editPlacard"
            :selectedItem="selectedItem"
            @onAddItem="
              onAddItemToBlock(selectedSection.id, extraBlock.id, $event)
            "
            @onDelete="
              deleteBlock({
                sectionId: selectedSection.id,
                blockId: extraBlock.id
              })
            "
            @selectItem="selectSybCategory($event)"
            @selectDisabledItem="setRedirectBlocking()"
          />
        </div>
        <AddButton
          v-if="editPlacard"
          v-click-outside="hideDropDownTypeBlocks"
          class="add-forms-button"
          :focused="showDropDownTypeBlocks"
          @onPress="toggleVisibilityDropDownTypeBlocks()"
        />
        <DropDown
          v-if="showDropDownTypeBlocks"
          class="forms-dropdown"
          :items="dropDownTypeBlocks"
          @onPressItem="onPressFormsDropdownItem"
        />
      </div>
      <div class="border" />
    </div>
    <div class="buttons" style="margin-bottom: 32px">
      <AppButton
        class="mr-24"
        :label="editPlacard ? 'ЗБЕРЕГТИ' : 'ЗМІНИТИ'"
        :disabled="editPlacard ? disabledPlacard : false"
        :class="{
          save: animatedBtnPlacard || (editPlacard && redirectBlocking)
        }"
        @onPress="editPlacard ? onSavePlacard() : onChangeEditState(true)"
      />
      <AppButton
        v-if="editPlacard"
        class="mr-24"
        label="ЗАКРИТИ"
        @onPress="onResetPlacard()"
      />
    </div>
    <div v-if="selectedItem" class="item-configuration">
      <span class="item-label">{{ selectedItem.label }}</span>
      <div class="item-configuration-forms" @click.stop>
        <div class="form-group">
          <span class="form-group--label">Форми</span>
          <CheckboxesForm
            :items="placardForms"
            :disabled="editBlockFilterAndForm"
            @onCheck="onCheckFormItem"
            @onCheckLabel="onCheckFormILabel"
            @onMoveItem="moveItemForm"
          />
        </div>
        <div class="form-group">
          <span class="form-group--label">Фільтри</span>
          <CheckboxesForm
            :items="placardFilters"
            :disabled="editBlockFilterAndForm"
            @onCheck="onCheckFilter"
            @onMoveItem="moveItemFilter"
          />
        </div>
        <div class="form-group" v-if="placardProducts?.products">
          <span class="form-group--label">
            {{
              selectedLabelFormOrFilter?.label ||
              selectedItemFormOrFilter?.label
            }}
          </span>
          <AppItemsTable
            v-if="placardProducts?.products?.length"
            :list="placardProducts?.products || []"
            :show-more-btn="
              placardProducts?.count <= (page - 1) * 10 + 10 ||
              placardProducts?.count <= 10
            "
            @onShowMore="onShowMoreProducts"
          />
          <span v-else class="no-data--table">Данні відсутні</span>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="selectedItem">
      <AppButton
        :label="editBlockFilterAndForm ? 'ЗБЕРЕГТИ' : 'ЗМІНИТИ'"
        class="mr-24"
        :disabled="editBlockFilterAndForm ? disabledFilterAndForm : false"
        :class="{
          save:
            animatedBtnFilterAndForm ||
            (editBlockFilterAndForm && redirectBlocking) ||
            changeStatusBlocking
        }"
        @onPress="
          editBlockFilterAndForm
            ? onSaveFiltersAndForm()
            : onChangeEditBlockFilterAndForm(true)
        "
      />
      <AppButton
        v-if="editBlockFilterAndForm"
        label="ЗАКРИТИ"
        @onPress="onResetFilterAndForm"
      />
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid"
import { mapGetters, mapMutations, mapActions } from "vuex"

import AppItemsTable from "./components/AppItemsTable.vue"
import CheckboxesForm from "./components/CheckboxesForm.vue"
import SectionLabel from "./components/SectionLabel.vue"
import AppBannerBlock from "./components/blocks/AppBannerBlock.vue"
import AppExtraBlock from "./components/blocks/AppExtraBlock.vue"
import AppTextBlock from "./components/blocks/AppTextBlock.vue"

import AddButton from "@/components/AddButton.vue"
import AppButton from "@/components/AppButton.vue"
import DropDown from "@/components/DropDown.vue"
import PageTitle from "@/components/PageTitle.vue"
import { BLOCK_TYPES, ITEM_TYPES } from "@/constants/sections"
import { CategoryItem, Section } from "@/models/sections/Section"
import BannerBlock from "@/models/sections/blocks/BannerBlock"
import ExtraBlock from "@/models/sections/blocks/ExtraBlock"
import TextBlock from "@/models/sections/blocks/TextBlock"
import BannerItem from "@/models/sections/items/BannerItem"
import { namespace } from "@/store/modules/sectionsAndForms"
import { mutationTypes } from "@/store/modules/sectionsAndForms/constants"

const BLOCKS = [
  { id: BLOCK_TYPES.TEXT, label: "Текст" },
  { id: BLOCK_TYPES.BANNER, label: "Банер" },
  { id: BLOCK_TYPES.EXTRA, label: "Екстра блок" }
]

const BLOCK_WIDTH = {
  [BLOCK_TYPES.TEXT]: 224,
  [BLOCK_TYPES.BANNER]: 172,
  [BLOCK_TYPES.EXTRA]: 100
}

const LIMIT = 10

const BLOCKS_CONTAINER_WIDTH = 1120

export default {
  components: {
    AppButton,
    AddButton,
    AppTextBlock,
    AppBannerBlock,
    AppExtraBlock,
    AppItemsTable,
    CheckboxesForm,
    DropDown,
    PageTitle,
    SectionLabel
  },

  data: () => ({
    editPlacard: false,
    editBlockFilterAndForm: false,
    showDropDownCategoriesFirstLevel: false,
    showDropDownTypeBlocks: false,
    draggedSection: null,
    draggedBlockID: null,
    categoryChildren: [],
    categoryExtraBlock: [],
    redirectBlocking: false,
    changeStatusBlocking: false,
    page: 1,
    selectedItemFormOrFilter: null,
    selectedLabelFormOrFilter: null,
    lastFormChecked: true,
    animatedBtnPlacard: false,
    animatedBtnFilterAndForm: false,
    disabledPlacard: false,
    disabledFilterAndForm: false
  }),

  async mounted() {
    await this.getPlacard()
    await this.getCategoriesFirstLevel()
    this.onSelectSection(this.placard[0] || null)
  },

  unmounted() {
    this.onResetFilterAndForm()
  },

  computed: {
    ...mapGetters(namespace, [
      "selectedSection",
      "selectedItem",
      "blocksWithoutExtra",
      "extraBlock",
      "categories",
      "placard",
      "placardFilters",
      "placardForms",
      "placardProducts",
      "placardFormsCopy",
      "placardFiltersCopy",
      "placardCopy"
    ]),

    dropDownTypeBlocks() {
      return BLOCKS
    },

    blockType() {
      return BLOCK_TYPES
    },

    categoriesFirstLevel() {
      // фільтр показує категорії, які ще не були додані до розділів
      return this.categories.filter(
        (category) => !this.placard.some((s) => s.id === category.id)
      )
    },

    stylesSectionsContainer() {
      return {
        borderBottomColor: this.placard.length > 0 ? "transparent" : "#99cccd"
      }
    },

    isSectionDataHasChanged() {
      const selectedSection = this.placardCopy.find(
        (section) => section.id === this.selectedSection?.id
      )

      return selectedSection && this.selectedSection
        ? JSON.stringify(selectedSection) !==
            JSON.stringify(this.selectedSection)
        : false
    },

    isFormDataHasChanged() {
      return (
        JSON.stringify(this.placardFormsCopy) ===
        JSON.stringify(this.placardForms)
      )
    },

    isFilterDataHasChanged() {
      return (
        JSON.stringify(this.placardFiltersCopy) ===
        JSON.stringify(this.placardFilters)
      )
    },

    allowEditingPlacard() {
      return !this.isSectionDataHasChanged && this.editPlacard
    },

    isEditingActive() {
      return this.editPlacard || this.editBlockFilterAndForm
    }
  },

  methods: {
    ...mapActions(namespace, [
      "getCategoriesFirstLevel",
      "getCategoryChildren",
      "getCategoryExtraBlock",
      "getPlacard",
      "updatePlacards",
      "updatePlacardFilters",
      "getProductValidate",
      "getPlacardsProductsByFilters",
      "getPlacardFilters"
    ]),

    async getExtraBlockСategories(id) {
      const categoriesExtraBlock = await this.getCategoryExtraBlock(id)

      this.categoryExtraBlock = categoriesExtraBlock.map((category) => ({
        id: category.id,
        label: category.category_name,
        validate: true
      }))
    },

    onSelectSection(section) {
      // категорі "Бренди" не можна редагувати і якщо відбулися змінив дочірних данних категорії
      if (section?.label === "Бренди") {
        if (this.isEditingActive) this.setRedirectBlocking()

        return
      }

      if (this.isSectionDataHasChanged && this.editPlacard) {
        this.animatedBtnPlacard = true
        return
      }

      if (
        (!this.isFormDataHasChanged || !this.isFilterDataHasChanged) &&
        this.editBlockFilterAndForm
      ) {
        this.animatedBtnFilterAndForm = true
        return
      }

      if (this.isEditingActive) {
        this.setRedirectBlocking()
        return
      }

      this.selectSection(section)
      // робимо запит на отримання підкатегорій для обраної категорії
      this.getSubCategory(section?.id)
      this.getExtraBlockСategories(section?.id)
    },

    onDeleteSection(section) {
      this.deleteSection(section.id)

      // очищаємо  обрану категорію другого рівня, якщо вона знаходилася в категорії яка була видалена
      if (this.selectedSection?.id === section?.id) {
        this.selectItem({})
      }

      // якщо категорій першого рівня не залищилося або остання категорія це "Бренди" то ощищаємо вибрану категорії
      // так як "Бренди" не можна налаштовувати
      if (!this.placard.length || this.placard[0]?.label === "Бренди") {
        this.onSelectSection(null)
      }

      // при видаленні сатегорії першого рівня, вибираємо наступну в списку
      if (this.checkSelectedSection(section) && this.placard.length) {
        this.onSelectSection(this.placard[0])
      }
    },

    async getSubCategory(ID) {
      const children = await this.getCategoryChildren(ID)

      this.categoryChildren = children.map(
        (category) =>
          new CategoryItem(
            category.id,
            category.category_name,
            category.parent_id,
            category.children.map(
              (el) => new CategoryItem(el.id, el.category_name, el.parent_id)
            ),
            true
          )
      )
      // додаю на початок пусте поле
      this.categoryChildren.unshift(new CategoryItem("empty", "пусте поле"))
    },

    onChangeEditState(bool) {
      if (bool && this.editBlockFilterAndForm) {
        this.changeStatusBlocking = true
        return
      }

      this.editPlacard = bool
      this.selectItem({})
      this.onResetFilterAndForm()
    },

    async onSavePlacard() {
      this.disabledPlacard = true
      this.animatedBtnPlacard = false
      await this.updatePlacards(this.placard)
      this.disabledPlacard = false
      this.onChangeEditState(false)
      this.onResetPlacard()
      this.redirectBlocking = false
    },

    onResetPlacard() {
      this.onChangeEditState(false)
      this.hideDropDownCategoriesFirstLevel()
      this.hideDropDownTypeBlocks()
      this.changePlacard(this.placardCopy)
      this.animatedBtnPlacard = false
      this.redirectBlocking = false
    },

    async selectSybCategory(item) {
      if (
        (!this.isFormDataHasChanged || !this.isFilterDataHasChanged) &&
        this.editBlockFilterAndForm
      ) {
        this.animatedBtnFilterAndForm = true
        return
      }

      if (this.isEditingActive) {
        this.setRedirectBlocking()
        return
      }

      this.selectItem(item)
      await this.getPlacardFilters(item.itemId)
      this.resetProducts([])
    },

    // блокує можливість переходити на інші категорії чи підкатегорії поки не будуть збережені дані або не буде натиснута кнопка закрити
    setRedirectBlocking() {
      if (this.isEditingActive) {
        this.redirectBlocking = true
        return
      }

      this.redirectBlocking = false
    },

    async onSaveFiltersAndForm() {
      this.disabledFilterAndForm = true
      this.animatedBtnFilterAndForm = false
      await this.updatePlacardFilters(this.selectedItem.id)
      this.disabledFilterAndForm = false
      this.onResetFilterAndForm()
      this.redirectBlocking = false
      this.changeStatusBlocking = false
    },

    onAddToSections(item) {
      const itemSection = new Section(item.id, item.label)

      this.addSection(itemSection)
    },

    onAddItemToBlock(sectionId, blockId, item) {
      this.addItem({ sectionId, blockId, item })
    },

    checkSelectedSection(section) {
      return this.selectedSection?.id === section.id
    },

    toggleVisibilityDropDownTypeBlocks() {
      if (this.placard.length > 0)
        this.showDropDownTypeBlocks = !this.showDropDownTypeBlocks
    },

    hideDropDownTypeBlocks() {
      this.showDropDownTypeBlocks = false
    },

    hideDropDownCategoriesFirstLevel() {
      this.showDropDownCategoriesFirstLevel = false
    },

    toggleVisibilityDropDownCategoriesFirstLevel() {
      this.showDropDownCategoriesFirstLevel =
        !this.showDropDownCategoriesFirstLevel
    },

    onResetFilterAndForm() {
      this.onChangeEditBlockFilterAndForm(false)
      this.animatedBtnFilterAndForm = false
      this.resetFilter(this.placardFiltersCopy)
      this.resetForm(this.placardFormsCopy)
      this.redirectBlocking = false
      this.changeStatusBlocking = false
    },

    onPressFormsDropdownItem(item) {
      if (item.id === BLOCK_TYPES.EXTRA) {
        this.addBlockHandler(item.id)
      } else {
        if (!this.checkCanAddBlock(item.id)) return

        this.addBlockHandler(item.id)
      }
    },

    addBlockHandler(type) {
      let block

      if (type === BLOCK_TYPES.TEXT) {
        block = new TextBlock(
          uuid.v4(),
          BLOCK_TYPES.TEXT,
          BLOCK_WIDTH[BLOCK_TYPES.TEXT]
        )
      } else if (type === BLOCK_TYPES.BANNER) {
        block = new BannerBlock(
          uuid.v4(),
          BLOCK_TYPES.BANNER,
          BLOCK_WIDTH[BLOCK_TYPES.BANNER],
          [new BannerItem(uuid.v4(), ITEM_TYPES.IMAGE)]
        )
      } else {
        if (this.extraBlock) return

        block = new ExtraBlock(
          uuid.v4(),
          BLOCK_TYPES.EXTRA,
          BLOCK_WIDTH[BLOCK_TYPES.EXTRA]
        )
      }

      this.addBlock({ sectionId: this.selectedSection.id, block })
    },

    async getProductsByFilter(ID, onlyFilter = 0) {
      const maxCount = this.placardProducts?.count

      if (maxCount <= (this.page - 1) * LIMIT) return

      const params = {
        categoryId: this.selectedItem.id,
        filterId: ID,
        limit: LIMIT,
        offset: (this.page - 1) * LIMIT,
        onlyFilter: onlyFilter
      }

      await this.getPlacardsProductsByFilters(params)
    },

    onShowMoreProducts() {
      this.page = this.page + 1

      if (this.selectedItemFormOrFilter) {
        this.getProductsByFilter(
          this.selectedItemFormOrFilter.id,
          !this.lastFormChecked ? 1 : 0
        )
      } else {
        this.getProductsByFilter(this.selectedLabelFormOrFilter.id, 1)
      }
    },

    async checkProductValidate(ID) {
      const params = {
        categoryId: this.selectedItem.id,
        filterId: ID
      }

      return await this.getProductValidate(params)
    },

    async onCheckFormItem(item) {
      this.page = 1
      this.selectedItemFormOrFilter = item
      this.selectedLabelFormOrFilter = null
      // не валідувати товари при вімкненні форми
      this.lastFormChecked = item.checked

      if (!item.checked) {
        this.getProductsByFilter(item.id, 1)
      } else {
        this.getProductsByFilter(item.id)
      }

      this.changeAllItemsFormKeyValidateTrue()
      const resp = await this.checkProductValidate(item.id)

      if (resp.validate || !item.checked) {
        this.checkedItemForm(item)
      } else {
        this.changeItemFormKeyValidate({ id: item.id, error: resp.error })
      }
    },

    onCheckFormILabel(item) {
      this.page = 1
      this.selectedLabelFormOrFilter = item
      this.getProductsByFilter(item.id, 1)
    },

    onChangeEditBlockFilterAndForm(bool) {
      this.editBlockFilterAndForm = bool
    },

    async onCheckFilter(item) {
      this.resetProducts([])
      this.changeAllItemsFilterKeyValidateTrue()
      const resp = await this.checkProductValidate(item.id)

      if (resp.validate || !item.checked) {
        this.checkedItemFilter(item)
      } else {
        this.changeItemFilterKeyValidate({ id: item.id, error: resp.error })
      }
    },

    checkCanAddBlock(itemType) {
      if (this.blocksWithoutExtra.length === 0) return true

      const marginRight = 32
      let blockWidthSum = this.blocksWithoutExtra.reduce(
        (sum, block) => sum + block.width + marginRight,
        0
      )

      blockWidthSum += BLOCK_WIDTH[itemType]

      return (
        blockWidthSum < BLOCKS_CONTAINER_WIDTH - BLOCK_WIDTH[BLOCK_TYPES.EXTRA]
      )
    },

    onStartDragBlock(event, ID, idx) {
      this.draggedBlockID = ID
    },

    onEndDragBlock(event, ID, idx) {
      event.target.classList.remove("dragging")
      this.changeBlockIdx({
        sectionId: this.selectedSection.id,
        blockId: this.draggedBlockID,
        newIdx: idx
      })
      this.draggedBlock = null
    },

    onStartDrag(event, type) {
      if (!this.editPlacard) return

      event.target.classList.add("dragging")
      this.draggedSection = event.target
    },

    onEndDrag(event, type) {
      if (!this.editPlacard) return

      event.target.classList.remove("dragging")
      const isSections = type === "sections"
      const refsBlock = isSections ? "sectionsContainer" : "blocksContainer"

      const idx = [...this.$refs[refsBlock].children].findIndex(
        (el) => el.dataset.id === event.target.dataset.id
      )

      this.changeSectionIdx({
        sectionId: Number(event.target.dataset.id),
        newIdx: Number(idx)
      })

      this.draggedSection = null
    },

    onDragOver(event, container, draggedElement) {
      if (!this.editPlacard) return

      event.preventDefault()
      const afterElement = this.getDragAfterSection(event.clientX, container)

      if (!afterElement) {
        container.appendChild(draggedElement)
      } else {
        container.insertBefore(draggedElement, afterElement)
      }
    },

    getDragAfterSection(x, container) {
      const filteredSections = [...container.children].filter(
        (section) => !section.classList.contains("dragging")
      )

      return filteredSections.reduce(
        (closest, current) => {
          const box = current.getBoundingClientRect()
          const offset = x - box.left - box.width / 2

          if (offset < 0 && offset > closest.offset) {
            return { offset: offset, element: current }
          } else {
            return closest
          }
        },
        { offset: Number.NEGATIVE_INFINITY }
      ).element
    },

    ...mapMutations(namespace, {
      addSection: mutationTypes.ADD_SECTION,
      deleteSection: mutationTypes.DELETE_SECTION,
      selectSection: mutationTypes.SELECT_SECTION,
      changeSectionIdx: mutationTypes.CHANGE_SECTION_IDX,
      addBlock: mutationTypes.ADD_BLOCK,
      addExtraBlock: mutationTypes.ADD_EXTRA_BLOCK,
      deleteBlock: mutationTypes.DELETE_BLOCK,
      changeBlockIdx: mutationTypes.CHANGE_BLOCK_IDX,
      addItem: mutationTypes.ADD_ITEM_TO_BLOCK,
      checkedItemForm: mutationTypes.CHECKED_ITEM_FORM,
      checkedItemFilter: mutationTypes.CHECKED_ITEM_FILTER,
      selectItem: mutationTypes.SELECT_ITEM,
      moveItemForm: mutationTypes.MOVE_ITEM_FORM,
      moveItemFilter: mutationTypes.MOVE_ITEM_FILTER,
      changeItemFormKeyValidate: mutationTypes.CHANGE_ITEM_FORM_VALIDATE,
      changeItemFilterKeyValidate: mutationTypes.CHANGE_ITEM_FILTER_VALIDATE,
      changeAllItemsFormKeyValidateTrue:
        mutationTypes.CHANGE_ITEMS_FORM_VALIDATE,
      changeAllItemsFilterKeyValidateTrue:
        mutationTypes.CHANGE_ITEMS_FILTER_VALIDATE,
      resetProducts: mutationTypes.PLACARD_PRODUCTS,
      changePlacard: mutationTypes.PLACARD,
      resetFilter: mutationTypes.PLACARD_FILTERS,
      resetForm: mutationTypes.PLACARD_FORMS
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/pages/sectionsAndForms.scss";
.no-data--table {
  display: flex;
  padding: 16px;
  width: 234px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $grey6;
}

.save {
  background-color: $red;
}
</style>
