import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/promotions"

export default {
  promotions() {
    return sendSecureRequest().get(`${ENDPOINT}`)
  },
  promotionsAll(params) {
    return sendSecureRequest().get(`${ENDPOINT}/all`, { params })
  },
  getPromotion(id) {
    return sendSecureRequest().get(`${ENDPOINT}/${id}`)
  },
  updatePromotion({ id, params }) {
    return sendSecureRequest().post(`${ENDPOINT}/${id}`, params)
  }
}
