import vCopyToСlipboard from "@meforma/vue-copy-to-clipboard"
import vClickOutside from "click-outside-vue3"
import VCalendar from "v-calendar"
import { createApp } from "vue"
import * as ConfirmDialog from "vuejs-confirm-dialog"

import App from "./App.vue"
import router from "./router"
import store from "./store"

import myComponents from "@/components/ui/index"
import { visible } from "@/directives/index"
import Authentication from "@/layouts/Authentication.vue"
import Default from "@/layouts/Default.vue"

import "@/assets/css/main.scss"
import "v-calendar/style.css"

const app = createApp(App)

app.component("default-layout", Default)
app.component("auth-layout", Authentication)

app.directive("visible", visible)

myComponents.forEach((component) => {
  app.component(component.name, component)
})

app.use(vClickOutside)
app.use(vCopyToСlipboard)
app.use(VCalendar, {})
app.use(router)
app.use(store)
app.use(ConfirmDialog)
app.config.devtools = true

app.mount("#app")
