<template>
  <div class="count">показано {{ current }} з {{ total }}</div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      required: true
    },

    total: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.count {
  color: $grey2;
  font-size: 10px;
}
</style>
