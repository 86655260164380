import { mutationTypes } from "./constants"

import api from "@/api"

const { getEffects, updateValidate } = api.effects

export default {
  async getFilters({ commit }) {
    const { data } = await getEffects()

    commit(mutationTypes.SET_EFFECTS, data)
  },

  async updateValidate({ commit }, { validate, itemId, groupId }) {
    const { data } = await updateValidate(itemId || groupId)

    if (data?.validate) {
      commit(mutationTypes.CHANGE_VALIDATION, { itemId, groupId })
    }
  }
}
