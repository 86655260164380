import { createStore } from "vuex"

import activeComponents from "./modules/activeComponents"
import auth from "./modules/auth"
import banners from "./modules/banners"
import brands from "./modules/brands"
import catalogColors from "./modules/catalogColors"
import catalogEffects from "./modules/catalogEffects"
import catalogFilter from "./modules/catalogFilter"
import documents from "./modules/documents"
import leavings from "./modules/leavings"
import orders from "./modules/orders"
import promotions from "./modules/promotions"
import reviews from "./modules/reviews"
import sectionsAndForms from "./modules/sectionsAndForms"
import shops from "./modules/shops"
import tradingPoints from "./modules/tradingPoints"

const store = createStore({
  modules: {
    auth,
    activeComponents,
    banners,
    brands,
    promotions,
    sectionsAndForms,
    catalogFilter,
    catalogColors,
    catalogEffects,
    shops,
    leavings,
    documents,
    orders,
    tradingPoints,
    reviews
  }
})

export const getStore = () => store

export default store
