<template>
  <div class="v-search">
    <div class="v-search--field">
      <icon-search class="icon" />
      <input
        type="text"
        v-click-outside="focusOff"
        @focus="searchFocus = true"
        @keypress.enter="onEnter"
        @blur="this.addToStore(modelValue || value)"
        :value="modelValue || value"
        :placeholder="placeholder"
        @input="sendData($event.target.value)"
      />
    </div>
    <div
      v-show="searchFocus && getDataStore().length"
      class="v-search--content"
    >
      <ul class="v-search--content_ul">
        <li
          v-for="item of getDataStore()"
          :key="item"
          @click="selectData(item)"
          class="v-search--content_li"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconSearch from "@/components/svg/SearchIcon.vue"

export default {
  name: "v-search",

  components: {
    IconSearch
  },

  props: {
    modelValue: {
      type: String,
      default: ""
    },

    value: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    focus: {
      type: Boolean,
      default: false
    },

    nameStore: {
      type: String,
      default: "search-banner"
    }
  },

  emits: ["update:modelValue", "change"],

  data() {
    return {
      searchFocus: false
    }
  },

  methods: {
    focusOff() {
      this.searchFocus = false
    },

    sendData(value) {
      this.$emit("update:modelValue", value)
    },

    selectData(value) {
      this.$emit("change", value)
    },

    getDataStore() {
      const dataStore = localStorage.getItem(this.nameStore)

      return dataStore ? JSON.parse(dataStore) : []
    },

    addToStore(value) {
      if (!value) return

      let oldData = this.getDataStore()

      if (oldData.length >= 5) {
        oldData.splice(oldData.length - 1, 1)
      }

      const data = JSON.stringify(Array.from(new Set([value, ...oldData])))

      localStorage.setItem(this.nameStore, data)
    },

    onEnter(event) {
      this.addToStore(event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-search {
  max-width: 611px;
  position: relative;
  .icon {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
  }

  &--field {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $grey5;
    border-radius: 15px;
    padding: 6px 16px;
    input {
      width: 100%;
      border: none;
      padding: 0;
      font-size: 16px;
      line-height: 1;
    }
  }
  &--content {
    position: absolute;
    top: 100%;
    z-index: 2;
    width: 100%;
    border: 1px solid $grey5;
    background-color: $white;
    border-radius: 15px;
    padding: 8px 16px;
    &_ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &_li {
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
