import { STATUS } from "@/constants/banners"
import {
  TIMELINE_ELEMENT_STATUS,
  TIMELINE_ELEMENT_COLORS,
  TIMELINE_TYPES
} from "@/constants/timeline"

export const getElementLabel = (elementName, elementStatus) => {
  switch (elementStatus) {
    case STATUS.PROCESSING:
      return `${elementName} в процесі`
    case STATUS.PLANNED:
      return `${elementName} заплановано`
    case STATUS.COMPLETED:
      return `${elementName} завершено`
    default:
      return "невідомо"
  }
}

export const getBackgroundColor = (status) => {
  switch (status) {
    case STATUS.PROCESSING:
      return TIMELINE_ELEMENT_COLORS.GREEN
    case STATUS.PLANNED:
      return TIMELINE_ELEMENT_COLORS.BLUE
    case STATUS.COMPLETED:
    case TIMELINE_ELEMENT_STATUS.NEW:
      return TIMELINE_ELEMENT_COLORS.GREY
    default:
      return TIMELINE_ELEMENT_COLORS.GREY
  }
}

export const getFormatedStatus = (status) => {
  switch (status) {
    case STATUS.PROCESSING:
      return "в процесі"
    case STATUS.PLANNED:
      return "заплановано"
    case STATUS.COMPLETED:
      return "завершено"
    default:
      return "новий"
  }
}

export const getFormatedStatusColor = (status, type) => {
  if (type && type === TIMELINE_TYPES.PROMOTION) {
    return TIMELINE_ELEMENT_COLORS.GREEN
  }

  switch (status) {
    case STATUS.PROCESSING:
      return TIMELINE_ELEMENT_COLORS.GREEN
    case STATUS.PLANNED:
      return TIMELINE_ELEMENT_COLORS.BLUE
    case STATUS.COMPLETED:
      return TIMELINE_ELEMENT_COLORS.DERK_GREY
    default:
      return TIMELINE_ELEMENT_COLORS.DERK_GREY
  }
}
