<template>
  <div class="image-data">
    <span class="delete-action" @click="onClick()">delete</span>
    <span class="image-name">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  },

  emits: ["click"],
  methods: {
    onClick() {
      this.$emit("click")
    }
  }
}
</script>

<style lang="scss" scoped>
.image-data {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.delete-action {
  font-size: 14px;
  line-height: 16px;
  color: #000;
  opacity: 0.8;
  text-decoration: underline;
  margin-right: 16px;
  cursor: pointer;
}
.image-name {
  font-size: 14px;
  line-height: 16px;
}
</style>
