export const TRADING_POINTS_TYPES_TRANSLATE = {
  CLOSE: "закриття",
  OPEN: "відкриття",
  UPDATE: "оновлення",
  CHANGED: "! змінено",
  NEW: "новий"
}
export const TRADING_POINTS_TYPES = {
  CLOSE: "CLOSE",
  OPEN: "OPEN",
  UPDATE: "UPDATE",
  CHANGED: "CHANGED",
  NEW: "NEW",
  WORKING: "WORKING"
}
