import { mutationTypes } from "./constants"

import { BLOCK_TYPES } from "@/constants/sections"

export default {
  [mutationTypes.CATEGORIES](state, categories) {
    state.categories = categories
  },

  [mutationTypes.PLACARD](state, placard) {
    const mutationToJson = JSON.stringify(placard)
    const clonePlacard = JSON.parse(mutationToJson)

    state.placard = clonePlacard
  },

  [mutationTypes.PLACARD_COPY](state, placard) {
    const mutationToJson = JSON.stringify(placard)
    const clonePlacard = JSON.parse(mutationToJson)

    state.placardCopy = clonePlacard
  },

  [mutationTypes.PLACARD_FILTERS](state, filters) {
    state.filters = filters
  },

  [mutationTypes.PLACARD_FORMS](state, forms) {
    state.forms = forms
  },

  [mutationTypes.PLACARD_FILTERS_COPY](state, filters) {
    state.filtersCopy = filters
  },

  [mutationTypes.PLACARD_FORMS_COPY](state, forms) {
    state.formsCopy = forms
  },

  [mutationTypes.PLACARD_PRODUCTS](state, products) {
    state.products = products
  },

  [mutationTypes.CHECKED_ITEM_FORM](state, itemForm) {
    const idx = state.forms.findIndex((i) => i.id === itemForm.id)

    state.forms[idx].checked = !state.forms[idx].checked
  },

  [mutationTypes.CHECKED_ITEM_FILTER](state, itemFilters) {
    const idx = state.filters.findIndex((i) => i.id === itemFilters.id)

    state.filters[idx].checked = !state.filters[idx].checked
  },

  [mutationTypes.MOVE_ITEM_FORM](state, data) {
    const formItem = state.forms.find((form) => form.id === +data.new)
    const oldFormItem = state.forms.findIndex((form) => form.id === +data.old)
    const oldIdx = state.forms.indexOf(formItem)

    state.forms.splice(oldIdx, 1)
    state.forms.splice(oldFormItem, 0, formItem)
  },

  [mutationTypes.MOVE_ITEM_FILTER](state, data) {
    const filterItem = state.filters.find((filter) => filter.id === +data.new)
    const oldFiltertem = state.filters.findIndex(
      (filter) => filter.id === +data.old
    )
    const oldIdx = state.filters.indexOf(filterItem)

    state.filters.splice(oldIdx, 1)
    state.filters.splice(oldFiltertem, 0, filterItem)
  },

  [mutationTypes.CHANGE_ITEM_FORM_VALIDATE](state, { id, error }) {
    const idx = state.forms.findIndex((form) => form.id === +id)

    state.forms[idx].validate = false
    state.forms[idx].textError = error
  },

  [mutationTypes.CHANGE_ITEM_FILTER_VALIDATE](state, { id, error }) {
    const idx = state.filters.findIndex((filter) => filter.id === +id)

    state.filters[idx].validate = false
    state.filters[idx].textError = error
  },

  [mutationTypes.CHANGE_ITEMS_FORM_VALIDATE](state) {
    state.forms = state.forms.map((el) => ({
      ...el,
      validate: true,
      textError: ""
    }))
  },

  [mutationTypes.CHANGE_ITEMS_FILTER_VALIDATE](state) {
    state.filters = state.filters.map((el) => ({
      ...el,
      validate: true,
      textError: ""
    }))
  },

  [mutationTypes.DRAG_CHILD_ITEM](state, data) {
    const { sectionId, dragItem, dragIdx, dropIdx, newBlockId, oldBlockId } =
      data
    const section = state.placard.find((section) => section.id === +sectionId)
    const oldBlock = section.blocks.find((section) => section.id === oldBlockId)
    const newBlock = section.blocks.find((section) => section.id === newBlockId)

    oldBlock.items.splice(dragIdx, 1)
    newBlock.items.splice(dropIdx, 0, dragItem)
  },
  //end my mutations
  [mutationTypes.ADD_SECTION](state, section) {
    state.placard.push(section)
  },
  [mutationTypes.DELETE_SECTION](state, sectionId) {
    state.placard = state.placard.filter((section) => section?.id !== sectionId)
  },
  [mutationTypes.SELECT_SECTION](state, section) {
    state.selectedSection = section
  },
  [mutationTypes.CHANGE_SECTION_IDX](state, { sectionId, newIdx }) {
    const section = state.placard.find((section) => section.id === +sectionId)
    const oldIdx = state.placard.indexOf(section)

    state.placard.splice(oldIdx, 1)
    state.placard.splice(newIdx, 0, section)
  },
  [mutationTypes.ADD_BLOCK](state, { sectionId, block }) {
    const section = state.placard.find((section) => section.id === sectionId)

    if (block.type === BLOCK_TYPES.EXTRA) {
      section.blocks = [...section.blocks, block]
    } else {
      const blocksWithoutExtra = section.blocks.filter(
        (block) => block.type !== BLOCK_TYPES.EXTRA
      )
      const extraBlock = section.blocks.find(
        (block) => block.type === BLOCK_TYPES.EXTRA
      )

      extraBlock > 0
        ? (section.blocks = [...blocksWithoutExtra, block, extraBlock])
        : section.blocks.push(block)
    }
  },
  [mutationTypes.DELETE_BLOCK](state, { sectionId, blockId }) {
    const section = state.placard.find((section) => section.id === sectionId)

    section.blocks = section.blocks.filter((block) => block.id !== blockId)
  },
  [mutationTypes.CHANGE_BLOCK_IDX](state, { sectionId, blockId, newIdx }) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)
    const oldIdx = section.blocks.indexOf(block)

    section.blocks.splice(oldIdx, 1)
    section.blocks.splice(newIdx, 0, block)
  },
  [mutationTypes.ADD_ITEM_TO_BLOCK](state, { sectionId, blockId, item }) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)

    block.items.push({ ...item, createTime: new Date().getTime() })
  },
  [mutationTypes.SELECT_ITEM](state, { sectionId, blockId, itemId }) {
    if (!sectionId && !blockId && !itemId) {
      state.selectedItem = null
      return
    }

    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)
    const item = block.items.find((item) => item.id === itemId)

    state.selectedItem = item
  },
  [mutationTypes.DELETE_ITEM](
    state,
    { sectionId, blockId, itemId, createTime = null }
  ) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)

    if (createTime)
      block.items = block.items.filter((item) => item.createTime !== createTime)
    else block.items = block.items.filter((item) => item.id !== itemId)
  },
  [mutationTypes.TOGGLE_TEXT_ITEM_BOLD](state, { sectionId, blockId, itemId }) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)
    const item = block.items.find((item) => item.id === itemId)

    item.bold = !item.bold
  },
  [mutationTypes.SET_ITEM_LINK](
    state,
    { sectionId, blockId, itemId, link, createTime = null }
  ) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)

    if (createTime)
      block.items.find((item) => item.createTime === createTime).link = link
    else block.items.find((item) => item.id === itemId).link = link
  },
  [mutationTypes.SET_BANNER_ITEM_IMAGE](
    state,
    { sectionId, blockId, itemId, image }
  ) {
    const section = state.placard.find((section) => section.id === sectionId)
    const block = section.blocks.find((block) => block.id === blockId)
    const item = block.items.find((item) => item.id === itemId)

    item.image = image
  }
}
