<template>
  <div class="page-wrapper">
    <PageTitle class="page-title" title="Банери" />
    <div class="search-input-row">
      <v-search
        class="search"
        v-model="searchTerm"
        :name-store="prevSearchList"
        :list-searches="listSearches()"
      />
      <EmptyFilter :active="sortSwitch" @click="onSort" />
    </div>
    <div v-if="!loading" class="timeline">
      <my-timeline :data="filteredBanners" @onPressElement="openBannerPage" />
    </div>
    <div v-else style="margin: 0 auto">Loading ...</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import EmptyFilter from "@/components/EmptyFilter.vue"
import MyTimeline from "@/components/MyTimeline"
import PageTitle from "@/components/PageTitle.vue"
import { PREV_SEARCH_BANNER } from "@/constants/localStorageKeys.js"
import { TIMELINE_ELEMENT_STATUS } from "@/constants/timeline"
import { getBackgroundColor, getElementLabel } from "@/helpers/elements"
import { getItemlocalStorage } from "@/helpers/storage.js"
import TimelineRow from "@/models/timeline/TimelineRow"
import TimelineSubitem from "@/models/timeline/TimelineSubitem"
import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/banners"

export default {
  components: {
    MyTimeline,
    PageTitle,
    EmptyFilter
  },

  setup() {
    const ROUTES = ROUTE

    return {
      ROUTES
    }
  },

  async mounted() {
    await this.getBanners()
    this.loading = false
  },

  data: () => ({
    loading: true,
    searchTerm: "",
    sortSwitch: false,
    filteredPages: []
  }),

  computed: {
    ...mapGetters(namespace, ["allBanners"]),

    filteredBanners() {
      return this.timlineItems.filter((group) =>
        group.rowLabel.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    },

    timlineItems() {
      const items = this.allBanners.map((item) => {
        const timelineSubItems = this.createSubItems(item)
        const timelineItemData = {
          id: item.id,
          rowLabel: item.name,
          type: item.position,
          subItems: timelineSubItems
        }

        return new TimelineRow(timelineItemData)
      })

      return !this.sortSwitch
        ? items
        : items.sort((a, b) => {
            return a.subItems.length - b.subItems.length
          })
    },

    prevSearchList() {
      return PREV_SEARCH_BANNER
    }
  },

  methods: {
    ...mapActions(namespace, ["getBanners"]),
    onSort() {
      this.sortSwitch = !this.sortSwitch
    },

    createSubItems(item) {
      return item.banners.map((banner) => {
        return new TimelineSubitem({
          id: banner.id,
          label: getElementLabel(banner.counter, banner.status),
          title: `${banner.counter} ${banner.name}`,
          startDate: banner.dateStart,
          endDate: banner.dateEnd,
          status: banner.status,
          backgroundColor: getBackgroundColor(banner.status),
          counter: banner.counter
        })
      })
    },

    openBannerPage({ row, element }) {
      const params = {
        status: element.status,
        parentId: row.id,
        bannerId: element.id,
        title: row.rowLabel,
        counter: element.counter,
        type: row.type
      }

      this.navigateToCreate(params)
    },

    navigateToCreate({ status, parentId, bannerId, title, counter, type }) {
      const isNew = status === TIMELINE_ELEMENT_STATUS.NEW

      this.$router.push({
        name: isNew ? ROUTE.CREATE_BANNER.name : ROUTE.EDIT_BANNER.name,
        params: isNew ? { parentId } : { bannerId },
        query: { title, counter, type }
      })
    },

    listSearches() {
      return (getItemlocalStorage(this.prevSearchList) || []).slice(0, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-title {
  margin-bottom: 40px;
}
.search-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 40px;
}

.search {
  max-width: 611px;
  width: 100%;
}
.timeline {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
