import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "banners"

const state = () => ({
  banners: [],
  banner: null
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
