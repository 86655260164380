import GroudDropdown from "@/models/dropdown/GroupDropdown"
import GroupDropdownItem from "@/models/dropdown/GroupDropdownItem"

export const formatterGrout = (arr) =>
  arr.map(
    (group) =>
      new GroudDropdown(
        group.id,
        group.name,
        group.children.map(
          (item) =>
            new GroupDropdownItem(
              item.id,
              item.name,
              item.id,
              { url: item.image, file: null },
              item.description
            )
        ),
        { url: group.image, file: null }
      )
  )
