export const useDraggable = () => {
  const CLASS_NAME_DRAGGING = "dragging"
  const startDrag = (event, item, isDraggable = false) => {
    if (!isDraggable) return

    event.target.classList.add(CLASS_NAME_DRAGGING)
    event.dataTransfer.dropEffect = "move"
    event.dataTransfer.effectAllowed = "move"
    event.dataTransfer.setData("itemID", item.id)
  }

  const endDrag = (event, item, isDraggable = false, emitName, emit) => {
    if (!isDraggable) return

    event.target.classList.remove(CLASS_NAME_DRAGGING)
    const newItem = event.dataTransfer.getData("itemID")
    emit(emitName, { old: item.id, new: newItem })
  }

  return { startDrag, endDrag }
}
