import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_REVIEWS](state, data) {
    state.reviews = data
  },

  [mutationTypes.SET_CURRENT_REVIEW](state, data) {
    state.currentReview = data
  }
}
