import BannerPosiont from "@/models/banners/BannerPosition"

export const FORMAT = {
  SINGLE: "SINGLE",
  DOUBLE: "DOUBLE",
  TRIPLE: "TRIPLE"
}

// export const STATUS = {
//   ACTIVE: "ACTIVE",
//   SCHEDULED: "SCHEDULED",
//   EXPIRED: "EXPIRED",
//   EMPTY: "EMPTY",
// };

export const STATUS = {
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  PLANNED: "PLANNED"
}

export const PLATFORM = {
  DESKTOP: "desktop",
  MOBILE: "mobile"
}

export const BANNER_FORMAT = [
  { title: "Одинарний", value: 1 },
  { title: "Подвійний", value: 2 },
  { title: "Потрійний", value: 3 }
]

export const STATUS_BANNER = {
  CREATE: "create",
  EDIT: "edit"
}

export const TYPE_BANNER = {
  HEADER: "header",
  BIG_BANNER: "bigBanner",
  PRODUCTS_1: "products_1",
  PRODUCTS_2: "products_2",
  TOP: "top"
}

export const POSITION_BANNER = {
  [TYPE_BANNER.HEADER]: {
    desktop: new BannerPosiont(
      "1920 * 60 px",
      1280,
      40,
      "+ завантажити для web"
    ),
    mobile: new BannerPosiont("600 * 500 px", 378, 40, "+ завантажити для mob")
  },
  [TYPE_BANNER.BIG_BANNER]: {
    desktop: new BannerPosiont(
      "1280 * 500 px",
      756,
      300,
      "+ завантажити для ПК"
    ),
    mobile: new BannerPosiont(
      "1280 * 500 px",
      398,
      300,
      "+ завантажити для mob"
    )
  },
  [TYPE_BANNER.PRODUCTS_1]: {
    desktop: new BannerPosiont(
      "592 * 232 px",
      592,
      232,
      "+ завантажити для ПК"
    ),
    mobile: new BannerPosiont(
      "1280 * 500 px",
      393,
      154,
      "+ завантажити для mob"
    )
  },
  [TYPE_BANNER.PRODUCTS_2]: {
    desktop: new BannerPosiont(
      "508 * 198 px",
      508,
      198,
      "+ завантажити для ПК"
    ),
    mobile: new BannerPosiont(
      "1280 * 500 px",
      393,
      154,
      "+ завантажити для mob"
    )
  },
  [TYPE_BANNER.TOP]: {
    desktop: new BannerPosiont(
      "1049 * 120 px",
      756,
      86,
      "+ завантажити для ПК"
    ),
    mobile: new BannerPosiont("1280 * 500 px", 398, 86, "+ завантажити для mob")
  }
}
