<template>
  <div class="container">
    <span class="date-value">
      {{ date }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  padding: 8px 16px;
  border: 1px solid $grey5;
  border-radius: 17px;
}
.date-value {
  font-size: 14px;
  line-height: 16px;
}
</style>
