<script setup>
import { useRoute } from "vue-router"
import PlusIcon from "./svg/PlusIcon.vue"
import { ROUTE } from "@/router/routeNames"
import { onClickOutside } from "@vueuse/core"
import { ref, reactive, computed } from "vue"
import { readFileAsync } from "@/helpers/files"
import { onHighlight } from "@/helpers/strings"
import { useDraggable } from "@/hooks/useDraggable"
import InfoPopup from "@/pages/ActiveComponents/components/InfoPopup.vue"

const route = useRoute()
const { startDrag, endDrag } = useDraggable()

const emit = defineEmits(["onDragDropItem", "onSelectIcon", "onRemoveIcon"])

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  highlight: {
    type: String,
    default: ""
  },

  noIcon: {
    type: Boolean,
    default: false
  },

  isItemDraggable: {
    type: Boolean,
    default: false
  }
})

const isHover = ref(false)
const iconInput = ref(null)
const infoPopup = ref(null)
const isHoverName = ref(false)
const unchangedNode = ref(null)
const triangleDirection = ref("top")
const modalKeepUnchanged = ref(false)
const paramInfo = reactive({ height: 0, width: 0 })
const position = reactive({ x: 0, y: 0, width: 0, bottom: 0 })

const infoPopupStyle = computed(() => {
  const { height, width } = paramInfo
  const cordY = position.y - height - 4
  const cordX = position.x - width / 2 + position.width / 2

  const style = { position: "fixed", left: `${cordX}px` }

  setTriangleDirection(cordY > 0)

  return cordY > 0
    ? { ...style, top: `${cordY}px` }
    : { ...style, top: `${position.bottom + 4}px` }
})

const isValid = computed(
  () => props.item && "validate" in props.item && !props.item.validate
)
const styles = computed(() => {
  const heightModal = 50
  return {
    top: `${position.y - heightModal}px`,
    left: `${position.x - 32}px`
  }
})
const isPageColors = computed(() => ROUTE.CATALOG_COLORS.path === route.path)

const closeModalKeepUnchanged = () => (modalKeepUnchanged.value = false)
const onRemoveIconHandler = () => emit("onRemoveIcon", props.item.id)
const setTriangleDirection = (bool) =>
  (triangleDirection.value = bool ? "top" : "bottom")
const onSelectIconHandler = async (e) => {
  const file = e.target.files[0]
  if (!file) return
  const url = URL.createObjectURL(file)
  const imgBase64 = await readFileAsync(file)

  emit("onSelectIcon", {
    id: props.item.id,
    imgData: imgBase64,
    icon: { url, file }
  })
}
const setPosition = (event) => {
  const { x, y, width, bottom } = event.target.getBoundingClientRect()
  position.x = x
  position.y = y
  position.width = width
  position.bottom = bottom
  setParamInfoPopup()
}

const openIconInput = () => {
  iconInput.value.value = null
  iconInput.value.click()
}

const setParamInfoPopup = () => {
  const { height, width } = infoPopup.value.$el.getBoundingClientRect()
  paramInfo.width = width
  paramInfo.height = height
}

onClickOutside(unchangedNode, () => closeModalKeepUnchanged())
</script>

<template>
  <div
    class="dropdown-group-item"
    :class="{ 'no-valide': isValid }"
    :draggable="isItemDraggable"
    @dragover.prevent
    @dragenter.prevent
    @dragstart="startDrag($event, item, isItemDraggable)"
    @drop="endDrag($event, item, isItemDraggable, 'onDragDropItem', emit)"
  >
    <input ref="iconInput" type="file" hidden @change="onSelectIconHandler" />
    <div
      class="dropdown-group-item-row"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    >
      <div class="item-name-wrapper">
        <div
          ref="unchangedNode"
          class="item-name-modal"
          v-if="modalKeepUnchanged && isValid"
          :style="styles"
        >
          <v-checkbox
            label="зберегти без змін"
            :value="!!item.validate"
            @change="$emit('changeValid', $event), closeModalKeepUnchanged"
          />
        </div>
        <span
          ref="itemName"
          class="item-name"
          v-html="onHighlight(item.name, highlight)"
          @click="modalKeepUnchanged = !modalKeepUnchanged"
          @mouseover="isHoverName = true"
          @mouseout="isHoverName = false"
          @mouseenter="setPosition"
        ></span>
        <InfoPopup
          v-show="isHoverName && item.description"
          ref="infoPopup"
          class="detailed-info-popup"
          :item="item"
          :triangle-direction="triangleDirection"
          :style="infoPopupStyle"
        />
      </div>
      <template v-if="!noIcon">
        <img
          v-if="item.icon?.url"
          class="icon"
          :class="[{ 'icon-color': isPageColors }]"
          :src="item.icon.url"
        />
        <div
          v-if="isHover && !item.icon?.url"
          class="plus-icon-wrapper"
          @click="openIconInput"
        >
          <PlusIcon class="plus-icon-wrapper--icon" color="#000" />
        </div>
        <span
          v-if="isHover && item.icon?.url"
          class="delete"
          @click="onRemoveIconHandler"
          >delete</span
        >
      </template>
    </div>
  </div>
</template>

<!-- <script>
// import PlusIcon from "./svg/PlusIcon.vue"

// import { readFileAsync } from "@/helpers/files"
// import { onHighlight } from "@/helpers/strings"
// import DraggableMixin from "@/mixins/draggable.mixin"
// import InfoPopup from "@/pages/ActiveComponents/components/InfoPopup.vue"
// import { ROUTE } from "@/router/routeNames"

// export default {
//   mixins: [DraggableMixin],
//   emits: ["onDragDropItem", "onSelectIcon", "onRemoveIcon"],

//   props: {
//     item: {
//       type: Object,
//       required: true
//     },

//     highlight: {
//       type: String,
//       default: ""
//     },

//     noIcon: {
//       type: Boolean,
//       default: false
//     },

//     isItemDraggable: {
//       type: Boolean,
//       default: false
//     }
//   },

//   components: {
//     PlusIcon,
//     InfoPopup
//   },

//   data: () => ({
//     isHover: false,
//     isHoverName: false,
//     modalKeepUnchanged: false,
//     position: { x: 0, y: 0, width: 0, bottom: 0 },
//     paramInfo: { height: 0, width: 0 },
//     triangleDirection: "top"
//   }),

//   computed: {
//     infoPopupStyle() {
//       const { height, width } = this.paramInfo
//       const cordY = this.position.y - height - 4
//       const cordX = this.position.x - width / 2 + this.position.width / 2
//       const style = {
//         position: "fixed",
//         left: `${cordX}px`
//       }

//       this.setTriangleDirection(cordY > 0)

//       return cordY > 0
//         ? {
//             ...style,
//             top: `${cordY}px`
//           }
//         : {
//             ...style,
//             top: `${this.position.bottom + 4}px`
//           }
//     },

//     isValid() {
//       // eslint-disable-next-line
//       return this.item?.hasOwnProperty("validate") && !this.item.validate
//     },

//     styles() {
//       const heightModal = 50

//       return {
//         top: `${this.position.y - heightModal}px`,
//         left: `${this.position.x - 32}px`
//         // visibility: this.showModal ? "visible" : "hidden",
//       }
//     },

//     isPageColors() {
//       return ROUTE.CATALOG_COLORS.path === this.$route.path
//     }
//   },

//   methods: {
//     onHighlight,
//     closeModalKeepUnchanged() {
//       this.modalKeepUnchanged = false
//     },

//     openIconInput() {
//       this.$refs.iconInput.value = null
//       this.$refs.iconInput.click()
//     },

//     async onSelectIconHandler(e) {
//       const file = e.target.files[0]

//       if (!file) return

//       const url = URL.createObjectURL(file)
//       const icon = {
//         url,
//         file
//       }
//       const imgBase64 = await readFileAsync(file)

//       this.$emit("onSelectIcon", {
//         id: this.item.id,
//         imgData: imgBase64,
//         icon: icon
//       })
//     },

//     onRemoveIconHandler() {
//       this.$emit("onRemoveIcon", this.item.id)
//     },

//     setPosition(event) {
//       const { x, y, width, bottom } = event.target.getBoundingClientRect()

//       this.position = { x: x, y: y, width: width, bottom: bottom }
//       this.setParamInfoPopup()
//     },

//     setParamInfoPopup() {
//       const { height, width } = this.$refs.infoPopup.$el.getBoundingClientRect()

//       this.paramInfo = {
//         height: height,
//         width: width
//       }
//     },

//     setTriangleDirection(bool) {
//       this.triangleDirection = bool ? "top" : "bottom"
//     }
//   }
// }
// </script> -->

<style lang="scss" scoped>
.dropdown-group-item {
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  background-color: $nav-color;
  padding: 0 64px;

  &.no-valide {
    background-color: $pink;

    &:hover {
      .item-name {
        color: $light-red;
      }
    }
  }

  &-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      .item-name {
        text-decoration: underline;
      }
    }
  }
}

.item-name-wrapper {
  max-width: calc(486px - 64px);
  width: 100%;
}

.item-name {
  font-size: 14px;
  line-height: 16px;
}

.item-name-modal {
  position: absolute;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
}

.delete {
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
}

.icon {
  min-width: 16px;
  height: 16px;
  &.icon-color {
    width: 37px;
    height: 37px;
    border-radius: 6px;
  }
}

.plus-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 4px;

  &--icon {
    width: 10px;
    height: 10px;
  }
}

.detailed-info-popup {
  z-index: 30;
}
</style>
