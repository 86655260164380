<template>
  <AppBlock
    :type="type"
    :dropdownItems="list"
    :disabled="disabled"
    @onSelectDropdownItem="onSelectDropdownItem"
    @onDelete="$emit('onDelete')"
  >
    <template #items>
      <div ref="items" class="items-container">
        <div v-for="(item, idx) in items" :key="item.id">
          <AppImageItem
            v-if="item.type === ITEM_TYPE.IMAGE"
            class="item"
            :item="item"
            :disabled="disabled"
            @onSelectImage="(image) => onSelectImage(image, item.id)"
            @onChangeLink="(link) => onChangeLink(link, item.id)"
          />
          <AppTextItem
            v-else
            :idx="idx"
            :item="item"
            :sectionId="sectionId"
            :blockId="id"
            :itemsRef="itemsRef"
            :disabled="disabled"
            :active="selectedItem?.id === item.id"
            @selectItem="$emit('selectItem', $event)"
            @selectDisabledItem="$emit('selectDisabledItem')"
          />
        </div>
      </div>
    </template>
  </AppBlock>
</template>

<script>
import { mapMutations } from "vuex"

import AppImageItem from "../items/AppImageItem.vue"
import AppTextItem from "../items/AppTextItem.vue"

import AppBlock from "./AppBlock.vue"

import { ITEM_TYPES } from "@/constants/sections"
import TextItem from "@/models/sections/items/TextItem"
import { namespace } from "@/store/modules/sectionsAndForms"
import { mutationTypes } from "@/store/modules/sectionsAndForms/constants"

export default {
  props: {
    id: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    sectionId: {
      type: [String, Number],
      required: true
    },

    items: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      required: true,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    selectedItem: {
      type: Object,
      default: () => {}
    }
  },

  emits: ["onAddItem", "onDelete", "selectItem", "selectDisabledItem"],

  components: {
    AppBlock,
    AppImageItem,
    AppTextItem
  },

  setup() {
    const ITEM_TYPE = ITEM_TYPES

    return {
      ITEM_TYPE
    }
  },

  mounted() {
    this.itemsRef = this.$refs.items
  },

  data: () => ({
    itemsRef: {}
  }),

  methods: {
    onSelectDropdownItem(item) {
      // тому, що для блокі може міститися лише картинка(по замовчуванні) і одна категорія
      if (this.items.length <= 1) {
        this.$emit(
          "onAddItem",
          new TextItem(item.id, item.label, item.parent_id, item.bold)
        )
      }
    },

    onSelectImage(image, itemId) {
      this.addImageToBannerItem({
        sectionId: this.sectionId,
        blockId: this.id,
        itemId,
        image
      })
    },

    onChangeLink(link, itemId) {
      this.addLinkToItem({
        sectionId: this.sectionId,
        blockId: this.id,
        itemId,
        link
      })
    },

    ...mapMutations(namespace, {
      addImageToBannerItem: mutationTypes.SET_BANNER_ITEM_IMAGE,
      addLinkToItem: mutationTypes.SET_ITEM_LINK
    })
  }
}
</script>

<style lang="scss" scoped>
.items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  :deep .inner-block {
    justify-content: center;
    .app-text-item--label {
      color: $black;
    }
  }
}

.item {
  margin-bottom: 12px;
}
</style>
