import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/bigbords"

export default {
  banners() {
    return sendSecureRequest().get(`${ENDPOINT}`)
  },

  currentBanner(id) {
    return sendSecureRequest().get(`${ENDPOINT}/${id}`)
  },

  createBanner(data) {
    return sendSecureRequest().post(`${ENDPOINT}`, data)
  },

  updateBanner(data) {
    return sendSecureRequest().put(`${ENDPOINT}`, data)
  }
}
