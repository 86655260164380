import ActiveComponents from "../pages/ActiveComponents"
import CurrentBanner from "../pages/Banner"
import Banners from "../pages/Banners"
import Brands from "../pages/Brands"
import CatalogColors from "../pages/CatalogColors"
import CatalogEffects from "../pages/CatalogEffects"
import CatalogFilter from "../pages/CatalogFilter"
import Brand from "../pages/CreateBrand"
import CreatePromotion from "../pages/CreatePromotion"
import Documents from "../pages/Documents"
import Leavings from "../pages/Leavings"
import Login from "../pages/Login"
import Main from "../pages/Main"
import Orders from "../pages/Orders"
import OrderInfo from "../pages/OrderInfo"
import Promotions from "../pages/Promotions"
import Reviews from "../pages/Reviews"
import SectionsAndForms from "../pages/SectionsAndForms"
import TradingPoints from "../pages/TradingPoints"

import { ROUTE } from "./routeNames"

import { STATUS_BANNER } from "@/constants/banners"

const routes = [
  {
    path: ROUTE.MAIN.path,
    name: ROUTE.MAIN.name,
    component: Main
  },
  {
    path: ROUTE.LOGIN.path,
    name: ROUTE.LOGIN.name,
    component: Login,
    meta: {
      layout: "auth"
    }
  },
  {
    path: ROUTE.BANNERS.path,
    name: ROUTE.BANNERS.name,
    component: Banners
  },
  {
    path: ROUTE.EDIT_BANNER.path,
    name: ROUTE.EDIT_BANNER.name,
    meta: { status: STATUS_BANNER.EDIT },
    component: CurrentBanner
  },
  {
    path: ROUTE.CREATE_BANNER.path,
    name: ROUTE.CREATE_BANNER.name,
    meta: { status: STATUS_BANNER.CREATE },
    component: CurrentBanner
  },
  {
    path: ROUTE.PROMOTIONS.path,
    name: ROUTE.PROMOTIONS.name,
    component: Promotions
  },
  {
    path: ROUTE.CREATE_PROMOTION.path,
    name: ROUTE.CREATE_PROMOTION.name,
    component: CreatePromotion
  },
  {
    path: ROUTE.BRANDS.path,
    name: ROUTE.BRANDS.name,
    component: Brands
  },
  {
    path: ROUTE.CREATE_BRAND.path,
    name: ROUTE.CREATE_BRAND.name,
    meta: { status: STATUS_BANNER.CREATE },
    component: Brand
  },
  {
    path: ROUTE.EDIT_BRAND.path,
    name: ROUTE.EDIT_BRAND.name,
    meta: { status: STATUS_BANNER.EDIT },
    component: Brand
  },
  {
    path: ROUTE.SECTIONS_AND_FORMS.path,
    name: ROUTE.SECTIONS_AND_FORMS.name,
    component: SectionsAndForms
  },
  {
    path: ROUTE.ACTIVE_COMPONENTS.path,
    name: ROUTE.ACTIVE_COMPONENTS.name,
    component: ActiveComponents
  },
  {
    path: ROUTE.CATALOG_FILTER.path,
    name: ROUTE.CATALOG_FILTER.name,
    component: CatalogFilter
  },
  {
    path: ROUTE.CATALOG_COLORS.path,
    name: ROUTE.CATALOG_COLORS.name,
    component: CatalogColors
  },
  {
    path: ROUTE.CATALOG_EFFECTS.path,
    name: ROUTE.CATALOG_EFFECTS.name,
    component: CatalogEffects
  },
  {
    path: ROUTE.LEAVINGS.path,
    name: ROUTE.LEAVINGS.name,
    component: Leavings
  },
  {
    path: ROUTE.DOCUMENTS.path,
    name: ROUTE.DOCUMENTS.name,
    component: Documents
  },
  {
    path: ROUTE.ORDERS.path,
    name: ROUTE.ORDERS.name,
    component: Orders
  },
  {
    path: ROUTE.ORDERS_INFO.path,
    name: ROUTE.ORDERS_INFO.name,
    component: OrderInfo
  },
  {
    path: ROUTE.TRADING_POINTS.path,
    name: ROUTE.TRADING_POINTS.name,
    component: TradingPoints
  },
  {
    path: ROUTE.REVIEWS.path,
    name: ROUTE.REVIEWS.name,
    component: Reviews
  }
]

export default routes
