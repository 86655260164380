export const truncateString = (str, num) => {
  return str.length > num ? str.slice(0, num) + "..." : str
}

export const onHighlight = (text, search) => {
  let startSymbol = text.toUpperCase().indexOf(search.toUpperCase())
  const partHightLine = `<span style="background-color: yellow">${text.slice(
    startSymbol,
    startSymbol + search.length
  )}</span>`

  return startSymbol >= 0
    ? `${text.substring(0, startSymbol)}${partHightLine}${text.substring(
        startSymbol + search.length,
        text.length
      )}`
    : text
}

export const isValidUrl = (url) => {
  // eslint-disable-next-line
  const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

  return matcher.test(url)
}

export const alphabetOrdering = (arr, keyName = "name") => {
  return arr.sort((a, b) => a[keyName].localeCompare(b[keyName]))
}

let timer

export const debounce = (func, timeout) => {
  return (function (...arg) {
    const funcAction = async () => {
      await func.apply(this, arg)
    }

    clearTimeout(timer)
    timer = setTimeout(funcAction, timeout)
  })()
}
