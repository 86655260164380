<template>
  <div class="v-drop-down" @click="onToggle" v-click-outside="onClose">
    <div class="v-drop-down--head">
      <slot name="toggler">
        <span>Toggle</span>
      </slot>
      <icon-arrow-up class="arrow-up" :class="[{ rotate: toggle }]" />
    </div>

    <div v-show="toggle" class="v-drop-down--content">
      <div
        v-for="item of items"
        :key="item.value"
        :class="{ active: item.id === value?.id }"
        class="v-drop-down--content_item"
        @click="onSelect(item)"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowUp from "@/components/svg/ArrowUpIcon.vue"

export default {
  name: "v-drop-down",
  components: {
    IconArrowUp
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    value: {
      type: [String, Object, Number],
      default: ""
    }
  },

  emits: ["onSelect"],
  data() {
    return {
      toggle: false
    }
  },

  methods: {
    onSelect(item) {
      this.$emit("onSelect", item)
    },

    onClose() {
      this.toggle = false
    },

    onToggle() {
      this.toggle = !this.toggle
    }
  }
}
</script>

<style lang="scss" scoped>
.v-drop-down {
  position: relative;
  width: 100%;

  &--head {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    :deep span {
      white-space: nowrap;
    }
  }

  &--content {
    position: absolute;
    left: calc(100% - 14px);
    top: 100%;
    z-index: 1;
    margin-top: 8px;
    padding: 8px 0;
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &_item {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400px;
      color: $black;
      padding: 10px 16px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.arrow-up {
  transition: transform 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
}
</style>
