import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/placards"
const ENDPOINT_CATEGORY = `${ENDPOINT}-category`
const ENDPOINT_EXTRA = `${ENDPOINT}-extra`
const ENDPOINT_FILTERS = `${ENDPOINT}-filters`
const ENDPOINT_PRODUCTS = `${ENDPOINT}-products`
const ENDPOINT_PRODUCTS_VALIDATE = `${ENDPOINT_PRODUCTS}-validate`
const UPLOAD_IMAGE = `admin/upload-image`

export default {
  categories() {
    return sendSecureRequest().get(ENDPOINT_CATEGORY)
  },

  categoryChildren(ID) {
    return sendSecureRequest().get(`${ENDPOINT_CATEGORY}/${ID}`)
  },

  categoryExtraBlock(ID) {
    return sendSecureRequest().get(`${ENDPOINT_EXTRA}/${ID}`)
  },

  placards() {
    return sendSecureRequest().get(ENDPOINT)
  },

  updatePlacards(dataPlacard) {
    return sendSecureRequest().post(ENDPOINT, dataPlacard)
  },

  placardFilters(ID) {
    return sendSecureRequest().get(`${ENDPOINT_FILTERS}/${ID}`)
  },

  updatePlacardFilters(ID, data) {
    return sendSecureRequest().post(`${ENDPOINT_FILTERS}/${ID}`, data)
  },

  placardsProductsByFilter({
    categoryId,
    filterId,
    limit,
    offset,
    onlyFilter
  }) {
    return sendSecureRequest().get(
      `${ENDPOINT_PRODUCTS}/${categoryId}/${filterId}`,
      { params: { limit, offset, onlyFilter } }
    )
  },

  placardsProductsValidate({ categoryId, filterId }) {
    return sendSecureRequest().get(
      `${ENDPOINT_PRODUCTS_VALIDATE}/${categoryId}/${filterId}`
    )
  },

  uploadImage(image) {
    return sendSecureRequest().post(UPLOAD_IMAGE, image)
  }
}
