import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_BANNERS]: (state, banners) => {
    const getFormatDateBanners = (banners) =>
      banners.map((banner) => {
        const formatDateBanners = banner.banners.map((item) => ({
          ...item,
          dateStart: new Date(item.dateStart).getTime(),
          dateEnd: new Date(item.dateEnd).getTime()
        }))

        return { ...banner, banners: formatDateBanners }
      })

    state.banners = getFormatDateBanners(banners)
  },

  [mutationTypes.SET_BANNER]: (state, bannerInfo) => {
    state.banner = bannerInfo
  },

  [mutationTypes.ADD_BANNER]: (state, date) => {
    const idx = state.banners.findIndex((el) => el.id === +date.groupId)

    state.banners[idx].banners = [...state.banners[idx].banners, date]
  }
}
