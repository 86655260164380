class TextItem {
  constructor(
    id,
    label,
    parent_id,
    bold = false,
    link = "",
    canChangeBold = true,
    validate = true
  ) {
    this.id = id
    this.label = label
    this.parent_id = parent_id
    this.bold = bold
    this.link = link
    this.canChangeBold = canChangeBold
    this.validate = validate
  }
}

export default TextItem
