<template>
  <div class="input-image" @click="onClick()">
    <form @submit.prevent class="input-image--form">
      <img v-show="url" :src="url" alt="Image" class="input-image--img" />
      <input
        ref="inputPicture"
        hidden
        type="file"
        @change="createImage($event)"
      />
    </form>
  </div>
</template>

<script>
import { readFileAsync } from "@/helpers/files"

export default {
  name: "input-image",
  props: {
    url: {
      type: String,
      default: ""
    }
  },

  emits: ["click", "createImage"],
  methods: {
    onClick() {
      this.$emit("click")
    },

    openDirectory() {
      this.$refs.inputPicture.click()
    },

    resetField() {
      this.$refs.inputPicture.form.reset()
    },

    async createImage(event) {
      const file = event.target.files[0]
      const url = URL.createObjectURL(file)
      const imgBase64 = await readFileAsync(file)

      const image = {
        name: file.name,
        imgData: imgBase64,
        url
      }

      this.$emit("createImage", image)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-image {
  &--form {
    width: 100%;
  }
  &--img {
    width: 100%;
    height: 100%;
  }
}
</style>
