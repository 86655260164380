<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3755 10.3755C10.6294 10.1216 11.041 10.1216 11.2949 10.3755L15.809 14.89C16.0629 15.1438 16.0629 15.5555 15.809 15.8094C15.5551 16.0633 15.1435 16.0633 14.8896 15.8094L10.3755 11.2949C10.1216 11.0411 10.1216 10.6294 10.3755 10.3755Z"
      fill="#666666"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.50119 11.7021C9.3736 11.7021 11.7021 9.3736 11.7021 6.50119C11.7021 3.62879 9.3736 1.30024 6.50119 1.30024C3.62879 1.30024 1.30024 3.62879 1.30024 6.50119C1.30024 9.3736 3.62879 11.7021 6.50119 11.7021ZM6.50119 13.0024C10.0917 13.0024 13.0024 10.0917 13.0024 6.50119C13.0024 2.91068 10.0917 0 6.50119 0C2.91068 0 0 2.91068 0 6.50119C0 10.0917 2.91068 13.0024 6.50119 13.0024Z"
      fill="#666666"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
