<template>
  <div
    class="timeline-element"
    :style="{ background: backgroundColor, width: width + 'px' }"
    @mouseover="isHover = true"
    @mouseout="isHover = false"
  >
    <div
      v-if="data.status !== TIMELINE_ELEMENT_STATUS.NEW && isHover"
      class="timeline-element--info"
    >
      <span v-if="data.code" class="timeline-element--info-text">
        {{ data.code }}
      </span>
      <span class="timeline-element--info-text text-dots">
        {{ data.title }}
      </span>
      <span class="timeline-element--info-text">
        Показ з {{ startDate }} по {{ endDate }}
      </span>
      <div class="status-row">
        <span
          class="timeline-element--info-text"
          :style="{ color: getFormatedStatusColor(data.status, type) }"
        >
          {{ getFormatedStatus(data.status) }}
        </span>
        <span
          v-if="data?.isEdited"
          class="timeline-element--info-text edited-status"
          >змінено
        </span>
      </div>
    </div>
    <span class="timeline-element--label">{{ data.label }}</span>
  </div>
</template>

<script>
import { DATE_FORMATS } from "@/constants/date"
import { TIMELINE_ELEMENT_STATUS } from "@/constants/timeline"
import { formatDateLocal } from "@/helpers/date"
import { getFormatedStatus, getFormatedStatusColor } from "@/helpers/elements"

export default {
  props: ["data", "backgroundColor", "width", "type"],

  setup() {
    return {
      TIMELINE_ELEMENT_STATUS
    }
  },

  computed: {
    startDate() {
      return formatDateLocal(
        this.data.startDate,
        this.setMask(this.data.startDate)
      )
    },

    endDate() {
      return formatDateLocal(this.data.endDate, this.setMask(this.data.endDate))
    }
  },

  data: () => ({
    isHover: false
  }),

  methods: {
    getFormatedStatus,
    getFormatedStatusColor,
    setMask(data) {
      if (this.type === "promotion") return DATE_FORMATS.DDMM

      return new Date(data).getFullYear() === new Date(Date.now()).getFullYear()
        ? DATE_FORMATS.DDMMHHMM
        : DATE_FORMATS.DDMMYYHHMM
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-element {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  &--info {
    position: absolute;
    min-width: 216px;
    bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 1px 3px 7px 3px rgba(0, 0, 0, 0.33);
    z-index: 3;
    width: 216px;

    &-text {
      width: calc(216px - 32px);
      line-height: 24px;
      &.text-dots {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &--label {
    font-size: 14px;
    line-height: 16px;
    z-index: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
    overflow: hidden;
    text-align: center;
    padding: 0 2px;
  }
}

.status-row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  span {
    width: auto;
  }
}

.edited-status {
  color: $light-red;
}
</style>
