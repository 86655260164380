import { mutationTypes } from "./constants"

import api from "@/api"

const { balance, balanceShop } = api.leavings

export default {
  async getBalance({ commit }, { page = 1, searchPhrase = "" }) {
    const { data } = await balance(page, searchPhrase)

    commit(mutationTypes.SET_BALANCE, data)
  },

  async shopBalance({ commit }, { id, page = 1, searchPhrase = "" }) {
    const { data } = await balanceShop({ id, page, searchPhrase })

    commit(mutationTypes.SET_BALANCE, data)
  }
}
