<template>
  <div class="v-drop-down" v-click-outside="onClose">
    <div class="v-drop-down--head" @click="onToggle">
      <slot name="toggler">
        <span>Toggle</span>
      </slot>
      <icon-arrow-up class="arrow-up" :class="[{ rotate: !toggle }]" />
    </div>

    <div v-show="toggle" class="v-drop-down--content">
      <slot name="content" :events="{ onToggle }">
        <span>content</span>
      </slot>
    </div>
  </div>
</template>

<script>
import IconArrowUp from "@/components/svg/ArrowUpIcon.vue"

export default {
  name: "v-drop-down-slots",
  components: {
    IconArrowUp
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onSelect"],
  data() {
    return {
      toggle: false
    }
  },

  methods: {
    onSelect(item) {
      this.$emit("onSelect", item)
    },

    onClose() {
      this.toggle = false
    },

    onToggle() {
      if (!this.disabled) {
        this.toggle = !this.toggle
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-drop-down {
  position: relative;
  width: fit-content;

  &--head {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    :deep span {
      white-space: nowrap;
    }
  }

  &--content {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    overflow: hidden;
    margin-top: 8px;
    padding: 8px 0;
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &_item {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400px;
      color: $black;
      padding: 10px 16px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.arrow-up {
  transition: transform 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
}
</style>
