<template>
  <div class="shop-edit">
    <div class="shop-edit__top">
      <div class="shop-edit__status status">
        <span>статус</span>
        <div
          class="status__value"
          @click="isEditing && (isShowDropdown = !isShowDropdown)"
        >
          {{ getTypePoint(shop.status) }}
          <DropDown
            v-if="isShowDropdown"
            class="status__dropDown"
            v-click-outside="() => (isShowDropdown = false)"
            :items="getStatusOptions"
            @onPressItem="selectStatus($event)"
          />
          <span class="status__icon">
            <ArrowRightIcon
              v-if="!shop.status"
              class="icon"
              :class="{ 'icon--open': isShowDropdown }"
            />
            <CloseIcon
              v-else-if="shop.status"
              class="icon icon--cross"
              @click.stop="removeStatus()"
            />
          </span>
        </div>
      </div>
      <div class="shop-edit__date">
        <span :class="{ disabledElement: !isActiveStartDate }">з</span>
        <v-date-picker
          is24hr
          mode="dateTime"
          v-model="shop.date.date_start"
          :locale="{ id: 'uk', masks: { weekdays: 'WW' } }"
          :minDate="Date.now()"
          :maxDate="dayjs().add(2, 'month').toDate()"
        >
          <template v-slot="{ togglePopover }">
            <span
              class="date-select"
              :class="{ disabledElement: !isActiveStartDate }"
              @click="
                isEditing && isActiveStartDate && isTouchDate(togglePopover)
              "
            >
              {{ cutDate(shop.date.date_start) }}
            </span>
          </template>
        </v-date-picker>
        <span :class="{ disabledElement: !isActiveEndDate }">по</span>
        <v-date-picker
          is24hr
          mode="dateTime"
          v-model="shop.date.date_end"
          :locale="{ id: 'uk', masks: { weekdays: 'WW' } }"
          :minDate="shop.date.date_start"
          :maxDate="dayjs().add(2, 'month').toDate()"
        >
          <template v-slot="{ togglePopover }">
            <span
              class="date-select"
              :class="{ disabledElement: !isActiveEndDate }"
              @click="
                isEditing && isActiveEndDate && isTouchDate(togglePopover)
              "
            >
              {{ cutDate(shop.date.date_end) }}
            </span>
          </template>
        </v-date-picker>
        <div v-if="isDatesNotValid" class="validate">
          некоректна дата показу
        </div>
      </div>
      <div class="shop-edit__changes-allow" v-if="item.changed">
        <span>! змінено</span>
        <AppCheckbox
          class="checkBox"
          :checked="!shop.changed"
          label="підтвердити зміни"
          @onPress="isEditing && (shop.changed = !shop.changed)"
        />
      </div>
    </div>
    <div class="shop-edit__btn">
      <v-btn
        class="btn"
        v-if="!isEditing"
        :label="'ЗМІНИТИ'"
        @click="onEditing()"
      />
      <v-btn
        class="btn"
        :class="{
          'btn--warning': saveWarning,
          'btn--disabled': isDatesNotValid
        }"
        v-else-if="isEditing"
        :disabled="disabledUpdate && isDatesNotValid"
        :label="'ЗБЕРЕГТИ'"
        @click="onUpdate()"
      />
      <v-btn v-if="isEditing" label="ЗАКРИТИ" @click="onClose()" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { mapGetters, mapActions, mapMutations } from "vuex"

import AppCheckbox from "@/components/AppCheckbox.vue"
import DropDown from "@/components/DropDown.vue"
import ArrowRightIcon from "@/components/svg/ArrowRightIcon.vue"
import CloseIcon from "@/components/svg/CloseIcon.vue"
import { PREV_TRADING_POINTS_BRANDS } from "@/constants/localStorageKeys.js"
import {
  TRADING_POINTS_TYPES_TRANSLATE,
  TRADING_POINTS_TYPES
} from "@/constants/tradingPointsStatus.js"
import { formatDateLocal } from "@/helpers/date"
import { mutationTypes } from "@/store/modules/orders/constants"
import { namespace } from "@/store/modules/tradingPoints"

const statusOptions = [
  { status: TRADING_POINTS_TYPES.CLOSE, label: "закриття", id: 1 },
  { status: TRADING_POINTS_TYPES.UPDATE, label: "оновлення", id: 2 },
  { status: TRADING_POINTS_TYPES.OPEN, label: "відкриття", id: 3 }
]

const responseDateFormat = "YYYY-MM-DD"

export default {
  name: "TradingPoints",
  components: {
    DropDown,
    AppCheckbox,
    ArrowRightIcon,
    CloseIcon
  },

  created() {
    this.setDataToStart()
  },

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },

    saveWarning: {
      type: Boolean,
      default: false
    },

    isEditingPossible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showDetails: false,
    isShowDropdown: false,
    shop: {},
    isEditing: false
  }),

  computed: {
    ...mapGetters({
      tradingPoints: `${namespace}/tradingPoints`,
      tradingPointsList: `${namespace}/tradingPointsList`
    }),

    prevSearchList() {
      return PREV_TRADING_POINTS_BRANDS
    },

    getStatusOptions() {
      return statusOptions
    },

    isActiveStartDate() {
      return (
        !!this.shop.status &&
        this.shop.status !== TRADING_POINTS_TYPES.WORKING &&
        this.shop.status !== TRADING_POINTS_TYPES.NEW
      )
    },

    isActiveEndDate() {
      return this.shop.status === TRADING_POINTS_TYPES.UPDATE
    },

    isDatesNotValid() {
      if (this.shop.status === TRADING_POINTS_TYPES.UPDATE)
        return this.isDatesNotValidUpdate

      if (
        this.shop.status &&
        this.shop.status !== TRADING_POINTS_TYPES.WORKING &&
        this.shop.status !== TRADING_POINTS_TYPES.NEW &&
        !this.shop.date.date_start
      )
        return true

      return false
    },

    isDatesNotValidUpdate() {
      if (!this.shop.date.date_start || !this.shop.date.date_end) return true

      return (
        dayjs(this.shop.date.date_start).valueOf() >
        dayjs(this.shop.date.date_end).valueOf()
      )
    }
  },

  methods: {
    ...mapActions({
      getShopFull: `${namespace}/getShopFull`,
      editShopFullElement: `${namespace}/editShopFullElement`
    }),

    ...mapMutations(namespace, {
      changeDetailsStatus: mutationTypes.CHANGE_DETAILS_STATUS
    }),

    dayjs,
    setDataToStart() {
      this.shop = JSON.parse(JSON.stringify(this.item))
      this.shop.date.date_start = dayjs(
        this.shop.date.date_start,
        responseDateFormat
      ).valueOf()
      this.shop.date.date_end = dayjs(
        this.shop.date.date_end,
        responseDateFormat
      ).valueOf()
      responseDateFormat
    },

    isTouchDate(fn) {
      this.touchDate = true
      fn()
    },

    cutDate(text) {
      return text ? dayjs(text).format("DD.MM") : ""
    },

    getTypePoint(type) {
      return TRADING_POINTS_TYPES_TRANSLATE[type] || ""
    },

    selectStatus(status) {
      this.shop.status = status.status
    },

    removeStatus() {
      if (!this.isEditing) return

      this.shop.status = ""
      this.isShowDropdown = false
    },

    onEditing() {
      if (!this.isEditingPossible) {
        this.$emit("setSaveWarning")
        return
      }

      this.isEditing = true
      this.$emit("editingAllow", { status: true, id: this.shop.id })
    },

    onClose() {
      this.isEditing = false
      this.setDataToStart()
      this.allowEdit()
    },

    async onUpdate() {
      this.isEditing = false
      await this.editShopFullElement({
        id: this.shop.id,
        shopEdited: this.shop
      })
      this.setDataToStart()
      this.allowEdit()
    },

    allowEdit() {
      this.$emit("editingAllow", { status: false, id: null })
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-edit {
  padding-top: 20px;
  padding-left: 160px;
  padding-bottom: 25px;

  &__top {
    display: flex;
  }

  &__status {
    display: flex;

    &.icon {
      position: absolute;
      right: 3px;
      top: 1px;
      transform: rotate(90deg);

      :deep path {
        fill: $grey;
      }

      &--open {
        transform: rotate(-90deg);
      }

      &--cross {
        display: none;
      }
    }
  }
  .status {
    &__value {
      position: relative;
      width: 136px;
      height: 26px;
      text-align: center;
      // border-bottom: 1px solid $grey;
      padding-bottom: 12px;
      margin-left: 25px;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 21px;
        right: 0;
        width: 100%;
        background-color: #000;
        height: 1px;
      }

      &:hover .icon--cross {
        display: block;
      }
    }

    &__dropDown {
      position: absolute;
      width: 136px;
      top: 26px;
      padding-top: 13px;
      z-index: 1;
      padding-bottom: 15px;

      :deep .dropdown--item {
        padding: 10px 16px;
      }
    }
  }

  &__date {
    display: flex;
    gap: 16px;
    margin-left: 56px;
    margin-top: 3px;
    position: relative;

    .date-select {
      display: block;
      height: 24px;
      width: 68px;
      padding: 4px 15px;
      margin-top: -4px;
      border: 1px solid $grey4;
      border-radius: 17px;
      cursor: pointer;
    }

    .validate {
      position: absolute;
      top: 25px;
      left: 0;
      color: $red;
    }
  }

  &__changes-allow {
    display: flex;
    margin-left: 57px;
    margin-top: 3px;

    & span {
      color: $red;
    }

    .checkBox {
      margin-left: 15px;
      transform: translateY(-5px);

      :deep {
        .app-checkbox--button {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }

        .app-checkbox--label:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__btn {
    margin-top: 28px;

    .btn {
      width: 128px;
      margin-right: 24px;

      &--warning {
        background-color: $red;
      }

      &--disabled {
        background-color: $grey5;
      }
    }
  }
}

.disabledElement {
  color: $grey4;
  border-color: $grey4;
}
</style>
