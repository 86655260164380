<script setup>
import { ref, reactive } from "vue"
import { onClickOutside } from "@vueuse/core"

const contextNode = ref(null)
const isContextMenuVisible = ref(false)
const contextMenuStyle = reactive({
  top: "0px",
  left: "0px"
})

const showContextMenu = (event) => {
  event.preventDefault()
  isContextMenuVisible.value = true
  contextMenuStyle.top = `${event.clientY}px`
  contextMenuStyle.left = `${event.clientX}px`
}
const hideContextMenu = () => (isContextMenuVisible.value = false)

onClickOutside(contextNode, () => hideContextMenu())

defineExpose({
  showContextMenu,
  hideContextMenu
})
</script>

<template>
  <Transition name="slide-right">
    <div
      ref="contextNode"
      class="context-menu"
      v-show="isContextMenuVisible"
      :style="contextMenuStyle"
    >
      <slot></slot>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.context-menu {
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 8px;
  z-index: 1000;

  &.show {
    display: block;
  }
}
</style>
