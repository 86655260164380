import { mutationTypes } from "./constants"

import api from "@/api"

const { shops } = api.shops

export default {
  async getShops({ commit }) {
    const { data } = await shops()

    commit(mutationTypes.SET_SHOPS, data)
  }
}
