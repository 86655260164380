import { mutationTypes } from "./constants"

import api from "@/api"

const {
  filters,
  filtersUpdate,
  filtersUpdateImage,
  filtersUpdateValidate,
  filtersSort
} = api.fillters

export default {
  async getFilters({ commit }) {
    const { data } = await filters()

    commit(mutationTypes.SET_FILTERS, data)
  },

  async filtersUpdate({ commit }, params) {
    const { data } = await filtersUpdate(params)

    commit(mutationTypes.CHANGE_TYPE_AND_VISIBILITY_TYPE, params)
  },
  async changeOrderFilters({ commit }, data) {
    const { resp } = await filtersSort({ filters: data.data })

    commit(mutationTypes.CHANGE_ORDER_FILTERS, data)
  },

  async filtersUpdateImage({ commit }, { params, groupId }) {
    const { data } = await filtersUpdateImage(params)

    commit(mutationTypes.CHANGE_ICON_FROM_ITEM, {
      groupId,
      imageUrl: data.image_url,
      itemId: params.id
    })
  },

  async filtersUpdateValidate(
    { commit, state, dispatch },
    { validate, itemId, groupId }
  ) {
    const { data } = await filtersUpdateValidate({ id: itemId || groupId })

    if (data?.validate) {
      await commit(mutationTypes.CHANGE_VALIDATION, { itemId, groupId })
      const groupItem = state.groups.find((item) => item.id === groupId)

      if (groupItem.items.every((el) => !!el.validate) && !groupItem.validate) {
        dispatch("filtersUpdateValidate", { groupId })
      }
    }
  }
}
