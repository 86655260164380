<template>
  <div
    @click="$emit('onPress')"
    class="add-button"
    :style="{
      pointerEvents: disabled ? 'none' : 'auto',
      backgroundColor: computedBackgroundColor
    }"
  >
    <PlusIcon
      :color="iconColor"
      :style="{
        width: iconSize + 'px',
        height: iconSize + 'px'
      }"
    />
  </div>
</template>

<script>
import PlusIcon from "@/components/svg/PlusIcon.vue"

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    focused: {
      type: Boolean,
      default: false
    },

    backgroundColor: {
      type: String,
      default: "#99CCCD"
    },

    focusedBackgroundColor: {
      type: String,
      default: "#5DACAD"
    },

    iconColor: {
      type: String,
      default: "#fff"
    },

    iconSize: {
      type: Number,
      default: 24
    }
  },

  emits: ["onPress"],
  components: {
    PlusIcon
  },

  computed: {
    computedBackgroundColor() {
      return this.focused ? this.focusedBackgroundColor : this.backgroundColor
    }
  }
}
</script>

<style lang="scss" scoped>
.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 33px;
  cursor: pointer;
}
</style>
