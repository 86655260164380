<template>
  <div
    class="app-text-item"
    ref="textItem"
    @mouseover.stop="isHover = true"
    @mouseleave.stop="isHover = false"
  >
    <div class="inner-block">
      <span
        v-if="!isIdEmpty(item.id)"
        class="app-text-item--label"
        :class="[classText, { error: !item.validate }]"
        :style="{
          fontWeight: item.bold ? 'bold' : '',
          textDecoration: isHover || active ? 'underline' : ''
        }"
        @click="onPressItem"
      >
        {{ item.label }}
      </span>
      <span
        v-else
        class="app-text-item--label h-16 empty"
        :style="{
          textDecoration: isHover ? 'underline' : ''
        }"
      >
        {{ isIdEmpty(item.id) && disabled ? "пусте поле" : "" }}
      </span>

      <div class="actions">
        <AppSquareButton
          v-if="showBlock"
          :type="SQUARE_BUTTON_TYPE.LINK"
          :backgroundColor="linkButtonColor"
          :iconColor="linkButtonIconColor"
          @onPress="toggleShowLinkInput()"
          @mouseover="isHoverLinkButton = true"
          @mouseout="isHoverLinkButton = false"
        />
        <AppSquareButton
          v-else-if="!showBlock && item.link"
          :type="SQUARE_BUTTON_TYPE.LINK"
          backgroundColor="#99cccd"
          iconColor="#fff"
        />
        <AppSquareButton
          v-if="item.canChangeBold && showBlock"
          :type="SQUARE_BUTTON_TYPE.BOLD"
          :backgroundColor="boldButtonColor"
          :iconColor="boldButtonIconColor"
          @onPress="onPressBold"
          @mouseover="isHoverBoldButton = true"
          @mouseout="isHoverBoldButton = false"
        />
        <AppSquareButton
          v-if="this.isHover && this.disabled"
          :backgroundColor="deleteButtonColor"
          :iconColor="deleteButtonIconColor"
          @onPress="onDeleteItem"
          @mouseover="isHoverDeleteButton = true"
          @mouseout="isHoverDeleteButton = false"
        />
      </div>
    </div>
    <div
      v-if="showLinkInput"
      class="link-input-container"
      v-click-outside="hideLinkInput"
      :style="{ top: inputTopOffset }"
      @click.stop
    >
      <v-input
        :modelValue="item.link"
        input-type="url"
        class="v-input--circle circular-input"
        @update:modelValue="onChangeLink"
      >
        <template #action>
          <AppSquareButton
            class="clear-button"
            backgroundColor="transparent"
            iconColor="#000"
            @onPress="hideLinkInput"
          />
        </template>
      </v-input>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"

import AppSquareButton from "@/components/AppSquareButton.vue"
import { SQUARE_BUTTON_TYPES } from "@/constants/squareButton"
import { isValidUrl } from "@/helpers/strings.js"
import { namespace } from "@/store/modules/sectionsAndForms"
import { mutationTypes } from "@/store/modules/sectionsAndForms/constants"

const BUTTON_HOVER_COLOR = "#99cccd"

export default {
  props: {
    idx: {
      type: Number,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    sectionId: {
      type: [String, Number],
      required: true
    },

    blockId: {
      type: String,
      required: true
    },

    itemsRef: {
      type: Object,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    classText: {
      type: String,
      default: ""
    }
  },

  emits: ["selectItem", "selectDisabledItem"],

  components: {
    AppSquareButton
  },

  setup() {
    const SQUARE_BUTTON_TYPE = SQUARE_BUTTON_TYPES

    return {
      SQUARE_BUTTON_TYPE
    }
  },

  data: () => ({
    isHover: false,
    isHoverLinkButton: false,
    isHoverBoldButton: false,
    isHoverDeleteButton: false,
    showLinkInput: false,
    inputTopOffset: "0px"
  }),

  computed: {
    showBlock() {
      return !this.isIdEmpty(this.item.id) && this.isHover && this.disabled
    },

    linkButtonColor() {
      return this.isHoverLinkButton ? BUTTON_HOVER_COLOR : "transparent"
    },

    linkButtonIconColor() {
      return this.isHoverLinkButton ? "#fff" : "#000"
    },

    boldButtonColor() {
      return this.isHoverBoldButton ? BUTTON_HOVER_COLOR : "transparent"
    },

    boldButtonIconColor() {
      return this.isHoverBoldButton ? "#fff" : "#000"
    },

    deleteButtonColor() {
      return this.isHoverDeleteButton ? "#fc536a" : "transparent"
    },

    deleteButtonIconColor() {
      return this.isHoverDeleteButton ? "#fff" : "#000"
    }
  },

  methods: {
    async onPressItem() {
      if (this.disabled) {
        this.$emit("selectDisabledItem")
      }

      if (this.isIdEmpty(this.item.id) || this.disabled) return

      const selectItem = {
        sectionId: this.sectionId,
        blockId: this.blockId,
        itemId: this.item.id
      }

      this.$emit("selectItem", selectItem)
    },

    onChangeLink(link) {
      if (isValidUrl(link) || !link) {
        this.setItemLink({
          sectionId: this.sectionId,
          blockId: this.blockId,
          itemId: this.item.id,
          link,
          createTime: this.item.createTime
        })
      }
    },

    onPressBold() {
      this.toggleItemBold({
        sectionId: this.sectionId,
        blockId: this.blockId,
        itemId: this.item.id
      })
    },

    onDeleteItem() {
      this.deleteItem({
        sectionId: this.sectionId,
        blockId: this.blockId,
        itemId: this.item.id,
        createTime: this.item.createTime
      })
    },

    calculateInputTopOffset() {
      const scrollOffset = this.itemsRef.scrollTop
      const itemTopOffset = this.$refs.textItem.offsetTop
      const marginBottom = 8
      const itemHeight = 16 + marginBottom

      const topOffset =
        scrollOffset === 0
          ? itemTopOffset + itemHeight
          : itemTopOffset - scrollOffset + itemHeight

      return `${topOffset}px`
    },

    hideLinkInput() {
      this.showLinkInput = false
    },

    toggleShowLinkInput() {
      this.showLinkInput = !this.showLinkInput

      if (this.showLinkInput) {
        this.inputTopOffset = this.calculateInputTopOffset()
      }
    },

    isIdEmpty(ID) {
      return ID.toString().includes("empty")
    },

    ...mapMutations(namespace, {
      setItemLink: mutationTypes.SET_ITEM_LINK,
      toggleItemBold: mutationTypes.TOGGLE_TEXT_ITEM_BOLD,
      deleteItem: mutationTypes.DELETE_ITEM
    })
  }
}
</script>

<style lang="scss" scoped>
.app-text-item {
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;

  &--label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $grey1;
    &.h-16 {
      height: 16px;
    }
    &.empty {
      color: $grey;
    }
    &.bold {
      font-weight: bold;
    }
  }
}

.actions {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(
    270deg,
    #ffffff 90.72%,
    rgba(255, 255, 255, 0) 100%
  );
  right: 0;
  padding: 0 6px;
  width: fit-content;
}

.link-input-container {
  position: absolute;
  display: flex;
  align-items: center;
  min-width: 434px;
  height: 48px;
  padding: 8px;
  background-color: $white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 3;
  &_btn {
    color: $white;
    font-size: 16px;
    line-height: 16px;
    background-color: $light-green;
    border: 0;
    margin-left: 5px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.circular-input {
  width: 100%;
  height: 32px;
}

.inner-block {
  position: relative;
  display: flex;
  width: 100%;
}

.error {
  color: $light-red;
}
</style>
