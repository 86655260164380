<template>
  <AppBlock
    :type="type"
    :dropdownItems="list"
    :disabled="disabled"
    @onSelectDropdownItem="onAddItem"
    @onDelete="$emit('onDelete')"
  >
    <template #items>
      <div ref="items" class="items-container">
        <div v-for="(item, idx) in items" :key="`${item.id}${idx}`">
          <AppTextItem
            :idx="idx"
            :item="item"
            :sectionId="sectionId"
            :blockId="id"
            :itemsRef="itemsRef"
            :disabled="disabled"
            :active="selectedItem?.id === item.id"
            class-text="bold"
            @selectItem="$emit('selectItem', $event)"
            @selectDisabledItem="$emit('selectDisabledItem')"
          />
        </div>
      </div>
    </template>
  </AppBlock>
</template>

<script>
import AppTextItem from "../items/AppTextItem.vue"

import AppBlock from "./AppBlock.vue"

export default {
  props: {
    id: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    sectionId: {
      type: [String, Number],
      required: true
    },

    items: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      required: true,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    selectedItem: {
      type: Object,
      default: () => {}
    }
  },

  emits: ["onDelete", "onAddItem", "selectItem", "selectDisabledItem"],

  components: {
    AppBlock,
    AppTextItem
  },

  mounted() {
    this.itemsRef = this.$refs.items
  },

  data: () => ({
    itemsRef: {}
  }),

  methods: {
    onAddItem(item) {
      this.$emit("onAddItem", item)
    }
  }
}
</script>

<style lang="scss" scoped>
.items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
