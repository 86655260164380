<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5281 10.4813C22.4044 10.3139 22.2434 10.1776 22.0579 10.0832C21.8724 9.98884 21.6675 9.93895 21.4594 9.9375H20.0625V8.25C20.0625 7.9019 19.9242 7.56806 19.6781 7.32192C19.4319 7.07578 19.0981 6.9375 18.75 6.9375H12.2531C12.2328 6.93922 12.2124 6.93679 12.193 6.93034C12.1737 6.92389 12.1558 6.91357 12.1406 6.9L9.53437 4.95C9.30687 4.78021 9.03075 4.68817 8.74688 4.6875H3.75C3.4019 4.6875 3.06806 4.82578 2.82192 5.07192C2.57578 5.31806 2.4375 5.6519 2.4375 6V19.5C2.43856 19.6172 2.47446 19.7314 2.54063 19.8281C2.59349 19.9004 2.6626 19.9593 2.74239 20C2.82218 20.0407 2.91042 20.0621 3 20.0625H19.5C19.6182 20.0627 19.7334 20.0256 19.8293 19.9566C19.9252 19.8876 19.997 19.7902 20.0344 19.6781L22.7062 11.6625C22.7706 11.4653 22.7881 11.2559 22.7571 11.0508C22.7262 10.8457 22.6478 10.6507 22.5281 10.4813ZM3.75 5.8125H8.74688C8.74688 5.8125 8.78765 5.81321 8.80699 5.81966C8.82633 5.82611 8.84416 5.83643 8.85938 5.85L11.4656 7.8C11.6931 7.96979 11.9692 8.06183 12.2531 8.0625H18.75C18.7997 8.0625 18.8474 8.08225 18.8826 8.11742C18.9177 8.15258 18.9375 8.20027 18.9375 8.25V9.9375H6.54375C6.26733 9.93587 5.99755 10.0222 5.77341 10.184C5.54927 10.3458 5.38238 10.5746 5.29688 10.8375L3.5625 16.0312V6C3.5625 5.95027 3.58225 5.90258 3.61742 5.86742C3.65258 5.83225 3.70027 5.8125 3.75 5.8125Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "iconFolderActive"
}
</script>

<style></style>
