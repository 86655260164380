import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/brands"

export default {
  list() {
    return sendSecureRequest().get(`${ENDPOINT}`)
  },

  current(id) {
    return sendSecureRequest().get(`${ENDPOINT}/${id}`)
  },

  create(data) {
    return sendSecureRequest().post(`${ENDPOINT}`, data)
  },

  update(data) {
    return sendSecureRequest().put(`${ENDPOINT}`, data)
  },

  listSearch(data) {
    return sendSecureRequest().get(`${ENDPOINT}-search`, data)
  }
}
