export const BLOCK_TYPES = {
  TEXT: "text",
  BANNER: "banner",
  EXTRA: "extra"
}
// const TYPE = {category,image,extrablock}

export const ITEM_TYPES = {
  TEXT: "text",
  IMAGE: "image"
}

export const BASE_PLACARD = "BASE_PLACARD"
