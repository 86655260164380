<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.875" width="6.125" height="1.5" rx="0.75" fill="black" />
    <rect y="4.375" width="8.75" height="1.5" rx="0.75" fill="black" />
    <rect y="7.875" width="11.375" height="1.5" rx="0.75" fill="black" />
    <rect y="11.375" width="14" height="1.5" rx="0.75" fill="black" />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
