<template>
  <div class="modal-reviews">
    <div class="modal-reviews--header">
      <span>Купи</span>
      <div class="dot"></div>
      <span>Спробуй</span>
      <div class="dot"></div>
      <span>Оціни</span>
    </div>
    <div class="modal-reviews--body">
      <div class="modal-reviews--body_left">
        <img
          :src="currentReview.image + 'medium'"
          alt="picture product"
          width="328"
          height="328"
          class="modal-reviews--body_left__img"
        />
        <h6 class="modal-reviews--body_left__title">
          {{ currentReview.brand }}
        </h6>
        <p class="modal-reviews--body_left__desc">
          {{ currentReview.name }}
        </p>
        <div v-if="currentReview.color" class="modal-reviews--body_left__color">
          <img
            :src="currentReview.color.url + 'small'"
            :alt="'Color' + currentReview.color.name"
          />
          {{ currentReview.color.name }}
        </div>
      </div>
      <div class="modal-reviews--body_right">
        <div class="attributes">
          <div
            class="attributes-item"
            v-for="attr in currentReview.attributes"
            :key="attr.key"
          >
            <div class="attributes-item--title">{{ attr.attribute_name }}</div>
            <div class="attributes-item--list">
              <div
                class="attributes-item--label"
                v-for="childAttr in attr.children"
                :key="childAttr.id"
                :class="{
                  active: currentReview.selected_attributes.includes(
                    childAttr.id
                  )
                }"
              >
                {{ childAttr.attribute_name }}
              </div>
            </div>
          </div>
          <div class="attributes-item">
            <div class="attributes-item--title">Рекомендую</div>
            <div class="attributes-item--list">
              <div
                class="attributes-item--label"
                :class="{ active: currentReview.recommend }"
              >
                так
              </div>
              <div
                class="attributes-item--label"
                :class="{ active: !currentReview.recommend }"
              >
                ні
              </div>
            </div>
          </div>
          <div class="attributes-item">
            <div class="attributes-item--title">Оцінка</div>
            <div class="attributes-item--list">
              <star-rating
                :rating="currentReview.rating"
                :increment="1"
                :max-rating="5"
                :fixed-points="1"
                :star-size="18"
                :active-color="['#000']"
                :border-width="2"
                :border-color="'#000'"
                :inactive-color="'#fff'"
                :padding="1"
                :read-only="true"
                :show-rating="false"
              ></star-rating>
            </div>
          </div>
        </div>
        <div
          ref="highlightedText"
          v-html="content"
          contenteditable="true"
          class="modal-reviews--textarea"
          @mouseup="stopHighlighting"
          @contextmenu="showContextMenu($event)"
        ></div>
        <div class="modal-reviews--footer">
          <v-drop-down-slots-fixed ref="dropDown" class="drop-down-search">
            <template v-slot:toggler>
              <span class="modal-reviews--footer_toggle">дія</span>
            </template>
            <template v-slot:content>
              <ul class="modal-reviews--footer_content">
                <li
                  v-for="(value, key) in statusOptions"
                  :key="key"
                  @click="onSetStatus(key)"
                >
                  {{ value }}
                </li>
              </ul>
            </template>
          </v-drop-down-slots-fixed>
          <button
            class="modal-reviews--submit"
            :disabled="!status"
            @click="onSubmit()"
          >
            Застосувати
          </button>
        </div>
      </div>
    </div>
    <context-menu ref="contextMenu">
      <ul>
        <li>
          <span @click="highlightSelectedText">
            {{ focusNode ? "Зняти виділене" : "Виділити цей фрагмент" }}
          </span>
          <button @click="closeContecMenu()">
            <img
              src="@/assets/icons/close.svg"
              alt="Icon close modal"
              width="12"
              height="12"
              class="img-fluid"
            />
          </button>
        </li>
      </ul>
    </context-menu>
  </div>
</template>

<script>
import StarRating from "vue-star-rating"
import { mapActions } from "vuex"

import contextMenu from "./contextMenu.vue"

import iconReset from "@/assets/icons/check-duotone.svg"
import VDropDownSlotsFixed from "@/components/ui/v-drop-down-slots-fixed"
import { namespace } from "@/store/modules/reviews"

const STATUS = {
  PUBLISHED: "опублікувати",
  PROBLEMATIC_VOCABULARY: "ненормативна лексика",
  PROBLEMATIC_NOTAPPLICABLE: "не стосується товару",
  HIDDEN: "приховати"
}

export default {
  name: "modalReviews",

  components: {
    StarRating,
    VDropDownSlotsFixed,
    contextMenu
  },

  emits: ["closeModal"],

  props: {
    currentId: {
      type: [Number, null],
      required: true
    },

    currentReview: {
      type: [Object, null],
      required: true
    }
  },

  data: () => ({
    iconReset,
    status: "",
    content: "",
    highlightedText: "",
    focusNode: null
  }),

  mounted() {
    this.content = this.currentReview?.feedback || ""
  },

  computed: {
    statusOptions() {
      return STATUS
    }
  },

  methods: {
    ...mapActions(namespace, ["fetchUpdateReview"]),

    onSetStatus(key) {
      this.status = key
      this.$refs.dropDown.onClose()
    },

    async onSubmit() {
      const params = {
        id: this.currentId,
        status: this.status,
        content: this.content
      }

      await this.fetchUpdateReview(params)
      this.$emit("closeModal")
    },

    showContextMenu(event) {
      this.$refs.contextMenu.showContextMenu(event)
    },

    closeContecMenu() {
      this.$refs.contextMenu.hideContextMenu()
    },

    highlightSelectedText() {
      if (!this.highlightedText.length) return

      let content = ""

      if (this.focusNode) {
        content = this.content.replace(
          new RegExp(this.focusNode.outerHTML, "g"),
          `${this.highlightedText}`
        )
      } else {
        content = this.content.replace(
          new RegExp(this.highlightedText, "g"),
          `<span style="color: #D8112C;">${this.highlightedText}</span>`
        )
      }

      this.content = content
      this.focusNode = null
      this.highlightedText = ""
      this.closeContecMenu()
    },

    stopHighlighting(event) {
      const element = event.target

      if (element.nodeName === "SPAN") {
        this.focusNode = element
        this.highlightedText = element.innerHTML
        return
      }

      const rangeText = document.getSelection()

      if (rangeText.type === "Range") {
        this.highlightedText = rangeText.toString()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-reviews {
  width: 975px;

  &--header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-align: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e0e0e0;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;

    .dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #4f4f4f;
      vertical-align: middle;
    }
  }

  &--textarea {
    width: 100%;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    height: 123px;
    outline: none;
    overflow: auto;
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin-top: 13px;

    &_toggle {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
    }

    &_content {
      padding: 24px 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

      li {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--submit {
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 40px;
    border-radius: 20px;
    background: #000;
    border: 0;

    &:disabled {
      background: #bdbdbd;
    }
  }

  &--body {
    display: flex;
    gap: 13px;
    padding: 0 20px 20px;
    color: #000;

    &_left {
      max-width: 328px;

      &__img {
        margin-bottom: 10px;
      }

      &__title,
      &__desc,
      &__color {
        padding-left: 20px;
        padding-right: 10px;
      }

      &__title {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      &__desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 9px;
      }

      &__color {
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 4px;
        }

        font-size: 14px;
        font-weight: 400;
      }
    }

    &_right {
      width: 100%;

      .attributes {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 26px;
        margin-bottom: 24px;

        &-item {
          display: grid;
          grid-template-columns: 131px 1fr;
          gap: 8px;

          &--title {
            padding: 5px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
          }

          &--list {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          &--label {
            height: fit-content;
            font-size: 14px;
            line-height: 14px;
            padding: 2px 14px;
            border-radius: 15px;
            border: 1px solid #e0e0e0;
            cursor: pointer;

            &:hover {
              border-color: #828282;
            }

            &.active {
              background-color: #000;
              color: #fff;
              border-color: #000;
            }
          }
        }
      }
    }
  }

  .context-menu {
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
