import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export const namespace = "sectionsAndForms"

const state = () => ({
  selectedSection: null,
  selectedItem: null,
  categories: [],
  placard: [],
  products: [],
  filters: [],
  forms: [],
  filtersCopy: [],
  formsCopy: [],
  placardCopy: []
})

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
