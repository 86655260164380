import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_SHOPS](state, data) {
    state.tradingPoints = data
  },
  [mutationTypes.SET_EDITED_SHOP](state, { id, editedShop }) {
    const index = state.tradingPoints.data.findIndex((item) => item.id === id)

    state.tradingPoints.data[index] = editedShop[0]
  }
}
