<template>
  <div class="container">
    <div
      v-for="format of getFormat"
      :key="format.title"
      class="row"
      @click="onSelectFormat(format.value)"
    >
      <div class="banner">
        <div
          v-for="block of format.value"
          :key="block"
          :class="[{ first: format.value === 1 }]"
        ></div>
      </div>
      <span class="banner-format">{{ format.title }}</span>
    </div>
  </div>
</template>

<script>
import { TYPE_BANNER } from "@/constants/banners.js"

const FORMAT = [
  { id: 0, title: "Одинарний", value: 1 },
  { id: 1, title: "Подвійний", value: 2 },
  { id: 2, title: "Потрійний", value: 3 }
]

export default {
  name: "BannersFormatSwitcher",
  props: {
    typeBanner: {
      type: Number,
      default: 3
    }
  },

  computed: {
    getFormat() {
      return FORMAT.filter((el) => el.value <= this.typeBanner)
    }
  },

  methods: {
    onSelectFormat(format) {
      this.$emit("setFormat", format)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
  width: fit-content;
  height: fit-content;
  padding: 16px;
}
.row {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.banner {
  display: flex;
  width: 80px;
  height: 32px;
  div {
    display: flex;
    flex: 1;
    &.first,
    &:nth-child(even) {
      background-color: $grey6;
    }
    &:not(.first):nth-child(odd) {
      background-color: $grey5;
    }
  }
}
.banner-format {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $black;
}
</style>
