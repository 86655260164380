<template>
  <span @click="copyText" :class="['copy-label', { disabled: disabled }]"
    >copy
  </span>
</template>

<script>
export default {
  props: {
    textToCopy: {
      type: String,
      required: true,
      default: ""
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["onCopy"],

  methods: {
    copyText() {
      if (this.disabled) return

      this.$copyText(this.textToCopy)
        .then(() => {
          console.log("copied!")
          this.$emit("onCopy", true)
        })
        .catch((e) => {
          console.log(`can't copy`, e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-label {
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: $black;
  opacity: 0.8;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}
</style>
