<template>
  <div class="input-container">
    <SearchIcon class="icon" />
    <input
      class="input"
      type="text"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="isDisabled"
      @focus="onFocus(true)"
      @blur="onFocus(false), savePrevText()"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-show="isHover && listSearches.length" class="input-container--list">
      <span
        v-for="item of listSearches"
        :key="item"
        @click="$emit('selectPrevItem', item)"
      >
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
import SearchIcon from "./svg/SearchIcon.vue"

import { setItemlocalStorage, getItemlocalStorage } from "@/helpers/storage.js"

export default {
  components: {
    SearchIcon
  },

  props: {
    modelValue: {
      type: [String],
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    listSearches: {
      type: Array,
      default: () => []
    },

    nameStorage: {
      type: String
    },

    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["update:modelValue", "selectPrevItem"],
  data() {
    return {
      isHover: false
    }
  },

  methods: {
    savePrevText() {
      const oldData = getItemlocalStorage(this.nameStorage) || []

      if (!this.modelValue || oldData.includes(this.modelValue)) return

      setItemlocalStorage(this.nameStorage, [
        this.modelValue,
        ...this.listSearches
      ])
    },

    onFocus(bool) {
      const DELAY = 120

      if (!bool) {
        setTimeout(() => {
          this.isHover = bool
        }, DELAY)
      } else {
        this.isHover = bool
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 32px;
  max-width: 611px;
  width: 100%;
  border: 1px solid $grey5;
  border-radius: 15px;
  &--list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid $grey5;
    border-radius: 15px;
    padding: 16px;
    background-color: $white;
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.icon {
  margin-right: 16px;
}

.input {
  width: 100%;
  border: none;
}
</style>
