import { sendSecureRequest } from "../config"

import { isAddSearch } from "./../../helpers/urlModificator"

const ENDPOINT = "admin/balance"

export default {
  balance(page = 1, searchPhrase = "") {
    return sendSecureRequest().get(
      `${ENDPOINT}${isAddSearch(searchPhrase, page)}`
    )
  },
  balanceShop({ id, page = 1, searchPhrase = "" }) {
    return sendSecureRequest().get(
      `${ENDPOINT}/${id}/${isAddSearch(searchPhrase, page)}`
    )
  }
}
