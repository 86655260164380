<template>
  <div class="dropdown" :style="{ maxHeight: maxHeight }">
    <template v-if="!cascade">
      <span
        v-for="item in items"
        :key="item.id"
        class="dropdown--item"
        @click="$emit('onPressItem', item)"
      >
        {{ item.label }}
      </span>
    </template>
    <template v-else>
      <div
        v-for="item in items"
        :key="item.id"
        class="d-flex flex-column"
        @click="$emit('onPressItem', item)"
      >
        <span
          class="dropdown--item"
          :class="{
            bold: item?.bold,
            light: item.id.toString().includes('empty')
          }"
        >
          {{ item.label }}
        </span>
        <template v-if="item?.children?.length > 0">
          <span
            v-for="child in item.children"
            :key="child.id"
            class="dropdown--item"
            @click="$emit('onPressItem', child)"
          >
            {{ child.label }}
          </span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    cascade: {
      type: Boolean,
      default: false
    },

    maxHeight: {
      type: String,
      default: "fit-content"
    }
  },

  emits: ["onPressItem"]
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px 0;
  width: fit-content;
  height: fit-content;
  overflow-y: scroll;

  &--item {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    &.bold {
      font-weight: bold;
    }
    &.light {
      color: $grey;
    }
  }
}
</style>
