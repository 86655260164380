import Item from "./Item"

class BannerItem extends Item {
  constructor(
    id,
    type,
    image = {
      url: ""
    },
    link = ""
  ) {
    super(id, type, link)
    this.image = image
  }
}

export default BannerItem
