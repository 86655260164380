<template>
  <div class="table" :style="`--columns: ${columnsStyles}`">
    <div
      class="table-header"
      :style="`grid-template-columns: ${columnsStyles}`"
    >
      <slot name="header"></slot>
    </div>
    <div class="table-body">
      <AppTableRow
        :class="['row', { 'px-0': disableRowPadding }]"
        :style="`grid-template-columns: ${columnsStyles}`"
        v-for="item in list"
        :key="item[listIdKey]"
        :item="item"
        :listIdKey="listIdKey"
        :listSubItemsKey="listSubItemsKey"
        :isDisabledEditingSubElements="isDisabledEditingSubElements"
        :disabledSubElementId="disabledSubElementId"
        :openRowOnIcon="openRowOnIcon"
        @clickDisabledElement="$emit('clickDisabledElement')"
      >
        <template v-slot:row>
          <slot name="body" v-bind="item"></slot>
        </template>
        <template v-slot:subItem>
          <slot name="subItem" v-bind="item[listSubItemsKey] || item" />
        </template>
        <template v-slot:endElement>
          <slot name="endElement" />
        </template>
      </AppTableRow>
    </div>
  </div>
</template>

<script>
import AppTableRow from "@/components/AppTableRow.vue"

export default {
  components: { AppTableRow },
  props: {
    isDisabledEditingSubElements: {
      type: Boolean,
      default: true
    },

    disabledSubElementId: {
      type: [String, Number],
      default: null
    },

    columnsStyles: {
      type: String,
      default: ""
    },

    listIdKey: {
      type: String,
      default: "code"
    },

    listSubItemsKey: {
      type: String,
      default: "products"
    },

    list: {
      type: Array,
      default: () => []
    },

    openRowOnIcon: {
      type: Boolean,
      default: false
    },

    disableRowPadding: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin gridOffsets {
  column-gap: 40px;
}
.table {
  &-header {
    display: grid;
    color: $grey2;
    border-bottom: 2px solid $grey5;
    @include gridOffsets;
    padding: 10px 16px;
    font-size: 12px;
  }
  &-body .row {
    display: grid;
    @include gridOffsets;
    font-size: 14px;
    border-bottom: 1px solid $grey5;
    &--dropDown {
      cursor: pointer;
      padding-right: 0px;
      padding-left: 0px;
      &-open {
        background-color: $green2;
        padding-bottom: 0;
      }
    }
  }
  .details {
    padding: 5px 16px 5px;
    margin-top: 10px;
    cursor: default;
    &--open {
      background-color: $nav-color;
    }
  }
}
</style>
