import { createRouter, createWebHistory } from "vue-router"

import * as COOKIE_KEYS from "../constants/cookieKeys"
import { getCookie } from "../helpers/cookies"

import { ROUTE } from "./routeNames"
import routes from "./routes"

const router = createRouter({ routes, history: createWebHistory() })

router.beforeEach((to, _, next) => {
  const token = getCookie(COOKIE_KEYS.TOKEN)

  if (to.name !== ROUTE.LOGIN.name && !token) {
    next({ name: ROUTE.LOGIN.name })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  const toDepth = to.path.split("/").length
  const fromDepth = from.path.split("/").length
  to.meta.transition = toDepth < fromDepth ? "slide-right" : "slide-left"
})

export default router
