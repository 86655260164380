import { mutationTypes } from "./constants"

import api from "@/api"

const { reviews, review, updateReview } = api.reviews

export default {
  async fetchReviews({ commit }, params) {
    const { data } = await reviews(params)

    commit(mutationTypes.SET_REVIEWS, data)
  },

  async fetchReview({ commit }, ID) {
    const { data } = await review(ID)

    commit(mutationTypes.SET_CURRENT_REVIEW, data)
    return data
  },

  async fetchUpdateReview({ commit }, params) {
    const { data } = await updateReview(params)

    return data
  }
}
