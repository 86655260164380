<template>
  <AppBlock
    :type="type"
    :dropdownItems="list"
    :disabled="disabled"
    @onSelectDropdownItem="onSelectDropdownItem"
    @onDelete="$emit('onDelete')"
  >
    <template #items>
      <div ref="items" class="items-container">
        <AppTextItem
          v-for="(item, idx) in items"
          :key="item.id"
          :idx="idx"
          :item="item"
          :sectionId="sectionId"
          :draggable="disabled"
          :blockId="id"
          :itemsRef="itemsRef"
          :disabled="disabled"
          :active="selectedItem?.id === item.id"
          @dragover.prevent
          @dragenter.prevent
          @dragstart="onStartDrag($event, item, idx)"
          @drop="onEndDrag($event, item, idx)"
          @selectItem="$emit('selectItem', $event)"
          @selectDisabledItem="$emit('selectDisabledItem')"
        />
      </div>
    </template>
  </AppBlock>
</template>

<script>
import { uuid } from "vue-uuid"
import { mapMutations } from "vuex"

import AppTextItem from "../items/AppTextItem.vue"

import AppBlock from "./AppBlock.vue"

import {
  setItemSessionStorage,
  getItemSessionStorage,
  removeItemSessionStorage
} from "@/helpers/storage"
import TextItem from "@/models/sections/items/TextItem"
import { namespace } from "@/store/modules/sectionsAndForms"
import { mutationTypes } from "@/store/modules/sectionsAndForms/constants"

export default {
  props: {
    id: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    sectionId: {
      type: [String, Number],
      required: true
    },

    items: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      required: true,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    selectedItem: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    "onAddItem",
    "onDelete",
    "noDrope",
    "selectItem",
    "selectDisabledItem"
  ],

  components: {
    AppBlock,
    AppTextItem
  },

  mounted() {
    this.itemsRef = this.$refs.items
  },

  data: () => ({
    isHoverAllForm: false,
    isHoverAddButtonFalse: false,
    isHoverDeleteButton: false,
    isShowDropdown: false,
    itemsRef: {}
  }),

  computed: {
    addButtonBackgroundColor() {
      return this.isHoverAddButtonFalse ? "#5DACAD" : "transparent"
    },

    addButtonIconColor() {
      return this.isHoverAddButtonFalse ? "#fff" : "#000"
    },

    deleteButtonBackgroundColor() {
      return this.isHoverDeleteButton ? "#fc536a" : "transparent"
    },

    deleteButtonIconColor() {
      return this.isHoverDeleteButton ? "#fff" : "#000"
    }
  },

  methods: {
    onSelectDropdownItem(item) {
      let myItem = item

      if (item.id === "empty") {
        myItem = { ...item, id: item.id + uuid.v4() }
      }

      this.$emit(
        "onAddItem",
        new TextItem(myItem.id, myItem.label, myItem.parent_id, myItem.bold)
      )
    },

    onStartDrag(event, item, idx) {
      const dragData = {
        blockId: this.id,
        item: item,
        dragIdx: idx
      }

      setItemSessionStorage("drag-item", dragData)
    },

    onEndDrag(event, item, idx) {
      event.stopPropagation()
      const dragItem = getItemSessionStorage("drag-item")
      const dragData = {
        sectionId: this.sectionId,
        oldBlockId: dragItem.blockId,
        newBlockId: this.id,
        dragItem: dragItem.item,
        dragIdx: dragItem.dragIdx,
        dropIdx: idx
      }

      this.dragChidItem(dragData)
      removeItemSessionStorage("drag-item")
    },

    ...mapMutations(namespace, {
      dragChidItem: mutationTypes.DRAG_CHILD_ITEM
    })
  }
}
</script>

<style lang="scss" scoped>
.items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
