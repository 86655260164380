<template>
  <div>
    <Calendar
      ref="calendar"
      locale="uk"
      :attributes="attributes"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      @dayclick="onDayClickHandler"
    />
  </div>
</template>

<script>
import { Calendar } from "v-calendar"

import { formatDate } from "@/helpers/date"

export default {
  props: {
    onDayClick: {
      type: Function,
      default: () => {}
    },

    selectedDate: {
      type: [String, Date, Number],
      default: new Date()
    },

    minDate: {
      type: [String, Date, Number, null],
      default: null
    },

    maxDate: {
      type: [String, Date, Number, null],
      default: null
    },

    disabledDates: {
      type: [Object, Array, null],
      default: null
    }
  },

  components: {
    Calendar
  },

  async mounted() {
    this.attributes[0].dates = this.selectedDate

    if (formatDate(this.selectedDate, "MM") !== formatDate(new Date(), "MM")) {
      this.$refs.calendar.move(formatDate(this.selectedDate, "YYYY-MM-DD"), {
        transition: "none"
      })
    }
  },

  data: () => ({
    date: null,
    attributes: [
      {
        key: "today",
        highlight: true,
        dates: new Date()
      }
    ]
  }),

  methods: {
    onDayClickHandler(date) {
      if (!!date.isDisabled) return

      this.onDayClick(date.date)
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-container {
  width: 200px;
  height: 400px;
}

.disabled-dates {
  pointer-events: none;
}
</style>
