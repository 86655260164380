// sessionStorage
export const setItemSessionStorage = (name, data) => {
  sessionStorage.setItem(name, JSON.stringify(data))
}

export const getItemSessionStorage = (name) => {
  const result = sessionStorage.getItem(name)

  return JSON.parse(result)
}

export const removeItemSessionStorage = (name) => {
  sessionStorage.removeItem(name)
}

// localStorage
export const setItemlocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data))
}

export const getItemlocalStorage = (name) => {
  const result = localStorage.getItem(name)

  return JSON.parse(result)
}

export const removeItemlocalStorage = (name) => {
  localStorage.removeItem(name)
}
