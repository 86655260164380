<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.36364 0.454545C6.36364 0.203507 6.56714 0 6.81818 0H9.54545C9.79649 0 10 0.203507 10 0.454545V3.18182C10 3.43286 9.79649 3.63636 9.54545 3.63636C9.29442 3.63636 9.09091 3.43286 9.09091 3.18182V1.57877L5.16448 5.64059C4.99 5.82109 4.70224 5.82597 4.52174 5.65149C4.34125 5.47701 4.33637 5.18925 4.51085 5.00876L8.47386 0.909091H6.81818C6.56714 0.909091 6.36364 0.705584 6.36364 0.454545ZM0 1.36364C0 1.1126 0.203507 0.909091 0.454545 0.909091H4C4.25104 0.909091 4.45455 1.1126 4.45455 1.36364C4.45455 1.61467 4.25104 1.81818 4 1.81818H0.909091V9.09091H8.18182V6C8.18182 5.74896 8.38533 5.54545 8.63636 5.54545C8.8874 5.54545 9.09091 5.74896 9.09091 6V9.54545C9.09091 9.79649 8.8874 10 8.63636 10H0.454545C0.203507 10 0 9.79649 0 9.54545V1.36364Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000"
    }
  }
}
</script>

<style></style>
