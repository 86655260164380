import { sendSecureRequest } from "../config"

import { isAddSearch } from "./../../helpers/urlModificator"

const ENDPOINT = "admin/shops"
const ENDPOINT_FULL = "admin/shops/full"
const ENDPOINT_SHOP_EDIT = "admin/shop"

export default {
  shops() {
    return sendSecureRequest().get(ENDPOINT)
  },
  shopsFull(searchPhrase, page) {
    return sendSecureRequest().get(
      `${ENDPOINT_FULL}${isAddSearch(searchPhrase, page)}`
    )
  },
  editShop(data) {
    return sendSecureRequest().put(ENDPOINT_SHOP_EDIT, data)
  }
}
