<template>
  <div class="orders-page">
    <div class="orders-header">
      <PageTitle class="mb-40" title="Торгові точки" />
    </div>
    <SearchInput
      class="search"
      v-model="search"
      :isDisabled="allowEditing && !!editingElementId"
      :list-searches="listSearches()"
      :name-storage="prevSearchList"
      @selectPrevItem="search = $event"
      @click="
        allowEditing && !!editingElementId && (isSaveWarningActive = true)
      "
    />
    <div v-if="loading" class="loading-status">Loading ...</div>
    <div v-else-if="!loading && tradingPointsList && tradingPointsList.length">
      <AppTable
        :list="tradingPointsList"
        listIdKey="id"
        :isDisabledEditingSubElements="allowEditing"
        :disabledSubElementId="editingElementId"
        @clickDisabledElement="isSaveWarningActive = true"
      >
        <template v-slot:header>
          <div class="header">
            <div>артикул</div>
            <div>торгова точка</div>
            <div>статус</div>
            <div>дата</div>
            <div>відображення на сайті</div>
          </div>
        </template>
        <template #body="{ store_code, address, status, date, changed, view }">
          <div class="row-grid">
            <div>{{ store_code }}</div>
            <div>{{ address }}</div>
            <div :class="{ 'row-grid__status-change': changed }">
              {{ changed ? "! змінено" : getTypePoint(status) }}
            </div>
            <div>
              {{
                `${cutDate(date.date_start)} ${
                  cutDate(date.date_end) && "-"
                } ${cutDate(date.date_end)}`
              }}
            </div>
            <div>{{ view }}</div>
          </div>
        </template>
        <template #subItem="subItems">
          <EditShop
            :item="subItems"
            :saveWarning="isSaveWarningActive"
            :isEditingPossible="!allowEditing && !editingElementId"
            @editingAllow="editingAllow($event)"
            @setSaveWarning="isSaveWarningActive = true"
          />
        </template>
      </AppTable>
      <div class="orders-footer">
        <CountElements
          v-if="tradingPoints && tradingPoints.to && tradingPoints.total"
          :current="tradingPoints.to || NaN"
          :total="tradingPoints.total || NaN"
        />
        <PaginationLaravel
          v-if="tradingPoints"
          :info="tradingPoints"
          @changePage="changePageIfAllowed($event)"
        />
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { mapGetters, mapActions, mapMutations } from "vuex"

import EditShop from "./components/editShop.vue"

import AppTable from "@/components/AppTable.vue"
import PageTitle from "@/components/PageTitle.vue"
import SearchInput from "@/components/SearchInput.vue"
import CountElements from "@/components/pagination/countElements.vue"
import PaginationLaravel from "@/components/pagination/paginationLaravel.vue"
import { PREV_TRADING_POINTS_BRANDS } from "@/constants/localStorageKeys.js"
import { TRADING_POINTS_TYPES_TRANSLATE } from "@/constants/tradingPointsStatus.js"
import { formatDateLocal } from "@/helpers/date"
import { debounce } from "@/helpers/strings"
import LeftoversFolderMixin from "@/mixins/leftoversFolder.mixin"
import { mutationTypes } from "@/store/modules/orders/constants"
import { namespace } from "@/store/modules/tradingPoints"

const responseDateFormat = "YYYY-MM-DD"

export default {
  name: "TradingPoints",
  mixins: [LeftoversFolderMixin],
  components: {
    PageTitle,
    SearchInput,
    AppTable,
    CountElements,
    PaginationLaravel,
    EditShop
  },

  async created() {
    this.search = this.$route.query.search || ""
    this.page = this.$route.query.page
    await this.getShopsFull()
  },

  data: () => ({
    search: "",
    searchDropDown: "",
    allowEditing: false,
    editingElementId: null,
    isSaveWarningActive: false,
    disableSearchRequest: true,
    loading: true,
    page: 1,
    routeKey: "TRADING_POINTS"
  }),

  computed: {
    ...mapGetters({
      tradingPoints: `${namespace}/tradingPoints`,
      tradingPointsList: `${namespace}/tradingPointsList`
    }),

    prevSearchList() {
      return PREV_TRADING_POINTS_BRANDS
    }
  },

  watch: {
    search(phrase) {
      if (!this.disableSearchRequest) {
        debounce(() => {
          this.setSearchResult(phrase, "getShopsFull")
        }, 500)
      }
    }
  },

  methods: {
    ...mapActions({
      getShopFull: `${namespace}/getShopFull`
    }),

    ...mapMutations(namespace, {
      changeDetailsStatus: mutationTypes.CHANGE_DETAILS_STATUS
    }),

    dayjs,
    isTouchDate(fn) {
      this.touchDate = true
      fn()
    },

    async getShopsFull() {
      this.loading = true
      const params = { page: this.page }

      if (this.search) {
        params.search = this.search
      }

      await this.getShopFull(params)
      this.loading = false

      if (this.disableSearchRequest) this.disableSearchRequest = false
    },

    getTypePoint(type) {
      return TRADING_POINTS_TYPES_TRANSLATE[type]
    },

    cutDate(text, mask = "DD.MM.YY") {
      return text
        ? formatDateLocal(dayjs(text, responseDateFormat).valueOf(), mask)
        : ""
    },

    editingAllow({ status, id }) {
      this.allowEditing = status
      this.editingElementId = id
      this.isSaveWarningActive = false
    },

    changePageIfAllowed(event) {
      if (this.allowEditing && !!this.editingElementId) {
        this.isSaveWarningActive = true
        return
      }

      this.changePage(event, "getShopsFull")
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin gridTable {
  display: grid;
  grid-template-columns: 160px 720px 192px 252px 1fr;
  padding-right: 16px;
  padding-left: 16px;
}

.search {
  margin-bottom: 15px;

  :deep input {
    background-color: #fff;
    cursor: text;
  }
}

.orders {
  &-header {
    display: flex;
    gap: 100px;
  }

  &-footer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}

.shop-edit {
  padding-top: 20px;
  padding-left: 160px;

  &__date {
    display: flex;
    gap: 16px;
  }
}

:deep .table-body .row {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
  margin-top: -1px;
}

:deep .table-header {
  padding: 7px 16px 8px;
}

:deep .table-body {
  display: grid;
  grid-gap: 1px;
}

:deep .table .details--open {
  background-color: $grey6;
}

:deep .table-body .row.row--dropDown-open {
  background-color: $grey6;
}

.row-grid,
.header {
  @include gridTable;

  & > div {
    white-space: nowrap;
  }
}

.row-grid {
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;

  &__status-change {
    color: $red;
  }

  & > div {
    font-size: 14px;
    line-height: 16px;
  }
}

.row--dropDown-open .row-grid {
  border-bottom: 1px solid #fff;
}

.header {
  padding-left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.loading-status {
  text-align: center;
}
</style>
