<script>
import DateFormat from "@/components/date/DateFormat.vue"
import IconArrowUp from "@/components/svg/ArrowUpIcon.vue"
import orderStatus from "@/pages/Orders/components/orderStatus.vue"
import iconECheck from "./assets/icons/e-check.svg"
import iconMarker from "./assets/icons/marker.svg"
import productItem from "./components/productItem.vue"
import { mapActions } from "vuex"
import { namespace } from "@/store/modules/orders"
import { PAYMENT_STATUSES } from "../Orders/constants"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import Confirm from "@/components/Confirm.vue"
import { createConfirmDialog } from "vuejs-confirm-dialog"
import { INNER_STATUS_CODES } from "../Orders/constants"

export default {
  name: "OrderInfo",
  components: {
    IconArrowUp,
    orderStatus,
    productItem,
    DateFormat,
    LoadingSpinner
  },

  setup() {
    const confirmDialog = createConfirmDialog(Confirm, {
      title: "Ти впевнений?",
      text: "Це незворотня дія. Замовлення буде скасовано назавжди."
    })

    return {
      confirmDialog
    }
  },

  data: () => ({
    toggle: false,
    iconMarker,
    iconECheck,
    order: null,
    loading: false,
    orderLoading: false,
    innerStatusCodes: INNER_STATUS_CODES
  }),

  async created() {
    this.orderLoading = true
    this.order = await this.getOrdersId(this.isOrderId)
    this.loading = true
    this.orderLoading = false
  },

  computed: {
    isOrderId() {
      return this.$route.params.orderId
    },

    canCancelOrder() {
      return this.innerStatusCodes[this.order?.status.code]?.cancel
    }
  },

  methods: {
    ...mapActions({
      getOrdersId: `${namespace}/getOrdersId`,
      updateNp: `${namespace}/updateNp`,
      cancelOrder: `${namespace}/cancelOrder`
    }),

    async directUpdateNp() {
      this.orderLoading = true
      const updatedOrder = await this.updateNp(this.isOrderId)

      if (updatedOrder) {
        this.order = updatedOrder
      }

      this.orderLoading = false
    },

    async directCancelOrder() {
      const { isCanceled } = await this.confirmDialog.reveal()
      if (isCanceled) return

      this.orderLoading = true
      const updatedOrder = await this.cancelOrder(this.isOrderId)

      if (updatedOrder) {
        this.order = updatedOrder
      }

      this.orderLoading = false
    },

    openReceipt() {
      if (this.order?.receipt) {
        window.open(this.order.receipt, "_blank")
      }
    },

    paymentStatus(status) {
      return PAYMENT_STATUSES[status] || ""
    },

    formatPhoneNumber(phoneNumber) {
      const cleaned = ("" + phoneNumber).replace(/\D/g, "")

      const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/)

      if (match) {
        return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
      }

      return phoneNumber
    }
  }
}
</script>

<template>
  <div class="oi single-order" v-if="loading">
    <button
      :class="['oi-back', { 'blurred-loading': orderLoading }]"
      @click="$router.go(-1)"
    >
      <icon-arrow-up class="oi-back--icon" />

      Замовлення/{{ isOrderId }}
    </button>
    <div :class="['oi-grid', { 'blurred-loading': orderLoading }]">
      <div class="oi-grid--item">
        <section class="oi-block">
          <div class="oi-block--header">
            <h6>
              <DateFormat
                :date="order.created_at"
                formatter="DD.MM.YY HH:mm"
                v-slot="{ formattedDate }"
              >
                {{ formattedDate }}
              </DateFormat>
              № {{ order.sparta_id }}
            </h6>
            <icon-arrow-up
              class="oi-block--header_arrow"
              :class="{ active: toggle }"
              @click="toggle = !toggle"
            />
          </div>
          <div class="oi-block--list">
            <product-item
              v-for="product in order.products"
              :key="product.id"
              :product="product"
            />
          </div>
          <template v-if="toggle">
            <hr class="oi-line" />
            <div class="oi-details">
              <div class="oi-details-col delivery">
                <figure>
                  <img :src="iconMarker" alt="Icon marker" />
                  <figcaption>{{ order.delivery.provider }}</figcaption>
                </figure>
                <p class="mb-28">
                  {{ order.delivery.city }}; {{ order.delivery.address }} <br />
                  {{ order.delivery.time }}
                </p>
                <p>забирає {{ order.customer.name }}</p>
                <p>{{ formatPhoneNumber(order.customer.phone) }}</p>
                <p class="result">
                  <strong>Задіяно акції:</strong>
                  {{ order.promotions && order.promotions.join(", ") }}
                </p>
              </div>
              <div class="oi-details-col e-check">
                <button
                  class="btn-eCheck"
                  @click="openReceipt"
                  :disabled="!order.receipt"
                >
                  <img :src="iconECheck" alt="Icon e-check" />
                  E-ЧЕК
                </button>
              </div>
              <div class="oi-details-col">
                <div class="oi-details-col--list">
                  <div class="row row-bold">
                    <span>всі знижки</span>
                    <span>-{{ order.dicount.total_dicount }}₴</span>
                  </div>
                  <div
                    class="row"
                    v-for="(dicount, dicountName) in order.dicount.all_dicount"
                    :key="dicountName"
                  >
                    <span>{{ dicountName }}</span>
                    <span>{{ dicount }}₴</span>
                  </div>

                  <div class="row row-delivery">
                    <span>доставка</span>
                    <span
                      class="label-delivery"
                      v-if="order.dicount.delivery_price === 'free'"
                    >
                      FREE
                    </span>
                    <span class="label-delivery" v-else>
                      {{ order.dicount.delivery_price }}₴
                    </span>
                  </div>
                </div>
                <div class="row-summ">
                  <span class="row-summ--caption">сума</span>
                  <strong class="row-summ--num"
                    >{{ order.dicount.amount }}₴</strong
                  >
                </div>
                <div class="oi-details-col--list">
                  <div class="row">
                    <span>нараховано балів</span>
                    <span>{{ order.dicount.points }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="oi-block--footer">
              <icon-arrow-up
                class="oi-block--header_arrow"
                @click="toggle = !toggle"
              />
            </div>
          </template>
        </section>

        <section class="oi-block" v-if="false">
          <div class="oi-block--header">
            <h6>Заява на повернення 21.04.23 19:44</h6>
          </div>
          <div class="oi-block--list">
            <product-item :review="true" />
            <product-item :review="true" />
          </div>
          <hr class="oi-line" />
          <div class="oi-block--accept">
            <button class="oi-block--accept_submit">
              ПІДТВЕРДИТИ ПОВЕРНЕННЯ
            </button>
            <div class="oi-block--accept_info">
              <span> Перераховано з урахуванням акцій </span>
              <div class="row-summ">
                <span class="row-summ--caption">сума</span>
                <strong class="row-summ--num">370.55₴</strong>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="oi-grid--item">
        <section class="oi-block">
          <div class="oi-block--header">
            <h6>Оплата</h6>
            <span class="oi-block--header_status">
              {{ paymentStatus(order.payment.status) }}
            </span>
          </div>
          <div class="oi-block--list">
            <div class="oi-block--list_item">
              <span>Товари</span>
              <span>{{ order.payment.cart_amount }}₴</span>
            </div>
            <div class="oi-block--list_item">
              <span>Доставка</span>
              <span>{{ order.payment.delivery_price }}₴</span>
            </div>
            <div class="oi-amount">
              <span class="oi-amount-captiot">СУМА</span>
              <span class="oi-amount-summ">{{ order.payment.amount }}₴</span>
            </div>
          </div>
          <div class="oi-block--list">
            <span
              class="payment-history-item"
              v-for="historyItem in order.payment.history"
              :key="historyItem.description"
            >
              <DateFormat
                :date="historyItem.created_at"
                formatter="DD.MM.YY HH:mm"
                v-slot="{ formattedDate }"
              >
                {{ formattedDate }}
              </DateFormat>
              -
              {{ historyItem.description }}
            </span>
          </div>
        </section>

        <section class="oi-block">
          <div class="oi-block--header">
            <h6>Опрацювання замовлення</h6>
            <order-status :status="order.collector.status" />
          </div>
          <div class="oi-block--list">
            <div class="oi-block--list_item">
              <strong>Комірник</strong>
              <span>{{ order.collector.name || "" }}</span>
            </div>
            <div class="oi-block--list_item">
              <strong>Взяв в роботу</strong>
              <span>
                <DateFormat
                  :date="order.collector.dateStart"
                  formatter="HH:mm DD.MM.YY"
                  v-slot="{ formattedDate }"
                >
                  {{ formattedDate }}
                </DateFormat></span
              >
            </div>
            <div class="oi-block--list_item">
              <strong>Зібрано</strong>
              <span>
                <DateFormat
                  :date="order.collector.dateStop"
                  formatter="HH:mm DD.MM.YY"
                  v-slot="{ formattedDate }"
                >
                  {{ formattedDate }}
                </DateFormat></span
              >
            </div>
          </div>
        </section>

        <section
          class="oi-block"
          v-if="Object.keys(order.np_document).length >= 1"
        >
          <div class="oi-block--header">
            <h6>Нова пошта</h6>
            <order-status
              status="NP"
              :direct-title="order.np_document?.np_status_text"
            />
          </div>
          <div class="oi-block--list">
            <div class="oi-block--list_item">
              <strong>Накладна</strong>
              <span>{{ order.np_document?.document_number || "" }}</span>
            </div>
            <div class="oi-block--list_item">
              <strong>Створено</strong>
              <span>
                <DateFormat
                  :date="order.np_document?.created_at"
                  formatter="HH:mm DD.MM.YY"
                  v-slot="{ formattedDate }"
                >
                  {{ formattedDate }}
                </DateFormat></span
              >
            </div>
            <div class="oi-block--list_item mb-20">
              <strong>Оновлено</strong>
              <span>
                <DateFormat
                  :date="order.np_document?.np_status_updated_at"
                  formatter="HH:mm DD.MM.YY"
                  v-slot="{ formattedDate }"
                >
                  {{ formattedDate }}
                </DateFormat></span
              >
            </div>
            <div>
              <v-btn label="ОНОВИТИ" size="sm" @click="directUpdateNp()" />
            </div>
          </div>
        </section>

        <section class="oi-block b-none">
          <div class="oi-block--list">
            <div
              class="oi-block--list_item"
              v-for="historyItem in order.history"
              :key="historyItem.description"
            >
              <span>{{ historyItem.description }}</span>
              <span>
                <DateFormat
                  :date="historyItem.created_at"
                  formatter="HH:mm DD.MM.YY"
                  v-slot="{ formattedDate }"
                >
                  {{ formattedDate }}
                </DateFormat>
              </span>
            </div>
          </div>
        </section>
        <section v-if="canCancelOrder" class="oi-block b-none">
          <v-btn
            label="СКАСУВАТИ ЗАМОВЛЕННЯ"
            size="sm"
            type="danger"
            @click="directCancelOrder()"
          />
        </section>
      </div>
    </div>
    <div v-if="orderLoading" class="loading-status">
      <LoadingSpinner />
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/index.scss";
</style>
