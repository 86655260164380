import { alphabetOrdering } from "./../../../helpers/strings"
import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_EFFECTS](state, effects) {
    const myEffects = effects.map((effect) => ({
      ...effect,
      items: effect.children
    }))

    state.groups = alphabetOrdering(myEffects)
  },
  [mutationTypes.CHANGE_VALIDATION](state, { groupId, itemId }) {
    const groupItem = state.groups.find((item) => item.id === groupId)

    // Якщо зміна групи
    if (!itemId) {
      groupItem.validate = 1
      groupItem.items = groupItem.items.map((item) => ({
        ...item,
        validate: 1
      }))
    } else {
      // Якщо зміна item в групі
      const groupItemChild = groupItem.items.find((item) => item.id === itemId)

      groupItemChild.validate = 1
    }
  }
}
