<template>
  <div class="label-status" :style="collection[status].style">
    <img
      :src="collection[status].icon"
      alt="icon sign"
      :width="sizeIcon"
      :height="sizeIcon"
    />
    <span>{{ collection[status].title }}</span>
  </div>
</template>

<script>
import iconCheckDuotone from "@/assets/icons/check-duotone.svg"
import iconExclamationMark from "@/assets/icons/exclamation-mark.svg"
import iconReset from "@/assets/icons/reset.svg"

export default {
  name: "labelStatus",
  props: {
    status: {
      type: String,
      required: true,
      validator(value) {
        return [
          "NEW",
          "RENEWED",
          "PROBLEMATIC",
          "PROBLEMATIC_NOTAPPLICABLE",
          "PROBLEMATIC_VOCABULARY",
          "PUBLISHED",
          "HIDDEN"
        ].includes(value)
      },

      default: "NEW"
    }
  },

  data() {
    return {
      iconCheckDuotone,
      iconExclamationMark,
      iconReset
    }
  },

  computed: {
    sizeIcon() {
      return this.status === "NEW" || this.status === "RENEWED" ? 14 : 16
    },

    collection() {
      return {
        NEW: {
          title: "новий",
          icon: iconReset,
          style: { background: "#FC536A", color: "#fff" }
        },

        RENEWED: {
          title: "оновлений",
          icon: iconReset,
          style: { background: "#FC536A", color: "#fff" }
        },

        PUBLISHED: {
          title: "опублікований",
          icon: iconCheckDuotone,
          style: { background: "#C6E6D6", color: "#000" }
        },

        PROBLEMATIC: {
          title: "проблемний",
          icon: iconExclamationMark,
          style: { background: "#7E71B1", color: "#fff" }
        },

        PROBLEMATIC_VOCABULARY: {
          title: "проблемний",
          icon: iconExclamationMark,
          style: { background: "#7E71B1", color: "#fff" }
        },

        PROBLEMATIC_NOTAPPLICABLE: {
          title: "проблемний",
          icon: iconExclamationMark,
          style: { background: "#7E71B1", color: "#fff" }
        },

        HIDDEN: {
          title: "прихований",
          icon: iconExclamationMark,
          style: { background: "#666", color: "#fff" }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label-status {
  margin-left: auto;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 16px;
  border-radius: 26.5px;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  cursor: pointer;
}
</style>
