<template>
  <div ref="time" class="timeline-date-container">
    <span
      v-if="!showDateChip && showDate"
      class="date-vale"
      :style="{ left: `-${spanDateLeftOffset}px` }"
      >{{ date.formatedDate }}
    </span>
    <DateChip
      v-if="showDateChip"
      class="date-chip"
      :style="{ left: `-${dateChipLeftOffset}px` }"
      :date="date.formatedDate"
      @click="showCalendar = !showCalendar"
    />
    <div class="line" />
    <AppCalendar
      v-if="showDateChip && showCalendar"
      class="calendar"
      :onDayClick="onPressDate"
      :selectedDate="selectedDate"
      :minDate="dayjs().subtract(1, 'week').toDate()"
      :maxDate="dayjs().add(2, 'month').toDate()"
      @mouseleave="showCalendar = false"
    />
  </div>
</template>

<script>
import dayjs from "dayjs"

import AppCalendar from "../../AppCalendar.vue"
import DateChip from "../../DateChip.vue"

export default {
  props: ["date", "showDateChip", "showDate", "isLast"],
  emits: ["onChangeWidth", "onSelectDate"],
  components: {
    DateChip,
    AppCalendar
  },

  mounted() {
    this.observeWidth()
  },

  unmounted() {
    this.widthObserverRef?.disconnect()
  },

  data: () => ({
    widthObserverRef: null,
    showCalendar: false
  }),

  computed: {
    spanDateLeftOffset() {
      const width = 36

      return width / 2
    },

    dateChipLeftOffset() {
      const width = 70

      return width / 2
    },

    selectedDate() {
      return new Date(this.date.rawDate)
    }
  },

  methods: {
    dayjs,
    observeWidth() {
      const resizeObserver = new ResizeObserver(() => {
        const data = {
          width: this.$refs.time.clientWidth,
          offsetLeft: this.$refs.time.offsetLeft
        }

        this.$emit("onChangeWidth", data)
      })

      resizeObserver.observe(this.$refs.time)
      this.widthObserverRef = resizeObserver
    },

    onPressDate(date) {
      this.$emit("onSelectDate", date, this.isLast)
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-date-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 32px;
  flex: 1;
}
.date-vale {
  position: absolute;
  display: inline-block;
  top: 4px;
  font-size: 14px;
  line-height: 16px;
  width: 36px;
}
.date-chip {
  position: absolute;
  top: 0;
  width: 70px;
  min-height: 24px;
  padding: 0;
  cursor: pointer;
}
.line {
  height: 5px;
  width: 1px;
  background-color: $grey4;
}
.calendar {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 2;
}
</style>
