import { sendSecureRequest } from "../config"

import { isAddSearch } from "./../../helpers/urlModificator"

const ENDPOINT = "admin/orders"

export default {
  orders(params) {
    return sendSecureRequest().get(`${ENDPOINT}`, { params })
  },

  ordersID(ID) {
    return sendSecureRequest().get(`${ENDPOINT}/${ID}`)
  },

  updateNp(ID) {
    return sendSecureRequest().get(`${ENDPOINT}/${ID}/status-update`)
  },

  cancel(ID) {
    return sendSecureRequest().get(`${ENDPOINT}/${ID}/cancel`)
  }
}
