import { sendRequest, sendSecureRequest } from "../config"

const ENDPOINT = "admin/colors"
const ENDPOINT_UPLOAD_IMAGE = `${ENDPOINT}/upload-image`

export default {
  getColors() {
    return sendSecureRequest().get(ENDPOINT)
  },

  updateImage(payload) {
    return sendSecureRequest().post(`${ENDPOINT_UPLOAD_IMAGE}`, payload)
  },

  updateType(payload) {
    return sendSecureRequest().post(ENDPOINT, payload)
  },

  updateValidate(payload) {
    return sendSecureRequest().post(ENDPOINT, payload)
  }
}
