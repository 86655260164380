<script setup>
import { useDateFormat } from "@vueuse/core"
import { defineProps, computed } from "vue"

const props = defineProps({
  date: { type: String, default: "" },
  formatter: { type: String, default: "DD.MM.YY HH:mm" }
})

const formattedDate = computed(() => {
  if (!props.date) return "-"
  return useDateFormat(() => props.date, props.formatter).value
})
</script>

<template>
  <slot :formattedDate="formattedDate"> </slot>
</template>
