import { BLOCK_TYPES } from "@/constants/sections"
import { CategoryItem } from "@/models/sections/Section"

export default {
  selectedSection: (state) => state.selectedSection,
  selectedItem: (state) => state.selectedItem,
  blocksWithoutExtra: (state) => {
    if (state.selectedSection) {
      const selectCategory = state.placard.find(
        (el) => el.id === state.selectedSection.id
      )

      return selectCategory.blocks.filter(
        (section) => section.type !== BLOCK_TYPES.EXTRA
      )
    } else return []
  },
  extraBlock: (state) => {
    if (state.selectedSection) {
      const indexBlock = state.placard.findIndex(
        (el) => el.id === state.selectedSection.id
      )
      const indexExtraBlock = state.placard[indexBlock].blocks.findIndex(
        (section) => section.type === BLOCK_TYPES.EXTRA
      )

      return state.placard[indexBlock].blocks[indexExtraBlock]
    } else return null
  },
  categories: (state) =>
    state.categories.map(
      (category) =>
        new CategoryItem(
          category.id,
          category.category_name,
          category.parent_id
        )
    ),
  placard: (state) => state.placard,
  placardFilters: (state) => state.filters,
  placardForms: (state) => state.forms,
  placardFiltersCopy: (state) => state.filtersCopy,
  placardFormsCopy: (state) => state.formsCopy,
  placardProducts: (state) => state.products,
  placardCopy: (state) => state.placardCopy
}
