<template>
  <div
    class="filter"
    :class="[active && 'active-filter']"
    @click="$emit('click')"
  >
    <span class="filter--label">Empty</span>
    <FilterIcon />
  </div>
</template>

<script>
import FilterIcon from "./svg/FilterIcon.vue"

export default {
  components: {
    FilterIcon
  },

  props: ["active"],
  emits: ["click"]
}
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 114px;
  width: 100%;
  min-height: 30px;
  border: 1px solid $grey5;
  border-radius: 16px;
  cursor: pointer;

  &--label {
    font-size: 14px;
    line-height: 16px;
    margin-right: 9px;
  }
}

.active-filter {
  background-color: $grey5;
}
</style>
